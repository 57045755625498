import CommentCard from "./commentCard";
import React, { useEffect, useState } from "react";
import { Column } from "../../../base/Layout/columns";
import Spinner from "../../../base/Components/Spinner";
import { addToastError, addToastInfo, addToastWarning } from "../../../base/Components/Toasts";
import InputComment from "../../../components/input/inputComment";
import OutlinedButton from "../../../base/Buttons/OutlinedButton";
import { addCommentToDiscussion, getDiscussionComments } from "../../../logic/DiscussionLogic";

// Component to display comments for a discussion and an input component
export default function DiscussionComments({ discussionId }) {
  // Throw an error if discussionId is null
  if (discussionId === null) {
    throw new Error("Discussion id is null");
  }

  // State variable for comments, loading status, and whether all comments have been downloaded
  const [comments, setComments] = useState(null);
  const [loading, setLoading] = useState(false);
  const [allDownloaded, setAllDownloaded] = useState(false);

  // Load first 3 comments when the component mounts
  useEffect(() => {
    if (comments == null) {
      setLoading(true);
      getDiscussionComments(discussionId).then((res) => {
        setComments(res);
        setLoading(false);
        if (res.length < 3) setAllDownloaded(true);
      });
    }
  }, [comments]);

  // Load more comments when the "View more" button is clicked
  async function viewMoreComments() {
    getDiscussionComments(discussionId, comments[comments.length - 1], 3).then((res) => {
      setComments((comments) => [...comments, ...res]);
      if (res.length < 3) setAllDownloaded(true);
    });
  }

  // Add a new comment to the discussion
  async function addComment(comment) {
    setLoading(true);
    await addCommentToDiscussion(comment, discussionId)
      .then(() => {
        document.getElementById("comment_input_" + discussionId).value = "";
        document.getElementById("comment_input_" + discussionId).style.height = "min-content";
        addToastInfo("Le commentaire est en cours de révision. Nous vous informerons dès sa publication.");
      })
      .catch((e) => {
        setLoading(false);
        addToastWarning("Une erreur est survenue lors de l'ajout du commentaire. Veuillez réessayer plus tard.");
      });
    setLoading(false);
  }

  // Render the component
  return (
    <>
      <Column
        id={"scroll_" + discussionId}
        className="snap-y snap-mandatory flex-1 w-full pb-10 pt-4 overflow-auto"
      >
        {loading && !comments && <Spinner className="h-8 w-8 mt-5" />}
        {comments && comments.length === 0 && (
          <p className="p-2 sm:p-10 mx-auto italic">Aucun commentaire pour l'instant</p>
        )}
        {comments && comments.map((e) => <CommentCard key={"comment_card_" + e.id} comment={e} />)}
        {!loading && !allDownloaded && (
          <OutlinedButton className="w-fit mx-auto" onClick={viewMoreComments}>
            <p>Voir plus</p>
          </OutlinedButton>
        )}
        <div id={"fin_" + discussionId} />
      </Column>
      <InputComment
        className="p-4 bg-background-dark"
        loading={loading && comments}
        onAddComment={addComment}
        id={"comment_input_" + discussionId}
      />
    </>
  );
}

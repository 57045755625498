import React from "react";
import { useNavigate } from "react-router";
import { Row, RowEnd } from "../../base/Layout/rows";
import { Link } from "react-router-dom";
import OutlinedButton from "../../base/Buttons/OutlinedButton";
import FilledButton from "../../base/Buttons/FilledButton";

export default function NavBarLanding({ }) {
  const navigate = useNavigate();
  return (
    <div className="flex justify-between items-center px-4 md:px-10 w-full py-4 bg-dark-background-color bg-opacity-40 backdrop-blur-sm shadow-sm absolute z-20 top-0">
      <Link to={"/"}>
        <img src="/images/isologotype.svg" alt="logo" width="250px" />
      </Link>
      <RowEnd className="space-x-2">
        <OutlinedButton className="rounded-full" onClick={() => navigate("/login")}>
          <p>Connexion</p>
        </OutlinedButton>
        <FilledButton className="rounded-full hidden md:flex" onClick={() => navigate("/earlyRegister")}>
          <p>S'inscrire</p>
        </FilledButton>
      </RowEnd>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import Footer from "../../components/landing/footer";
import Form from "../../base/Forms/Form";
import FormInput from "../../base/Forms/FormInput";
import { useNavigate } from "react-router";
import { createDocument } from "../../helpers/FirestoreHelper";
import { Timestamp } from "firebase/firestore";
import { sendSignInLink } from "../../helpers/AuthHelper";
import FilledButton from "../../base/Buttons/FilledButton";
import Spinner from "../../base/Components/Spinner";
import FilledCard from "../../base/Cards/FilledCard";
import { Column } from "../../base/Layout/columns";
import NavBarLanding from "../../components/landing/navBar";
import { addToastError } from "../../base/Components/Toasts";
import { Helmet } from "react-helmet";
import { sendEventToDiscord } from "../../helpers/DiscordHelper";
import { logEventToAnalytics } from "../../helpers/AnalyticsHelper";

export default function EarlyRegister() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function handleSubmit(e) {
    let elements = e.target.elements;
    let data = {
      traited: false,
      date: Timestamp.fromDate(new Date()),
      displayName: elements.displayName.value,
      mail: elements.mail.value,
    };

    // Validate data
    if (!data.displayName || !data.mail) return addToastError("Veuillez remplir tous les champs");

    setLoading(true);
    await createDocument("earlyRegister/", data);
    sendSignInLink(data.mail);
    localStorage.setItem("earlySubscriptionSent", true);
    setLoading("Uploaded");

    logEventToAnalytics("fill_register_form", { email: data.mail });
    sendEventToDiscord("Un utilisateur s'est inscrit à l'inscription anticipée : " + data.mail);
  }

  return (
    <>
      <Helmet>
        <title>Philosophique - Inscription Anticipée</title>
        <meta
          name="description"
          content="Inscrivez-vous dès maintenant et rejoignez une communauté de philosophes à travers le monde !"
        />
      </Helmet>
      <NavBarLanding />
      <div className="w-full md:h-screen block pt-32 pb-24 transition-all duration-300">
        <div
          className="fixed h-screen bottom-0 top-0 w-full transition-colors z-0 bg-cover"
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0,0.1),rgba(0, 0, 0, 0.8)),url(/images/girl_header.jpeg)`,
          }}
        />
        <FilledCard
          className={"!bg-primary-light p-6 md:p-10 text-background-color !bg-opacity-80 h-min backdrop-blur-md max-w-4xl mx-auto"}
        >
          {loading === "Uploaded" && (
            <div className="flex flex-col items-center text-lg my-5 mx-auto max-w-xl text-center ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-12 h-12 mt-2"
              >
                <path
                  fillRule="evenodd"
                  d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                  clipRule="evenodd"
                />
              </svg>
              <p className="text-3xl font-bold">Lien envoyée avec succès</p>{" "}
              <p className="text-base mt-2">
                Vous souhaitez utiliser la plateforme sans restriction ?
                <br />
                Pensez à un{" "}
                <a className="text-dark-blue underline" href="/plans">
                  abonnement anticipé
                </a>
                .
              </p>
            </div>
          )}
          {loading !== "Uploaded" && (
            <Column className="space-y-5 md:space-y-0 md:flex-row md:space-x-5">
              <div className="flex flex-col space-y-2 md:text-lg pb-5 md:pb-0 md:pr-5 md:w-3/5 border-b md:border-b-0 md:border-r border-primary-light">
                <p className="text-3xl font-bold">Inscription Anticipée</p>
                <p>La plateforme est en phase de développement.</p>
                <p>
                  Il est probable que vous trouviez des erreurs dans son utilisation. Si tel est le cas, n'hésitez pas à
                  nous contacter.
                </p>
                <p>Dites-nous votre prenom et votre e-mail et vous recevrez un lien pour accéder à la plateforme.</p>
              </div>
              <Form
                className="flex flex-col md:w-2/5 md:mx-auto space-y-6 py-10"
                onSubmit={handleSubmit}
              >
                <FormInput
                  title="Prenom"
                  id="displayName"
                  placeholder="Prenom..."
                  className="placeholder:text-gray-600 w-full bg-opacity-5 !text-dark-blue"
                />
                <FormInput
                  id="mail"
                  type="email"
                  title="Email"
                  placeholder="example@mail.com..."
                  className="placeholder:text-gray-600 w-full bg-opacity-5 !text-dark-blue"
                />
                {loading && <Spinner />}
                {!loading && (
                  <FilledButton className="bg-secondary-color text-secondary-on ml-auto" type="submit">
                    S'inscrire
                  </FilledButton>
                )}
              </Form>
            </Column>
          )}
        </FilledCard>
        {loading !== "Uploaded" && (
          <FilledCard className="!bg-primary-light p-6 md:p-10 text-background-color !bg-opacity-80 h-min backdrop-blur-md max-w-4xl mx-auto flex-col mt-10 space-y-10">
            <div className="flex flex-col space-y-2 pr-10 flex-grow ">
              <h4>Ou abonnez-vous maintenant</h4>
              <p>Et nous ne vous oublierons jamais 💙</p>
            </div>
            <div className="flex space-x-3 mt-8 md:mt-0 justify-end">
              <FilledButton className="bg-secondary-color text-secondary-on" onClick={() => navigate("/plans")}>
                <p>Voir les plans</p>
              </FilledButton>
            </div>
          </FilledCard>
        )}
      </div>
      <Footer />
    </>
  );
}

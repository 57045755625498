import { Timestamp } from "firebase/firestore";
import moment from "moment";
import React, { useState } from "react";
import { auth } from "../../../helpers/AuthHelper";
import { createDocument, getDocumentReference, setDocument } from "../../../helpers/FirestoreHelper";
import Form from "../../../base/Forms/Form";
import FormInput from "../../../base/Forms/FormInput";
import FormTextarea from "../../../base/Forms/FormTextarea";
import { addToastInfo } from "../../../base/Components/Toasts";
import GenericModal from "../../../base/Dialogs/GenericModal";
import { ColRow, Column } from "../../../base/Layout/columns";

function getNewDiscussion() {
  return {
    title: "",
    description: "",
    createdBy: getDocumentReference("users/" + auth.currentUser.uid),
    public: false,
    participants: [],
    from: moment().format("YYYY-MM-DD"),
    to: moment().add(1, "week").format("YYYY-MM-DD"),
  };
}

export default function CreateDiscussion({ open, setOpen }) {
  const [newDiscussion, setNewDiscussion] = useState(getNewDiscussion());
  const [loading, setLoading] = useState(false);

  async function onCreateDiscussion() {
    setLoading(true);
    let discuss = { ...newDiscussion };
    discuss.from = Timestamp.fromMillis(moment(discuss.from).toDate().getTime());
    discuss.to = Timestamp.fromMillis(moment(discuss.to).toDate().getTime());
    await createDocument("/discussions", discuss);
    setLoading(false);
    setNewDiscussion(getNewDiscussion());
    addToastInfo("La discussion est entrée en revue. Nous vous tiendrons informés lors de sa publication.");
    setTimeout(() => {
      setOpen(false);
    }, 2000);
  }

  return (
    <Form id="form_discussion" onSubmit={onCreateDiscussion}>
      <GenericModal
        loading={loading}
        title={"Créer une nouvelle discussion"}
        textActionButton="Créer"
        open={open}
        actionButtonType="submit"
        setOpen={setOpen}
      >
        <Column className="p-4 h-full flex-1 sm:p-10 space-y-5">
          <FormInput
            id="title"
            title={"De quoi allons-nous discuter ?"}
            placeholder={"Titre . . ."}
            minLength={10}
            maxLength={80}
            required={true}
            value={newDiscussion.title}
            onChange={(e) => setNewDiscussion({ ...newDiscussion, [e.target.id]: e.target.value })}
          />
          <FormTextarea
            id="description"
            title={"Détaillez la discussion en quelques mots"}
            placeholder={"Description . . ."}
            minLength={45}
            maxLength={320}
            required={true}
            value={newDiscussion.description}
            onChange={(e) => setNewDiscussion({ ...newDiscussion, [e.target.id]: e.target.value })}
          />
          <ColRow breakpoint="sm" className="space-y-2 sm:space-y-0 sm:space-x-2">
            <FormInput
              id="from"
              title={"Début"}
              type="date"
              required={true}
              value={newDiscussion.from}
              onChange={(e) => setNewDiscussion({ ...newDiscussion, [e.target.id]: e.target.value })}
            />
            <FormInput
              id="to"
              title={"Fin"}
              type="date"
              required={true}
              value={newDiscussion.to}
              onChange={(e) => setNewDiscussion({ ...newDiscussion, [e.target.id]: e.target.value })}
            />
          </ColRow>
        </Column>
      </GenericModal>
    </Form>
  );
}

import { useEffect, useState } from "react";
import { getQuote } from "../../../logic/QuotesLogic";
import Spinner from "../../../base/Components/Spinner";
import { hasPhilosophies } from "../../../logic/UserLogic";
import UserNameLink from "../../../components/navigation/userNameLink";

export default function ProfileQuotes({ userDoc }) {
  const [open, setOpen] = useState(false);

  return hasPhilosophies(userDoc) ? (
    <ul className="card flex-col bg-background-dark shadow-glow-sm w-64 space-y-2 max-h-96 !p-3 overflow-auto hidden xl:flex">
      <li className="py-1 border-primary-dark border-opacity-25 flex items-center space-x-1 justify-between">
        <h4 className="text-base flex-grow">Philosophies</h4>
        <p className="text-sm">{userDoc.data().philosophies.length}/5</p>
        {hasPhilosophies(userDoc) && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-5 h-5 flex-shrink-0 cursor-pointer"
            onClick={() => setOpen(!open)}
          >
            {open && (
              <path
                fillRule="evenodd"
                d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z"
                clipRule="evenodd"
              />
            )}
            {!open && (
              <path
                fillRule="evenodd"
                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                clipRule="evenodd"
              />
            )}
          </svg>
        )}
      </li>
      {open &&
        userDoc.data().philosophies &&
        userDoc.data().philosophies.map((e, k) => <QuoteCard key={"quoteProfile_" + k} q={e} />)}
    </ul>
  ) : (
    <></>
  );
}

function QuoteCard({ q }) {
  const [quote, setquote] = useState(null);

  useEffect(() => {
    if (quote === null) getQuote(q.ref.path).then(setquote);
  }, [quote]);

  return (
    <li className="space-y-2 border-t border-primary-dark border-opacity-25 last:border-b-0 py-2">
      {!quote && <Spinner />}
      {quote && (
        <>
          <p className="quote-base text-right">“{quote.quote}”</p>
          <UserNameLink user={quote.author} className="h-8 text-sm justify-end" />
        </>
      )}
    </li>
  );
}

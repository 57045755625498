import React, { useEffect, useState } from "react";
import { RoundedCheckBadge } from "../../components/badges/badges";
import { Column } from "../../base/Layout/columns";
import { Row, RowBetween } from "../../base/Layout/rows";
import { createDocument, getDocument, searchDocumentByValue } from "../../helpers/FirestoreHelper";
import GenericModal from "../../base/Dialogs/GenericModal";
import FilledButton from "../../base/Buttons/FilledButton";
import Spinner from "../../base/Components/Spinner";
import FormInput from "../../base/Forms/FormInput";
import SearchRounded from "../../base/Components/Search";
import { GridCols3 } from "../../base/Layout/grids";

function getNewUser() {
  return {
    displayName: "",
    lightPoints: 0,
    isPhilosopher: false,
    isSubscribed: false,
    lighted: [],
    createdByPlatform: true,
    biography: "",
  };
}

export default function UsersDashboard({}) {
  const [users, setUsers] = useState(null);
  const [newUser, setNewUser] = useState(getNewUser());
  const [loading, setLoading] = useState(false);
  const [profilePictures, setProfilePictures] = useState(null);
  const [addUserModal, setAddUserModal] = useState(false);

  useEffect(() => {
    if (!profilePictures) getDocument("users/profilePictures").then(setProfilePictures);
  }, [profilePictures]);

  async function onAddUser() {
    setLoading(true);
    let postUser = { ...newUser };
    if (postUser.isPhilosopher) {
      postUser.quotedBy = [];
    }
    let res = await createDocument("/users", postUser, false);
    setLoading(false);
    setNewUser(getNewUser());
  }

  async function onSearch(search) {
    setTimeout(async () => {
      if (document.getElementById("search_users").value === search && search !== "") {
        setLoading(true);
        let res = await searchDocumentByValue("/users", "displayName", search);
        setUsers(res);
        setLoading(null);
      }
    }, 800);
  }

  return (
    <>
      <Column className="max-w-5xl mx-auto w-full p-10">
        <RowBetween>
          <h1>Gestion d'utilisateurs</h1>
          <FilledButton onClick={() => setAddUserModal(!addUserModal)}>
            <p>Add new user</p>
          </FilledButton>
        </RowBetween>
        <RowBetween className="mt-10">
          <SearchRounded id="search_users" onChange={(e) => onSearch(e.target.value)} />
        </RowBetween>
        {loading && <Spinner />}
        <GridCols3 className="p-10">
          {users &&
            users.map((user) => (
              <div className="card w-full flex flex-col space-y-2 items-center">
                <img src={user.profileImg} className="w-20 h-20 rounded-full" />
                <p className="text-xl font-semibold">{user.displayName}</p>
                <p>{user.id}</p>
              </div>
            ))}
        </GridCols3>
      </Column>
      <GenericModal
        open={addUserModal}
        loading={loading}
        setOpen={setAddUserModal}
        onActtionButton={onAddUser}
        textActionButton="Ajouter utilisateur"
      >
        <Column className="max-w-4xl p-10 w-full h-screen overflow-y-scroll snap-y snap-mandatory mx-auto items-start">
          <div className="flex py-10 justify-between items-end w-full">
            <Row className="w-3/4 space-x-4">
              <p>Display Name</p>
              <FormInput setValue={(e) => setNewUser({ ...newUser, displayName: e.target.value })} />
            </Row>
          </div>
          <div className="flex-col py-10 space-y-10 items-start w-full">
            <RowBetween className="items-start space-x-10">
              <div className="h-36 w-36 relative rounded-full">
                <div className="bg-gray-700 animate-pulse absolute" />
                <img
                  src={newUser.profileImg}
                  alt="user_avatar"
                  className={"w-full h-full rounded-full aspect-square object-cover "}
                />
              </div>
              <div className="grid grid-cols-5 justify-evenly flex-grow gap-2">
                {profilePictures &&
                  profilePictures.data().paths.map((img) => (
                    <div
                      onClick={() => setNewUser({ ...newUser, profileImg: img })}
                      className="h-16 w-16 relative cursor-pointer hover:shadow-md hover:shadow-primary-color rounded-full"
                    >
                      <div className="bg-gray-700 animate-pulse absolute" />
                      <img
                        src={img}
                        alt="user_avatar"
                        className={"w-full h-full rounded-full aspect-square object-cover "}
                      />
                      {newUser.profileImg === img && (
                        <RoundedCheckBadge className="text-blue-500 absolute bottom-0 right-0" small={false} />
                      )}
                    </div>
                  ))}
              </div>
            </RowBetween>
          </div>
          <div className="flex-col space-y-10 items-start w-full">
            <RowBetween>
              <Row className="w-min space-x-4">
                <p className="text-xl whitespace-nowrap">Is Subscribed ?</p>
                <FormInput
                  type="checkbox"
                  setValue={(e) => setNewUser({ ...newUser, isSubscribed: e.target.checked })}
                />
              </Row>
              <Row className="w-min space-x-4">
                <p className="text-xl whitespace-nowrap">Is Philosopher ?</p>
                <FormInput
                  type="checkbox"
                  setValue={(e) => setNewUser({ ...newUser, isPhilosopher: e.target.checked })}
                />
              </Row>
            </RowBetween>
            <Row className="w-3/4 space-x-4">
              <p>Light points</p>
              <FormInput
                type="number"
                className="w-2/4"
                setValue={(e) => setNewUser({ ...newUser, lightPoints: parseInt(e.target.value) })}
              />
            </Row>
            <Column className="w-full">
              <h2>Biography</h2>
              <textarea
                className="px-6 py-2 w-full border bg-transparent border-text-color rounded-lg focus:ring-0"
                onChange={(e) => setNewUser({ ...newUser, biography: e.target.value })}
              />
            </Column>
          </div>
        </Column>
      </GenericModal>
    </>
  );
}

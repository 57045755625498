import { arrayUnion, Timestamp } from "firebase/firestore";
import React, { useState } from "react";
import { Column } from "../../../base/Layout/columns";
import { RowBetween } from "../../../base/Layout/rows";
import {
  createDocument,
  getDocument,
  getDocumentReference,
  searchDocumentByValue,
  UpdateDocument,
} from "../../../helpers/FirestoreHelper";
import GenericModal from "../../../base/Dialogs/GenericModal";
import FilledButton from "../../../base/Buttons/FilledButton";
import OutlinedButton from "../../../base/Buttons/OutlinedButton";
import SearchRounded from "../../../base/Components/Search";
import { GridCols3 } from "../../../base/Layout/grids";
import OutlinedCard from "../../../base/Cards/OutlinedCard";
import EditBook from "./editBook";
import Spinner from "../../../base/Components/Spinner";

function getNewBook() {
  return {
    title: "",
    description: "",
    date: Timestamp.fromDate(new Date()),
    body: [{ subtitle: "", paragraphs: [""] }],
    isPublic: false,
    lighted: [],
  };
}

export default function Books({}) {
  const [books, setBooks] = useState(null);
  const [newBook, setNewBook] = useState(null);
  const [loading, setLoading] = useState(false);

  async function saveBook() {
    setLoading(true);
    let postBook = { ...newBook };
    postBook.author = getDocumentReference("/users/" + newBook.author);

    if (postBook.id !== undefined) {
      // EDIT LIVRE
      let id = postBook.id;
      delete postBook.id;
      delete postBook.path;
      delete postBook.author;
      await UpdateDocument("/books/" + id, postBook);
    } else {
      // NOUVEAU LIVRE
      if (newBook.current && newBook.current !== "")
        postBook.current = getDocumentReference("/currents/" + newBook.current);
      let res = await createDocument("/books", postBook, false);
      UpdateDocument("/users/" + postBook.author.id, { books: arrayUnion(getDocumentReference("/books/" + res.id)) });
      if (newBook.current && newBook.current !== "") {
        let current = getDocument("/currents/" + newBook.current);
        if (!current.data().philosophers.find((e) => e === postBook.author))
          await UpdateDocument("/currents/" + newBook.current, {
            books: arrayUnion(getDocumentReference("/books/" + res.id)),
            philosophers: arrayUnion(postBook.author),
          });
        else
          await UpdateDocument("/currents/" + newBook.current, {
            books: arrayUnion(getDocumentReference("/books/" + res.id)),
          });
      }
    }

    setLoading(false);
    setNewBook(null);
  }

  async function onSearch(search) {
    setTimeout(async () => {
      if (document.getElementById("search_books").value === search && search !== "") {
        setLoading(true);
        let res = await searchDocumentByValue("/books", "title", search);
        setBooks(res);
        setLoading(null);
      }
    }, 800);
  }

  return (
    <>
      <Column className="max-w-7xl w-full p-20 mx-auto">
        <RowBetween>
          <h1>Gestion de livres</h1>
          <FilledButton onClick={() => setNewBook(getNewBook())}>
            <p>Ajouter livre</p>
          </FilledButton>
        </RowBetween>
        <RowBetween className="mt-10">
          <SearchRounded id="search_books" onChange={(e) => onSearch(e.target.value)} />
        </RowBetween>
        {loading && <Spinner />}
        <GridCols3 className="gap-5 p-5">
          {books &&
            books.map((book) => (
              <OutlinedCard key={book.id}>
                <Column className="space-y-4">
                  <p className="text-3xl font-semibold">{book.title}</p>
                  <p>{book.description}</p>
                  <OutlinedButton onClick={() => setNewBook({ ...book })}>
                    <p>Editer le livre</p>
                  </OutlinedButton>
                </Column>
              </OutlinedCard>
            ))}
        </GridCols3>
      </Column>
      <GenericModal
        open={newBook !== null}
        setOpen={() => setNewBook(null)}
        loading={loading}
        onActtionButton={saveBook}
        showHeader={false}
        textActionButton="Enregistrer"
      >
        {newBook && <EditBook book={newBook} setBook={setNewBook} />}
      </GenericModal>
    </>
  );
}

import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { Row } from "../../base/Layout/rows";
import ReadMenu from "../read/components/readMenu";
import BooksList from "../read/components/booksList";
import { writeParagraphs } from "../../logic/BooksLogic";
import QuotesList from "../../components/quotes/quotesList";
import { getDocument } from "../../helpers/FirestoreHelper";
import PhilosophersList from "./components/philosophersList";
import { Column, ColumnEnd } from "../../base/Layout/columns";
import { ControlledSelectable } from "../../base/Components/Selectable";
import { NavigateBackStructure } from "../../components/navigation/structure";
import { Route, Routes, useLocation, useNavigate, useParams } from "react-router";
import { awaitForSeconds } from "../../helpers/functions";

export default function ThinkCurrent({}) {
  let { id } = useParams();
  const navigate = useNavigate();
  const path = useLocation().pathname;
  const [current, setCurrent] = useState(null);
  const [presenting, setPresenting] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!current) {
      getDocument("/currents/" + id).then((res) => setCurrent(res));
    } else if (path == "/current/" + id && !presenting) {
      setTimeout(() => {
        readPresentation();
      }, 2000);
    } else {
      setPresenting(false);
    }
  }, [current, path]);

  async function readPresentation() {
    setPresenting(true);
    if (document.getElementById("next_paragraph")) document.getElementById("next_paragraph").remove();
    if (document.getElementById("prev_paragraph")) document.getElementById("prev_paragraph").remove();

    // Get all elements with "paragraph_" id
    const paragraphs = document.querySelectorAll('[id^="paragraph_"]');

    for (let index = 0; index < paragraphs.length; index++) {
      const element = paragraphs[index];

      if (document.getElementById("skip")) {
        element.children[0].innerText += element.children[1].innerText;
        element.children[1].innerText = "";
        continue;
      }

      // We scroll to the paragraph only if it has text left to read
      if (element.children[1].innerText.length > 0)
        window.scrollTo({
          top: element.offsetTop - 100,
          behavior: "smooth",
        });

      await awaitForSeconds(0.3);

      while (element.children[1].innerText.length > 0) {
        if (document.getElementById("pause")) {
          await awaitForSeconds(0.1);
          continue;
        }
        if (document.getElementById("next_paragraph") || document.getElementById("skip")) {
          element.children[0].innerText += element.children[1].innerText;
          element.children[1].innerText = "";
          if (document.getElementById("next_paragraph")) document.getElementById("next_paragraph").remove();
          break;
        }
        if (document.getElementById("prev_paragraph")) {
          let porcentageRead = element.children[0].innerText.length / element.children[1].innerText.length;
          element.children[1].innerText = element.children[0].innerText + element.children[1].innerText;
          element.children[0].innerText = "";
          // If the paragraph is less than 20% read, we return to the previous paragraph
          if (index > 0 && porcentageRead < 0.2) {
            paragraphs[index - 1].children[1].innerText = paragraphs[index - 1].children[0].innerText;
            paragraphs[index - 1].children[0].innerText = "";
            index--;
          }
          document.getElementById("prev_paragraph").remove();
          index--;
          break;
        }

        let char = element.children[1].innerText[0];
        if (char === " ") {
          char += element.children[1].innerText[1];
          await awaitForSeconds(0.1);
        }

        element.children[0].innerText += char;
        element.children[1].innerText = element.children[1].innerText.substring(char.length);

        await awaitForSeconds(char === "." || char === ":" ? 0.8 : char === "," ? 0.3 : char === "?" ? 0.35 : 0.035);
      }
    }

    if (document.getElementById("skip")) document.getElementById("skip").remove();
    setPresenting(false);
  }

  async function discoverPage() {
    setPresenting(false);
    for (let index = 0; index < current.data().description.length; index++) {
      document.getElementById("paragraph_" + index).innerText = current.data().description[index];
    }
  }

  function replayPresentation() {
    const paragraphs = document.querySelectorAll('[id^="paragraph_"]');

    for (let index = 0; index < paragraphs.length; index++) {
      const element = paragraphs[index];
      element.children[1].innerText = element.children[0].innerText;
      element.children[0].innerText = "";
    }
    readPresentation();
  }

  return (
    <>
      {current && (
        <Helmet>
          <title>
            {current.data().name} -{" "}
            {path.includes("books")
              ? "Livres"
              : path.includes("quotes")
              ? "Citations"
              : path.includes("philosophers")
              ? "Philosophers"
              : "Résumé"}{" "}
            - Découvrir la courant de pensée
          </title>
          <meta name="description" content={current.data().description[0]} />
        </Helmet>
      )}
      <NavigateBackStructure to={"/discover"}>
        <div
          className="w-full h-64 bg-cover bg-center"
          style={{
            backgroundImage:
              `linear-gradient(to left, rgba(0, 0, 0,0.1),rgba(0, 0, 0, .85)),url(` + current?.data().img + `)`,
          }}
        >
          <ColumnEnd className="px-4 sm:px-16 w-full max-w-5xl mx-auto">
            <h4 className="truncate text-6xl sm:text-7xl">{current?.data().name}</h4>
          </ColumnEnd>
        </div>
        <Row className="space-x-2 px-5 sm:px-16 sm:max-w-5xl w-full overflow-x-auto mx-auto py-5">
          <ControlledSelectable value={path == "/current/" + id} setValue={() => navigate("/current/" + id)}>
            Résumé
          </ControlledSelectable>
          <ControlledSelectable value={path.includes("books")} setValue={() => navigate("books")}>
            Livres
          </ControlledSelectable>
          <ControlledSelectable value={path.includes("quotes")} setValue={() => navigate("quotes")}>
            Citations
          </ControlledSelectable>
          <ControlledSelectable value={path.includes("philosophers")} setValue={() => navigate("philosophers")}>
            Philosophers
          </ControlledSelectable>
        </Row>
        <Routes>
          <Route path="/books" element={current && <BooksList list={current.data().books} />} />
          <Route
            path="/quotes"
            element={
              <QuotesList
                id="quotes_list"
                className={
                  "max-w-5xl mx-auto transition-all duration-1000 ease-in-out " + (presenting ? "opacity-0" : "")
                }
                title={""}
                cards={false}
                list={current?.data().quotes}
              />
            }
          />
          <Route path="/philosophers" element={<PhilosophersList list={current?.data().philosophers} />} />
          <Route
            index
            element={
              <Column className="relative max-w-5xl px-4 sm:px-16 mx-auto space-y-5">
                {current?.data().description.map((e, k) => (
                  <p id={"paragraph_" + k} key={"paragraph_" + k} className="text-2xl lg:text-3xl break-words">
                    <span></span>
                    <span className="text-gray-700">{e}</span>
                  </p>
                ))}
                {presenting && <ReadMenu presenting={presenting} onSkip={discoverPage} onReplay={replayPresentation} />}
              </Column>
            }
          />
        </Routes>
        <div className="h-64" />
      </NavigateBackStructure>
    </>
  );
}

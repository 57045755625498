import BookCard from "./bookCard";
import { useEffect, useState } from "react";
import { GridCols2 } from "../../../base/Layout/grids";
import { getBook, removeBookFromList } from "../../../logic/BooksLogic";

export default function ReadingList({ title, listName = null, list = null }) {
  const [booksList, setBooksList] = useState();

  useEffect(() => {
    if (!booksList) getBooks();
  }, [list]);

  async function getBooks() {
    let arr = [];
    for (let index = 0; index < list.length; index++) {
      const ref = list[index];
      let res = await getBook(ref.id);
      arr.push(res);
    }
    setBooksList(arr);
  }

  return !list || list.length == 0 || (booksList && booksList.length === 0) ? (
    <p className="italic p-10 w-full text-center">Il n'y a pas de livres pour le moment</p>
  ) : (
    <GridCols2 className="gap-5">
      {!booksList && list?.map((e, k) => <BookCard key={"load_book_" + k} b={null} />)}
      {booksList &&
        booksList.slice(0, 9).map((book, k) => (
          <BookCard
            key={book.id}
            onHideBook={
              listName
                ? async () => {
                    let list = await removeBookFromList(book, listName);
                    setBooksList(list);
                  }
                : null
            }
            b={book}
          />
        ))}
    </GridCols2>
  );
}

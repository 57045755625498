import moment from "moment";
import { useEffect, useState } from "react";
import { createDocument, getDocumentReference, searchDocumentByValue } from "../../../helpers/FirestoreHelper";
import { addToastInfo } from "../../../base/Components/Toasts";
import { Timestamp } from "firebase/firestore";
import { RowBetween, RowEnd } from "../../../base/Layout/rows";
import Form from "../../../base/Forms/Form";
import FormInput from "../../../base/Forms/FormInput";
import FormTextarea from "../../../base/Forms/FormTextarea";
import { auth } from "../../../helpers/AuthHelper";
import { Column } from "../../../base/Layout/columns";
import GenericModal from "../../../base/Dialogs/GenericModal";

function getNewDiscussion() {
  return {
    title: "",
    description: "",
    createdBy: getDocumentReference("users/" + auth.currentUser.uid),
    public: false,
    participants: [],
    from: moment().format("YYYY-MM-DD"),
    to: moment().add(1, "week").format("YYYY-MM-DD"),
  };
}

export default function AddDiscussion({ open, setOpen }) {
  const [newDiscussion, setNewDiscussion] = useState(getNewDiscussion());
  const [loading, setLoading] = useState(false);

  async function onCreateDiscussion() {
    setLoading(true);
    let discuss = { ...newDiscussion };
    discuss.from = Timestamp.fromMillis(moment(discuss.from).toDate().getTime());
    discuss.to = Timestamp.fromMillis(moment(discuss.to).toDate().getTime());
    await createDocument("/discussions", discuss);
    setLoading(false);
    setNewDiscussion(getNewDiscussion());
    addToastInfo("La discussion a été créée.");
    setOpen(false);
  }

  return (
    <GenericModal
      loading={loading}
      title={"Créer une nouvelle discussion"}
      open={open}
      setOpen={setOpen}
      showFooter={false}
    >
      <Form className="w-3/5 mx-auto my-10 flex flex-col space-y-5" id="form_discussion" onSubmit={onCreateDiscussion}>
        <FormInput
          id="title"
          title={"Titre du discussion ?"}
          placeholder={"Titre . . ."}
          minLength={10}
          maxLength={80}
          required={true}
          value={newDiscussion.title}
          onChange={(e) => setNewDiscussion({ ...newDiscussion, [e.target.id]: e.target.value })}
        />
        <FormTextarea
          id="description"
          title={"Détaillez la discussion en quelques mots"}
          placeholder={"Description . . ."}
          minLength={45}
          maxLength={320}
          required={true}
          value={newDiscussion.description}
          onChange={(e) => setNewDiscussion({ ...newDiscussion, [e.target.id]: e.target.value })}
        />
        <RowBetween className="space-x-5">
          <FormInput
            id="from"
            title={"Début"}
            type="date"
            required={true}
            value={newDiscussion.from}
            onChange={(e) => setNewDiscussion({ ...newDiscussion, [e.target.id]: e.target.value })}
          />
          <FormInput
            id="to"
            title={"Fin"}
            type="date"
            required={true}
            value={newDiscussion.to}
            onChange={(e) => setNewDiscussion({ ...newDiscussion, [e.target.id]: e.target.value })}
          />
        </RowBetween>
        <FormInput value="Créer" type="submit" />
      </Form>
    </GenericModal>
  );
}

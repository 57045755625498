import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { auth } from "../../../helpers/AuthHelper";
import FilledCard from "../../../base/Cards/FilledCard";

export default function ProfileCard({ userDoc }) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged((result) => setUser(result));
  }, []);

  return (
    <FilledCard className="!p-0 w-fit xl:w-64 shadow-glow-sm">
      <Link
        to={user ? "/user/" + user.uid : "/earlyRegister"}
        className={
          "hover:bg-secondary-light bg-transparent rounded-3xl hover:bg-opacity-20 transition-colors ease-in font-bold flex items-center p-3 xl:flex-row-reverse " +
          (user ? "group cursor-pointer" : "")
        }
      >
        {!user && (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-12 h-12"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
            <p className="truncate mr-2 tracking-tighter hidden text-sm xl:block">Philosophe Anonyme</p>
          </>
        )}
        {user && userDoc && (
          <>
            <img
              src={userDoc.data().profileImg}
              alt="user_profile_img"
              className="w-12 h-12 rounded-full object-cover"
            />
            <p className="hidden xl:inline mr-2 group-hover:underline truncate">{userDoc.data().displayName}</p>
          </>
        )}
        {user && !userDoc && (
          <>
            <div className="bg-gray-700 w-12 h-12 animate-pulse rounded-full ml-auto" />
            <div className="bg-gray-700 flex-grow h-10 mr-2 animate-pulse hidden xl:block rounded-full" />
          </>
        )}
      </Link>
    </FilledCard>
  );
}

import React from "react";
import { Column } from "../../../base/Layout/columns";
import { Row } from "../../../base/Layout/rows";
import { BookBadge, QuoteBadge } from "../../../components/badges/badges";
import FilledCard from "../../../base/Cards/FilledCard";
import { Link } from "react-router-dom";

export default function PhilosopherCard({ philosopher }) {
  return philosopher == undefined ? (
    <LoadingState />
  ) : (
    <Link
      className="card bg-background-dark group cursor-pointer sm:h-full hover:shadow-glow transition-all duration-300"
      to={"/user/" + philosopher?.id}
    >
      <Column className="items-center space-y-6">
        <Column className="items-center space-y-2">
          <img className="w-28 h-28 rounded-full" src={philosopher.data().profileImg} alt="profile_img" />
          <p className="text-lg group-hover:underline">{philosopher.data().displayName}</p>
          <Row>
            <p className="text-sm mr-1">
              {philosopher.data().books.length} livre{philosopher.data().books.length === 1 ? "" : "s"}
            </p>
            <BookBadge className="w-5 h-5" />
            <p className="mx-2">|</p>
            <p className="text-sm mr-1">
              {philosopher.data().quotes ? philosopher.data().quotes.length : 0} citation
              {philosopher.data().quotes && philosopher.data().quotes.length === 1 ? "" : "s"}
            </p>
            <QuoteBadge className="w-5 h-5" />
          </Row>
        </Column>
        {philosopher.data().biography && (
          <p className="lg:text-base max-h-24 text-justify overflow-hidden text-ellipsis">{philosopher.data().biography}</p>
        )}
      </Column>
    </Link>
  );
}

function LoadingState() {
  return (
    <FilledCard className="group cursor-pointer sm:h-full hover:shadow-glow transition-all duration-300">
      <Column className="items-center">
        <Column className="overflow-hidden items-center flex-grow group space-y-2">
          <div className="bg-gray-700 mx-2 animate-pulse h-28 w-28 rounded-full" />
          <div className="bg-gray-700 mx-2 w-full animate-pulse h-6 rounded-3xl mt-4" />
          <Row className="space-x-4 w-full relative">
            <div className="bg-gray-700 w-32 h-8 animate-pulse rounded-full" />
            <div className="bg-gray-700 w-32 h-8 animate-pulse rounded-full" />
          </Row>
        </Column>
        <div className="bg-gray-700 mx-2 animate-pulse h-2 rounded-3xl mt-4" />
        <div className="bg-gray-700 ml-2 mr-5 animate-pulse h-2 rounded-3xl mt-4" />
        <div className="bg-gray-700 ml-2 mr-5 animate-pulse h-2 rounded-3xl mt-4" />
        <div className="bg-gray-700 ml-2 mr-5 animate-pulse h-2 rounded-3xl mt-4" />
      </Column>
    </FilledCard>
  );
}

import { useNavigate } from "react-router";
import { Row } from "../../base/Layout/rows";
import { logEvent } from "firebase/analytics";
import React, { useEffect, useState } from "react";
import Footer from "../../components/landing/footer";
import FilledCard from "../../base/Cards/FilledCard";
import OutlinedCard from "../../base/Cards/OutlinedCard";
import FilledButton from "../../base/Buttons/FilledButton";
import { ColRow, Column } from "../../base/Layout/columns";
import GenericModal from "../../base/Dialogs/GenericModal";
import NavBarLanding from "../../components/landing/navBar";
import { analytics } from "../../providers/AnalitycsProvider";
import { logEventToAnalytics } from "../../helpers/AnalyticsHelper";
import { Helmet } from "react-helmet";
import { sendEventToDiscord, sendMessageToDiscord } from "../../helpers/DiscordHelper";
import { auth } from "../../helpers/AuthHelper";

const plans = [
  { name: "Solo", price: 3.75, originalPrice: 14.99, accounts: 1 },
  { name: "Duo", price: 6.25, originalPrice: 24.99, accounts: 2 },
  { name: "Family", price: 10.0, originalPrice: 39.99, accounts: 5 },
];

export default function Plans() {
  const navigate = useNavigate();
  const [modalSubscribe, showModalSubscribe] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    logEventToAnalytics("page_view", { page_path: "/plans" });
  }, []);

  return (
    <>
      <Helmet>
        <title>Philosophique - Plans</title>
        <meta name="description" content="Découvrez les plans d'abonnement de Philosophique !" />
      </Helmet>
      <NavBarLanding />
      <div className="w-full block pt-32 pb-24 transition-all duration-300">
        <div
          className="fixed h-screen top-0 w-full z-0 bg-cover"
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0,0.1),rgba(0, 0, 0, 0.8)),url(/images/girl_header.jpeg)`,
          }}
        />
        <ColRow className="items-center justify-center space-y-10 md:px-5 lg:px-10 lg:space-y-0 lg:space-x-10">
          <FilledCard className="bg-text-color p-6 md:p-10 bg-opacity-75 h-min backdrop-blur-md max-w-2xl lg:max-w-lg xl:max-w-xl pr-10 lg:pr-20 text-dark-blue">
            <Column className="space-y-2">
              <p className="text-4xl font-bold mb-2">Abonnement anticipé</p>
              <p>En nous rejoignant maintenant, vous soutiendrez ce projet et ferez partie d'une communauté dévouée.</p>
              <p>
                <strong>Nous vous gelerons les prix de nos forfaits à vie</strong> et prendrons en compte vos
                commentaires et suggestions à mesure que nous continuerons à améliorer et à développer la plateforme.
              </p>
            </Column>
          </FilledCard>
          <Column className="space-y-6 max-w-2xl">
            {plans.map((plan) => (
              <OutlinedCard className="flex-col backdrop-blur-sm relative space-y-5 justify-between p-8">
                <Row className="items-center">
                  <h4>{plan.name}</h4>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6 ml-5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <p className="font-bold ">x{plan.accounts}</p>
                  <FilledButton
                    onClick={() => {
                      showModalSubscribe(true);
                      logEventToAnalytics("begin_checkout", { name: "click_subscribe" });
                    }}
                    className="ml-auto w-min"
                  >
                    <p>S'abonner</p>
                  </FilledButton>
                </Row>
                <p className="text-sm">
                  <strong className="text-lg">€{plan.price} mensuellement</strong> / €{plan.originalPrice} mensuellement
                  lorsque la plateforme atteint la première version stable.
                </p>
              </OutlinedCard>
            ))}
          </Column>
        </ColRow>
      </div>
      <Footer />
      {showModalSubscribe && (
        <GenericModal
          open={modalSubscribe}
          showHeader={false}
          setOpen={(e) => showModalSubscribe(false)}
          showFooter={false}
          title={""}
        >
          <ColRow breakpoint="md" className="w-full justify-center items-center md:space-x-10 my-5 md:my-20 px-10">
            <div className="relative md:w-2/5 h-52 md:h-96">
              <img
                src="/images/buble03.png"
                className={"relative aspect-square rounded-full z-10 mx-auto border-4 border-text-light bg-text-light"}
              />
              <img
                src="/images/buble04.png"
                className={
                  "absolute aspect-square rounded-full top-0 -left-4 w-36  border-4 border-text-light bg-text-light"
                }
              />
              <img
                src="/images/buble02.png"
                className={
                  "absolute aspect-square rounded-full w-32 bottom-8 right-2 border-2 border-text-light bg-text-light"
                }
              />
              <img
                src="/images/buble01.png"
                className={
                  "absolute aspect-square rounded-full w-24 top-3 right-0  border-2 border-text-light bg-text-light"
                }
              />
            </div>
            <Column className="md:space-y-5 z-10 pt-10 sm:pb-5 items-center bg-gradient-to-t from-background-color via-background-color to-transparent text-center md:w-3/5">
              <p className="text-2xl sm:text-3xl">
                Le service d'abonnement n'est pas encore fonctionnel. Nous vous informerons dès qu'il sera disponible.
              </p>
            </Column>
          </ColRow>
        </GenericModal>
      )}
    </>
  );
}

import { showModalSubscribe } from "../components/modals/modalSubscribe";
import { logedIn } from "../helpers/AuthHelper";
import { auth } from "../helpers/AuthHelper";
import {
  getUserDocumentData,
  UpdateDocument,
  setUserDocumentData,
  getDocumentsWhere,
} from "../helpers/FirestoreHelper";
import { awaitForSeconds } from "../helpers/functions";

export function giveLightPoint(destinationId) {
  return new Promise(async (resolve, reject) => {
    if (!lightPointsAvailable()) return reject();

    let lightBadge = document.getElementById("profile_light_badge");
    if(!lightBadge) return resolve();
    let lightClone = lightBadge.cloneNode(true);
    lightBadge.parentElement.appendChild(lightClone);
    let lightText = document.getElementById("profile_light_text");

    lightText.classList.add("text-base");
    lightText.classList.add("xl:text-lg");
    lightText.classList.add("opacity-30");

    let destination = document.getElementById(destinationId).getBoundingClientRect();
    lightClone.classList.add("w-6");
    lightClone.classList.add("h-6");
    lightClone.classList.remove("w-5");
    lightClone.classList.remove("h-5");

    lightClone.style =
      "position: absolute;transform: translateX(" +
      (destination.left - lightBadge.getBoundingClientRect().right - 15).toString() +
      "px) translateY(" +
      (destination.top - lightBadge.getBoundingClientRect().top + 2).toString() +
      "px)";

    await awaitForSeconds(0.4);
    lightText.innerText = (parseInt(lightText.innerText) - 1).toString();
    lightText.classList.remove("transition-all");
    lightText.classList.remove("text-base");
    lightText.classList.remove("xl:text-lg");
    lightText.classList.remove("opacity-30");
    await awaitForSeconds(0.6);

    resolve();

    lightClone.remove();
  });
}

export function lightPointsAvailable() {
  if (!logedIn()) return false;
  let userData = getUserDocumentData();
  if (auth.currentUser && userData && userData.lightPoints === 0 && !isSubscribed()) {
    document.getElementById("profile_light_badge").classList.add("text-red-600");
    awaitForSeconds(1).then(() => {
      document.getElementById("profile_light_badge").classList.remove("text-red-600");
    });
    showModalSubscribe();
  }
  return auth.currentUser && userData && userData.lightPoints > 0;
}

export function hasPhilosophies(userDoc) {
  return userDoc && userDoc.data() && userDoc.data().philosophies && userDoc.data().philosophies.length > 0;
}
export function isPhilosopher(userDoc) {
  let userData = userDoc ? userDoc.data() : getUserDocumentData();
  return userData && userData.isPhilosopher;
}

export function isSubscribed(showModal = false) {
  let userData = getUserDocumentData();
  let subscribed = auth.currentUser && userData && userData.isSubscribed;
  if (showModal && !subscribed) {
    showModalSubscribe();
  }
  return subscribed;
}

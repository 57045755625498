import { Column } from "../../../base/Layout/columns";
import QuotesList from "../../../components/quotes/quotesList";
import ReadingList from "../../read/components/readingList";

export default function PhilosopherProfileSection({ userDoc }) {
  return (
    <Column className="space-y-5 w-full mt-5">
      <ReadingList
        title={
          "Livres " +
          (userDoc.data().displayName[0] === "e" || userDoc.data().displayName[0] === "E" ? "d'" : "de ") +
          userDoc.data().displayName
        }
        list={userDoc.data().books}
      />
      <QuotesList
        title={
          "Citations " +
          (userDoc.data().displayName[0] === "e" || userDoc.data().displayName[0] === "E" ? "d'" : "de ") +
          userDoc.data().displayName
        }
        list={userDoc.data().quotes}
      />
    </Column>
  );
}

import { Helmet } from "react-helmet";
import { NavigateBackStructure } from "../../components/navigation/structure";

export default function PageNotFounded({}) {
  return (
    <>
      <Helmet>
        <title>Page non trouvée</title>
        <meta name="description" content="Page non trouvée" />
      </Helmet>
      <p>Page non trouvée</p>
    </>
  );
}

import {
  getUserDocumentData,
  UpdateDocument,
  setUserDocumentData,
  getDocumentsWhere,
} from "../helpers/FirestoreHelper";

export function getAllPhilosophers() {
  return new Promise(async (resolve, reject) => {
    let philosophers = await getDocumentsWhere("users", "isPhilosopher", "==", true).catch(reject);
    let res = [];
    for (let index = 0; index < philosophers.length; index++) {
      let philosopher = philosophers[index].data();
      philosopher.id = philosophers[index].id;
      philosopher.path = philosophers[index].path;
      res.push(philosopher);
    }

    resolve(res);
  });
}

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { BookBadge, LigthBadge, QuoteBadge, SettingsBadge, VerifiedBadge } from "../../components/badges/badges";
import { Column } from "../../base/Layout/columns";
import { Row, RowBetween, RowEnd, RowStart } from "../../base/Layout/rows";
import PhilosopherProfileSection from "./components/philosopherProfile";
import QuoteCardProfile from "./components/quoteCardProfile";
import { auth, LogOut } from "../../helpers/AuthHelper";
import { getDocumentReference } from "../../helpers/FirestoreHelper";
import ProfileSettings from "./components/profileSettings";
import { onSnapshot } from "firebase/firestore";
import { hasPhilosophies, isPhilosopher } from "../../logic/UserLogic";
import PopOverMenu, { PopOverMenuItem } from "../../base/Popovers/Popover";
import Spinner from "../../base/Components/Spinner";
import FilledCard from "../../base/Cards/FilledCard";
import Selectable, { ControlledSelectable } from "../../base/Components/Selectable";
import ReadingList from "../read/components/readingList";
import { Helmet } from "react-helmet";

export default function Profile() {
  const [userDoc, setUserDoc] = useState(null);
  const [user, setUser] = useState(auth.currentUser);
  const [showModalSetting, setShowModalSettings] = useState(false);
  const [selectedTab, setSelectedTab] = useState("philosophies");
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    auth.onAuthStateChanged((result) => setUser(result));
  }, []);

  useEffect(() => {
    if (userDoc === null || (userDoc !== null && id !== userDoc.id)) {
      onSnapshot(getDocumentReference("/users/" + id), setUserDoc);
    }
  }, [user, userDoc, id]);

  return userDoc == null ? (
    <Spinner className="w-8 h-8 mt-10" />
  ) : (
    <>
      <Helmet>
        <title>{userDoc.data().displayName} | Philosophique</title>
        <meta name="description" content={userDoc.data().bio} />
      </Helmet>
      <Column className="w-full space-y-5 mt-3 min-h-screen">
        <Column className="w-full">
          <FilledCard className="w-full relative border-b-2 border-primary-color z-10">
            <Column className="space-y-10">
              <RowBetween className="items-start">
                <Row className="space-x-4">
                  {userDoc.data().profileImg && (
                    <img
                      src={userDoc.data().profileImg}
                      className="w-16 h-16 rounded-full aspect-square object-cover"
                      alt="user_img"
                    ></img>
                  )}
                  <Column className="justify-end space-y-0">
                    <p className="text-3xl font-bold shadow-lg group-hover:underline whitespace-nowrap text-ellipsis">
                      {userDoc.data().displayName}
                    </p>
                    {userDoc.data().createdByPlatform && (
                      <Row className={"space-x-1"}>
                        <VerifiedBadge />
                        <p className="text-xs">Profil créé par la plateforme</p>
                      </Row>
                    )}
                  </Column>
                </Row>
                {auth.currentUser && auth.currentUser.uid === userDoc.id && (
                  <PopOverMenu
                    className="bg-background-dark shadow-glow rounded-2xl right-0"
                    popoverButton={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                        />
                      </svg>
                    }
                    buttonClassName="icon-btn border border-gray-700 hover:bg-primary-color hover:bg-opacity-25"
                  >
                    <PopOverMenuItem isFirst={true} onClick={() => setShowModalSettings(true)}>
                      <SettingsBadge solid={true} />
                      <p>Paramétrage</p>
                    </PopOverMenuItem>
                    <PopOverMenuItem isLast={true} onClick={() => LogOut().then(() => navigate("/login"))}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
                        />
                      </svg>
                      <p>Se déconnecter</p>
                    </PopOverMenuItem>
                  </PopOverMenu>
                )}
              </RowBetween>
              <RowBetween>
                {!userDoc.data().createdByPlatform && (
                  <RowStart className="space-x-2">
                    {["philosophies", "illuminé"].map((e) => (
                      <ControlledSelectable
                        key={"tab_" + e}
                        setValue={() => setSelectedTab(e)}
                        value={selectedTab === e}
                      >
                        {e}
                      </ControlledSelectable>
                    ))}
                  </RowStart>
                )}
                {userDoc.data().isPhilosopher && (
                  <RowEnd className="space-x-4">
                    <Row className="space-x-1">
                      <QuoteBadge />
                      <p className="font-bold text-xs">{userDoc.data().quotedBy?.length} fois cité</p>
                    </Row>
                    <Row className="space-x-1">
                      <BookBadge />
                      <p className="font-bold text-xs">
                        {userDoc.data().books?.length} texte{userDoc.data().books?.length === 1 ? "" : "s"}
                      </p>
                    </Row>
                    <Row className="space-x-1">
                      <LigthBadge className="w-5 h-5" />
                      <p className="font-bold text-xs">
                        {userDoc.data().lightPoints} lumière{userDoc.data().lightPoints === 1 ? "" : "s"}
                      </p>
                    </Row>
                  </RowEnd>
                )}
              </RowBetween>
            </Column>
          </FilledCard>
          {selectedTab === "philosophies" && (
            <>
              <div className="relative pt-4">
                <div className="absolute w-1 h-16 bg-primary-color left-4 sm:left-10 -top-5 -z-10" />
                <div className="px-6 py-2 rounded-full bg-primary-color text-primary-on sm:mx-5 w-fit">
                  <p className="font-bold text-dark-background text-sm">
                    {hasPhilosophies(userDoc) ? userDoc.data().philosophies.length : 0}/5 philosophies
                  </p>
                </div>
              </div>
              {userDoc.data().philosophies?.length === 0 && (
                <p className="italic p-10 mx-auto">Aucune philosophie pour le moment</p>
              )}
              {userDoc.data().philosophies?.map((quote, k) => (
                <QuoteCardProfile
                  key={"quote_" + k}
                  index={k}
                  quote={quote}
                  refresh={() => setUserDoc(null)}
                  userDoc={userDoc}
                />
              ))}
            </>
          )}
        </Column>
        {userDoc.data().lightedBooks && selectedTab === "illuminé" && (
          <ReadingList title="Livres illuminés" list={userDoc.data().lightedBooks} />
        )}
        {isPhilosopher(userDoc) && <PhilosopherProfileSection userDoc={userDoc} />}
        {auth.currentUser && auth.currentUser.uid === userDoc.id && (
          <ProfileSettings open={showModalSetting} setOpen={setShowModalSettings} userDoc={userDoc} />
        )}
      </Column>
    </>
  );
}

import { useState } from "react";
import Form from "../base/Forms/Form";
import FormInput from "../base/Forms/FormInput";
import { Column } from "../base/Layout/columns";
import { NavigateBackStructure } from "../components/navigation/structure";
import FormTextarea from "../base/Forms/FormTextarea";
import { createDocument } from "../helpers/FirestoreHelper";
import { Timestamp } from "firebase/firestore";
import moment from "moment";
import { auth } from "../helpers/AuthHelper";
import { RoundedCheckBadge } from "../components/badges/badges";
import { useNavigate } from "react-router";
import { Row } from "../base/Layout/rows";
import Footer from "../components/landing/footer";
import OutlinedButton from "../base/Buttons/OutlinedButton";
import Spinner from "../base/Components/Spinner";
import FilledButton from "../base/Buttons/FilledButton";
import { Helmet } from "react-helmet";
import { sendMessageToDiscord } from "../helpers/DiscordHelper";
import { useEffect } from "react";

export default function Contact({}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function handleForm(e) {
    let elements = e.target.elements;

    let data = {
      date: Timestamp.fromMillis(moment()),
      user: auth.currentUser ? auth.currentUser.uid : null,
      email: elements.mail.value,
      message: elements.message.value,
      name: elements.name.value,
      traited: false,
    };

    setLoading("sending");
    await createDocument("/contact", data);
    await sendMessageToDiscord(
      "Nouveau message de " +
        data.name +
        " : " +
        "\n" +
        data.message +
        "\n" +
        "Email : " +
        data.email +
        "\n" +
        "ID : " +
        data.user
    );
    setLoading("sent");
  }

  return (
    <>
      <Helmet>
        <title>Philosophique - Contactez-nous</title>
        <meta
          name="description"
          content="Vous avez une question ? Vous souhaitez nous contacter ? N'hésitez pas à nous envoyer un message !"
        />
      </Helmet>
      <NavigateBackStructure>
        <Column className="max-w-4xl sm:w-4/5 mx-auto items-center space-y-20 px-5 py-14 sm:p-10 mt-20 border border-primary-color rounded-3xl">
          <h4 className="text-5xl">Contactez-nous</h4>
          {!loading && (
            <Form className="space-y-4 w-full max-w-xl" onSubmit={handleForm}>
              <FormInput id="name" required placeholder="Nom Prenom..." title="Comment tu t'apelles ?" />
              <FormInput
                title="Un email pour vous contacter"
                id="mail"
                type="email"
                required
                placeholder="email@example.com"
              />
              <FormTextarea title="Pourquoi nous contactez-vous ?" id="message" required placeholder="Message..." />
              <FilledButton type="submit">Envoyer</FilledButton>
            </Form>
          )}
          <Row className="space-x-2">
            {loading === "sending" && <Spinner />}
            {loading === "sent" && <RoundedCheckBadge solid={true} />}
            {loading && <p className="text-xl">{loading === "sending" ? "Envoi en cours" : "Message envoyé"}</p>}
          </Row>
          {loading === "sent" && (
            <OutlinedButton onClick={() => navigate("/")}>
              <p>Retour à la page d'accueil</p>
            </OutlinedButton>
          )}
        </Column>
      </NavigateBackStructure>
      <Footer />
    </>
  );
}

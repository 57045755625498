import { useEffect, useState } from "react";
import { getBook } from "../../../logic/BooksLogic";
import BookCardBig from "./bookCardBig";
import { Column } from "../../../base/Layout/columns";

export default function BooksList({ list = null }) {
  const [booksList, setBooksList] = useState();

  useEffect(() => {
    if (!booksList) getBooks();
  }, []);

  async function getBooks() {
    let arr = [];
    for (let index = 0; index < list.length; index++) {
      const ref = list[index];
      let res = await getBook(ref.id);
      arr.push(res);
    }
    setBooksList(arr);
  }

  return !list || list.length === 0 ? (
    <p className="text-center w-full py-10 italic">Il n'y a pas encore de livres</p>
  ) : (
    <Column className="p-2 sm:p-5 mx-auto max-w-5xl w-full space-y-5">
      {!booksList || booksList.length === 0
        ? list.map((e, k) => <BookCardBig key={"loading_" + k} book={null} />)
        : booksList.map((book, k) => <BookCardBig key={"bookCard_" + book.id} book={book} />)}
    </Column>
  );
}

import { Route, Routes, useNavigate } from "react-router";
import { BookBadge, DiscussBadge, QuoteBadge } from "../../components/badges/badges";
import { Column } from "../../base/Layout/columns";
import ProtectedRoute from "../../components/navigation/protectedRoute";
import { NavigateBackStructure } from "../../components/navigation/structure";
import AddQuote from "./addQuote";
import AddCurrent from "./addCurrent";
import DiscussionsDashboard from "./discussions/discussions";
import UsersDashboard from "./users";
import Books from "./books/books";
import OutlinedButton from "../../base/Buttons/OutlinedButton";
import OutlinedCard from "../../base/Cards/OutlinedCard";
import { GridCols3, GridCols4 } from "../../base/Layout/grids";
import { Row } from "../../base/Layout/rows";
import { useEffect, useState } from "react";
import { UpdateDocument, getDocumentsWhere } from "../../helpers/FirestoreHelper";
import moment from "moment";
import FilledButton from "../../base/Buttons/FilledButton";
import { addToastSuccess } from "../../base/Components/Toasts";
import Spinner from "../../base/Components/Spinner";
import Inbox from "./inbox";

export default function AdminRoutes({}) {
  return (
    <NavigateBackStructure>
      <ProtectedRoute role="admin">
        <Routes>
          <Route exact path="/books" element={<Books />} />
          <Route exact path="/users" element={<UsersDashboard />} />
          <Route exact path="/addQuote" element={<AddQuote />} />
          <Route exact path="/addCurrent" element={<AddCurrent />} />
          <Route exact path="/discussions" element={<DiscussionsDashboard />} />
          <Route path="/" element={<Dashboard />} />
        </Routes>
      </ProtectedRoute>
    </NavigateBackStructure>
  );
}

function Dashboard({}) {
  const navigate = useNavigate();

  return (
    <div className="max-w-5xl w-full mx-auto">
      <GridCols3 className="gap-2 pt-20">
        <OutlinedCard
          className="cursor-pointer hover:bg-white hover:bg-opacity-10"
          onClick={() => navigate("/adminInput/books")}
        >
          <Row className="space-x-4">
            <BookBadge className="w-10 h-10" />
            <p className="text-xl font-semibold">Books</p>
          </Row>
        </OutlinedCard>
        <OutlinedCard
          className="cursor-pointer hover:bg-white hover:bg-opacity-10"
          onClick={() => navigate("/adminInput/addQuote")}
        >
          <Row className="space-x-4">
            <QuoteBadge className="w-10 h-10" />
            <p className="text-xl font-semibold">Quotes</p>
          </Row>
        </OutlinedCard>
        <OutlinedCard
          className="cursor-pointer hover:bg-white hover:bg-opacity-10"
          onClick={() => navigate("/adminInput/users")}
        >
          <Row className="space-x-4">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-10 h-10">
              <path
                fillRule="evenodd"
                d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
                clipRule="evenodd"
              />
            </svg>
            <p className="text-xl font-semibold">Users</p>
          </Row>
        </OutlinedCard>
        <OutlinedCard
          className="cursor-pointer hover:bg-white hover:bg-opacity-10"
          onClick={() => navigate("/adminInput/addCurrent")}
        >
          <Row className="space-x-4">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-10 h-10">
              <path d="M15.75 8.25a.75.75 0 01.75.75c0 1.12-.492 2.126-1.27 2.812a.75.75 0 11-.992-1.124A2.243 2.243 0 0015 9a.75.75 0 01.75-.75z" />
              <path
                fillRule="evenodd"
                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM4.575 15.6a8.25 8.25 0 009.348 4.425 1.966 1.966 0 00-1.84-1.275.983.983 0 01-.97-.822l-.073-.437c-.094-.565.25-1.11.8-1.267l.99-.282c.427-.123.783-.418.982-.816l.036-.073a1.453 1.453 0 012.328-.377L16.5 15h.628a2.25 2.25 0 011.983 1.186 8.25 8.25 0 00-6.345-12.4c.044.262.18.503.389.676l1.068.89c.442.369.535 1.01.216 1.49l-.51.766a2.25 2.25 0 01-1.161.886l-.143.048a1.107 1.107 0 00-.57 1.664c.369.555.169 1.307-.427 1.605L9 13.125l.423 1.059a.956.956 0 01-1.652.928l-.679-.906a1.125 1.125 0 00-1.906.172L4.575 15.6z"
                clipRule="evenodd"
              />
            </svg>
            <p className="text-xl font-semibold">Currents</p>
          </Row>
        </OutlinedCard>
        <OutlinedCard
          className="cursor-pointer hover:bg-white hover:bg-opacity-10"
          onClick={() => navigate("/adminInput/discussions")}
        >
          <Row className="space-x-4">
            <DiscussBadge className="w-10 h-10" />
            <p className="text-xl font-semibold">Discussions</p>
          </Row>
        </OutlinedCard>
      </GridCols3>
      <hr className="mx-20 my-10" />
      <Inbox />
    </div>
  );
}

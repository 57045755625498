import { useEffect } from "react";
import { useState } from "react";
import { getDocument, UpdateDocument } from "../../../helpers/FirestoreHelper";
import { isSubscribed } from "../../../logic/UserLogic";
import { RoundedCheckBadge } from "../../../components/badges/badges";
import Form from "../../../base/Forms/Form";
import FormInput from "../../../base/Forms/FormInput";
import RadioOptionsGroup, { RadioGroupOption } from "../../../components/input/radioGroup";
import GenericModal from "../../../base/Dialogs/GenericModal";
import { ColRow } from "../../../base/Layout/columns";
import { addToastError, addToastSuccess } from "../../../base/Components/Toasts";
import TabsPanel, { TabPanel } from "../../../base/Navigation/TabsPanel";

const tabs = [
  {
    title: "Profil",
    disabled: false,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
        />
      </svg>
    ),
  },
  {
    title: "Confidentialité",
    disabled: false,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
        />
      </svg>
    ),
  },
  {
    title: "À propos",
    disabled: false,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
        />
      </svg>
    ),
  },
];

export default function ProfileSettings({ userDoc, open, setOpen }) {
  const [userData, setUserData] = useState(userDoc.data());
  const [loading, setLoading] = useState(false);
  const [profilePictures, setProfilePictures] = useState(null);

  useEffect(() => {
    if (!profilePictures) getDocument("users/profilePictures").then(setProfilePictures);
  }, [profilePictures]);

  async function handleSave() {
    setLoading(true);
    await UpdateDocument("users/" + userDoc.id, {
      displayName: userData.displayName,
      privacy: userData.privacy,
      profileImg: userData.profileImg,
    }).catch((e) => {
      setLoading(false);
      return addToastError("Erreur lors de la sauvegarde des paramètres : " + e.message);
    });
    setLoading(false);
    setOpen(false);
    addToastSuccess("Paramètres sauvegardés avec succès !");
  }

  return (
    <GenericModal title="Paramètres de profil" open={open} setOpen={setOpen} onActionButton={handleSave}>
      <TabsPanel tabs={tabs} className="h-full p-5">
        <TabPanel isLoading={loading} className="p-5">
          <Form className="space-y-5 w-full">
            <ColRow
              breakpoint="sm"
              className="sm:items-start items-center sm:justify-between space-y-4 sm:space-y-0 sm:space-x-10"
            >
              <div className="h-36 w-36 relative rounded-full">
                <div className="bg-gray-700 animate-pulse absolute" />
                <img
                  src={userData.profileImg}
                  alt="user_avatar"
                  className={"w-full h-full rounded-full aspect-square object-cover "}
                />
              </div>
              <div className="grid grid-cols-5 justify-evenly flex-grow gap-2">
                {profilePictures &&
                  profilePictures.data()[isSubscribed() ? "paths" : "pathsUnsubscribed"].map((img, k) => (
                    <div
                      key={"profile_img_" + k}
                      onClick={() => setUserData({ ...userData, profileImg: img })}
                      className="h-16 w-16 relative cursor-pointer hover:shadow-md hover:shadow-primary-color rounded-full"
                    >
                      <div className="bg-gray-700 animate-pulse absolute" />
                      <img
                        src={img}
                        alt="user_avatar"
                        className="w-full h-full rounded-full aspect-square object-cover"
                      />
                      {userData.profileImg === img && (
                        <RoundedCheckBadge className="text-blue-500 absolute bottom-0 right-0 w-6 h-6" />
                      )}
                    </div>
                  ))}
              </div>
            </ColRow>
            <div className="grid sm:grid-cols-2 gap-4">
              <FormInput
                title="Nom d'utilisateur"
                value={userData.displayName}
                minLength={3}
                maxLength={14}
                required={true}
                id="userName"
                setValue={(e) => setUserData({ ...userData, displayName: e.target.value })}
              />
            </div>
          </Form>
        </TabPanel>
        <TabPanel isLoading={loading} className="p-5">
          <Form>
            <RadioOptionsGroup
              title="Visibilité du profil"
              value={userData.privacy}
              onChange={(v) => setUserData({ ...userData, privacy: v })}
            >
              <RadioGroupOption
                className={userData.privacy === "all" ? "border-light-text" : "border-gray-800"}
                value={"all"}
                text="Toutes les personnes"
              />
              <RadioGroupOption
                className={userData.privacy === "friends" ? "border-light-text" : "border-gray-800"}
                value={"friends"}
                text={"Juste amis"}
              />
              <RadioGroupOption
                className={userData.privacy === "me" ? "border-light-text" : "border-gray-800"}
                value={"me"}
                text={"Juste moi"}
              />
            </RadioOptionsGroup>
          </Form>
        </TabPanel>
      </TabsPanel>
      <div className="h-20" />
    </GenericModal>
  );
}

import moment from "moment";
import { useRef, useEffect } from "react";
import { addCopyToClipboardToast } from "../base/Components/Toasts";

export function awaitForSeconds(seconds) {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, seconds * 1000);
  });
}

export function getRandomNumber(min, max) {
  const rand = min + Math.random() * (max - min);
  return Math.round(rand);
}

export function getHighlightedText(text, highlight) {
  // Split text on highlight term, include term itself into parts, ignore case
  const parts = text.split(new RegExp(`(${highlight})`, "gi"));
  return parts.map((part, i) => (part.toLowerCase() === highlight.toLowerCase() ? <mark key={i}>{part}</mark> : part));
}

export function getTimeLeft(timestamp) {
  const now = moment();
  const end = moment(timestamp);
  const duration = moment.duration(end.diff(now));

  const days = Math.floor(duration.asDays());
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  const dayString = days > 0 ? `${days} jour${days > 1 ? "s" : ""} ` : "";
  const hourString = `${hours.toString().padStart(2, "0")}h `;
  const minuteString = `${minutes.toString().padStart(2, "0")}min `;
  const secondString = `${seconds.toString().padStart(2, "0")}s`;

  return dayString + hourString + minuteString + secondString;
}

let observers = {};
export function observeElement(id, callback) {
  var observer = new IntersectionObserver(callback);
  observers[id] = observer;
  observer.observe(document.getElementById(id));
}

export function unObserveElement(id) {
  if (observers[id]) {
    observers[id].unobserve(document.getElementById(id));
    observers[id] = undefined;
  }
}

export function saveToClipboard(str, showToast = true, toastMessage = "Lien copié dans le presse-papier !") {
  navigator.clipboard.writeText(str);
  if (showToast)
    setTimeout(() => {
      addCopyToClipboardToast(toastMessage);
    }, 300);
}

export function getPathParams() {
  let params = window.location.href.split("?")[1];
  if (params !== undefined) {
    params = [
      {},
      ...params.split("&").map((e) => {
        return { [e.split("=")[0]]: e.split("=")[1] };
      }),
    ].reduce((a, b) => {
      return { ...a, ...b };
    });
    return params;
  } else return null;
}

export function useHorizontalScroll() {
  const elRef = useRef();
  useEffect(() => {
    const el = elRef.current;
    if (el) {
      const onWheel = (e) => {
        if (e.deltaY == 0 || e.deltaX !== 0) return;
        if (
          (el.scrollLeft === 0 && e.deltaY < 0) ||
          (Math.round(el.scrollLeft) === Math.round(el.scrollWidth - el.clientWidth) && e.deltaY > 0)
        )
          return;
        e.preventDefault();
        el.scrollTo({
          left: el.scrollLeft + e.deltaY * 2,
          behavior: "smooth",
        });
      };
      el.addEventListener("wheel", onWheel);
      return () => el.removeEventListener("wheel", onWheel);
    }
  }, []);
  return elRef;
}

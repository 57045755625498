import React from "react";
import { ColRow, Column } from "../../base/Layout/columns";
import { RowBetween, RowCol } from "../../base/Layout/rows";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <RowBetween id="footer" className="bg-dark-background-color max-w-5xl mx-auto relative z-10 px-8 lg:px-14 mt-20 pb-10 pt-6 lg:pt-10 text-sm rounded-t-3xl">
      <ColRow breakpoint="sm" className="mx-auto justify-between max-w-7xl">
        <Column className="space-y-2 items-center sm:items-start sm:space-y-1 w-full">
          <img src="/images/isologotype.svg" alt="logo" style={{ minWidth: "150px" }} className="w-3/4 sm:w-2/4" />
          <p className="text-sm sm:text-base">Apportez de la philosophie à votre quotidien.</p>
        </Column>
        <hr className="opacity-50 sm:hidden mt-5"/>
        <RowCol breakpoint="sm" className="justify-between sm:justify-end space-y-4 w-full h-full text-right items-end">
          <Link to="/plans" className="px-2 sm:px-8 cursor-pointer flex space-x-2 hover:underline">
            <p>Plans</p>
          </Link>
          <Link to="/contact" className="px-2 sm:px-8 cursor-pointer flex space-x-2 hover:underline">
            <p>Contact</p>
          </Link>
          <Link to="/termsAndConditions" className="px-2 sm:px-8 cursor-pointer flex space-x-2 hover:underline">
            <p>Termes et conditions</p>
          </Link>
        </RowCol>
      </ColRow>
    </RowBetween>
  );
}

import React from "react";
import { useNavigate } from "react-router";
import GradientButton from "../../../base/Buttons/GradientButton";

export default function Header({}) {
  const navigate = useNavigate();
  return (
    <div className="mt-5 flex flex-col -space-y-48 sm:space-y-0 sm:flex-row sm:py-5 sm:space-x-4 max-w-sm lg:space-x-10 justify-between sm:h-96 sm:max-w-5xl mx-auto">
      <div className="relative flex items-center justify-center h-full sm:w-2/4 lg:w-2/5">
        <img
          src="/images/buble01.png"
          className={
            "relative bg-cover rounded-full z-10 m-auto h-11/12 w-4/5 border-8 box-border bg-primary-color border-primary-color"
          }
        />
        <img
          src="/images/buble02.png"
          className={
            "absolute bg-cover rounded-full top-4 left-0 h-2/5 w-2/5 border-4 box-border bg-primary-color border-primary-color"
          }
        />
        <img
          src="/images/buble03.png"
          className={
            "absolute bg-cover rounded-full h-1/3 w-1/3 bottom-4 right-0 border-4 box-border bg-primary-color border-primary-color"
          }
        />
        <img
          src="/images/buble04.png"
          className={
            "absolute bg-cover rounded-full h-1/4 w-1/4 object-cover top-8 right-8  border-2 box-border bg-primary-color border-primary-color"
          }
        />
      </div>
      <div className="sm:w-2/4 z-20 bg-gradient-to-t pt-20 sm:pt-0 from-background-color via-background-color to-transparent lg:w-3/5 h-full text-center sm:text-left flex flex-col items-center sm:items-start justify-center space-y-2 lg:space-y-5">
        <h4 className="text-5xl lg:text-7xl bg-gradient-to-tl from-secondary-light to-secondary-dark bg-clip-text text-transparent">
          Contenu philosophique de qualité
        </h4>
        <p className="lg:text-lg lg:mr-24 leading-snug">
          Découvrez comment philosophique vous aide à vivre de manière plus consciente.
        </p>
        <GradientButton onClick={() => navigate("/book/DB62ClQVI0sDvr1Nirfk")}>
          <p>Lire le manifeste</p>
        </GradientButton>
      </div>
    </div>
  );
}

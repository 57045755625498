import { useEffect, useState } from "react";
import { getDocument, getUserDocumentData, UpdateDocument } from "../helpers/FirestoreHelper";
import PodcastList from "../components/podcasts/podcastList";
import { Column } from "../base/Layout/columns";
import Switch from "../base/Forms/Switch";
import { showModalEarlRegister } from "../components/modals/modalEarlyRegister";
import { auth } from "../helpers/AuthHelper";

export default function Podcasts({}) {
  const [userDocData, setUserDocData] = useState(null);
  const [notifyMe, setNotifyMe] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    let user = getUserDocumentData();
    if (user) setUserDocData(user);
    else {
      let subscribe = auth.onAuthStateChanged((user) => {
        getDocument("/users/" + user.uid).then((res) => {
          if (res.data()) {
            let user = res.data();
            user.path = res.ref.path;
            user.id = res.id;
            setUserDocData(user);
          }
        });
      });

      return () => subscribe();
    }
  }, []);

  useEffect(() => {
    setNotifyMe(userDocData ? userDocData.notifyPodcast : false);
  }, [userDocData]);

  useEffect(() => {
    if (userDocData) {
      UpdateDocument(userDocData.path, { notifyPodcast: notifyMe });
    } else if (notifyMe) {
      setNotifyMe(false);
      showModalEarlRegister();
    }
  }, [notifyMe]);

  return (
    <>
      <Column className="items-center w-full h-screen space-y-5">
        <img src="/images/chat.png" alt="chat" />
        <h4 className="text-center">Nous construisons la section podcast</h4>
        <Switch selected={notifyMe} setSelected={setNotifyMe} label="Avertissez-moi lorsque disponible" />
      </Column>
      {/* {userDocData && userDocData.podcastsStarted && userDocData.podcastsStarted.length > 0 && (
        <PodcastList list={userDocData.podcastsStarted} listName="podcastsStarted" title="Podcasts commencés" />
      )}
      {userDocData && userDocData.listenLaterList && userDocData.listenLaterList.length > 0 && (
        <PodcastList
          list={userDocData.listenLaterList}
          listName={"listenLaterList"}
          title="Podcasts sauvegardés pour plus tard"
        />
      )}
      <PodcastList title="Recommandations pour vous" /> */}
    </>
  );
}

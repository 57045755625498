import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { auth } from "../../helpers/AuthHelper";
import { getUserDocumentData, getDocument } from "../../helpers/FirestoreHelper";
import PageNotAllowed from "../../pages/default/pageNotAllowed";
import Spinner from "../../base/Components/Spinner";

const _ROLES = ["user", "philosopher", "admin"];

export default function ProtectedRoute({ role = "user", needSubscription = false, ...props }) {
  const navigate = useNavigate();
  const [user, setUser] = useState(auth.currentUser);
  const [userData, setUserData] = useState(null);
  const [isAllowed, setIsAllowed] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged((res) => {
      setUser(res);
      if (!res) navigate("/login");
      else {
        getDocument("/users/" + res.uid).then((doc) => {
          setUserData(doc.data());
        });
      }
    });
  }, []);

  useEffect(() => {
    if (user) {
      setUserData(getUserDocumentData());
    }
  }, [user]);

  useEffect(() => {
    setIsAllowed(
      user &&
        userData &&
        _ROLES.indexOf(userData["role"]) >= _ROLES.indexOf(role) &&
        (!needSubscription || userData["isSubscribed"])
    );
  }, [user, userData]);

  return isAllowed === null ? <Spinner /> : isAllowed ? props.children : <PageNotAllowed />;
}


export function checkRole(role) {
  return _ROLES.indexOf(getUserDocumentData()["role"]) >= _ROLES.indexOf(role);
}
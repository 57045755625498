import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ModalEarlyRegister from "./components/modals/modalEarlyRegister";
import Structure from "./components/navigation/structure";
import Book from "./pages/read/book";
import ThinkCurrent from "./pages/discover/thinkCurrent";
import EarlyRegister from "./pages/landing/earlyRegister";
import Login from "./pages/landing/login";
import Plans from "./pages/landing/plans";
import HistoryProvider from "./providers/HistoryProvider";
import Discussion from "./pages/discussions/discussion";
import ModalSubscribe from "./components/modals/modalSubscribe";
import TermsAndConditions from "./pages/landing/terms&conditions";
import AdminRoutes from "./pages/admin/dashboard";
import Contact from "./pages/contact";
import ConfirmationModal from "./base/Dialogs/ConfirmationModal";

function App() {
  return (
    <BrowserRouter history={HistoryProvider}>
      <Routes>
        <Route exact path="/plans" element={<Plans />} />
        <Route exact path="/earlyRegister" element={<EarlyRegister />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/termsAndConditions" element={<TermsAndConditions />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/book/:id/*" element={<Book />} />
        <Route exact path="/discussion/:id" element={<Discussion />} />
        <Route exact path="/current/:id/*" element={<ThinkCurrent />} />
        <Route path="/adminInput/*" element={<AdminRoutes />} />
        <Route path="*" element={<Structure />} />
      </Routes>
      <ModalEarlyRegister />
      <ModalSubscribe />
      <ConfirmationModal />
    </BrowserRouter>
  );
}

export default App;

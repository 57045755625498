import React, { useEffect, useState } from "react";
import { ChevronDownBadge, ChevronUpBadge, DeleteBadge, PlusBadge } from "../../../components/badges/badges";
import { Column } from "../../../base/Layout/columns";
import { Row, RowBetween } from "../../../base/Layout/rows";
import OutlinedButton from "../../../base/Buttons/OutlinedButton";
import FilledIconButton from "../../../base/Buttons/FilledIconButton";
import FormInput from "../../../base/Forms/FormInput";
import { getDocument } from "../../../helpers/FirestoreHelper";
import FormTextarea from "../../../base/Forms/FormTextarea";

export default function EditBook({ book, setBook }) {
  const [patterns, setPatterns] = useState(null);

  useEffect(() => {
    if (!patterns) {
      getDocument("/books/patterns").then((res) => setPatterns(res.data().images));
    }
  }, [patterns]);

  function changeValue(e) {
    setBook({ ...book, [e.target.id]: e.target.value ?? e.target.checked });
  }

  function resizeTextArea(e) {
    e.target.style.height = "5px";
    e.target.style.height = e.target.scrollHeight + 45 + "px";
  }

  return (
    <Column className="space-y-5 p-5 overflow-auto">
      <RowBetween>
        <Column className="w-2/4">
          <h1>Titre</h1>
          <FormInput id="title" onChange={changeValue} value={book.title} />
        </Column>
        <Row className="w-min space-x-4">
          <p className="text-xl whitespace-nowrap">le livre est public ?</p>
          <FormInput type="checkbox" id="isPublic" value={book.isPublic} onChange={changeValue} />
        </Row>
      </RowBetween>
      <Column className="w-4/5">
        <h2>General description</h2>
        <FormTextarea onInput={resizeTextArea} value={book.description} id="description" onChange={changeValue} />
        <p className={book.description?.length > 350 ? "text-red-500" : ""}>{book.description?.length} / 350</p>
      </Column>
      {!book.id && (
        <RowBetween className="space-x-5">
          <Column className="w-full">
            <p>Author (document id)</p>
            <FormInput id="author" value={book.author} onChange={changeValue} />
          </Column>
          <Column className="w-full">
            <p>Current of thinking Ref (doc id)</p>
            <FormInput id="current" value={book.current} onChange={changeValue} />
          </Column>
        </RowBetween>
      )}
      <RowBetween className="space-x-5">
        <Column className="w-full">
          <p>Image URL</p>
          <FormInput id="image" value={book.img} onChange={changeValue} />
        </Column>
        <Column className="w-full">
          <p>Author image credits (balise {"<a>"})</p>
          <FormInput id="imgAuthor" value={book.imgAuthor} onChange={changeValue} />
        </Column>
      </RowBetween>
      <Row className="justify-center space-x-5">
        {patterns &&
          patterns.map((p) => (
            <img
              key={p}
              onClick={() => setBook({ ...book, img: p })}
              className="w-full h-32 rounded-3xl border cursor-pointer object-cover hover:shadow-glow border-white"
              src={p}
            />
          ))}
      </Row>
      {book.body.map((section, k) => (
        <Column className="space-y-10" key={"section_" + k}>
          <div className="flex-col items-start pt-10 pb-5">
            <h2>Section subtitle</h2>
            <FormInput
              value={section["subtitle"]}
              onChange={(e) => {
                let editBook = { ...book };
                editBook.body[k]["subtitle"] = e.target.value;
                setBook(editBook);
              }}
            />
          </div>
          {section.paragraphs.map((p, pIndex) => (
            <div key={"section_" + k + "p_" + pIndex} className="flex-col relative items-start ">
              <p>
                Section paragraph {pIndex + 1} "{book.body[k]["subtitle"]}"
              </p>
              <FormTextarea
                value={p}
                onInput={resizeTextArea}
                onChange={(e) => {
                  let editBook = { ...book };
                  editBook.body[k]["paragraphs"][pIndex] = e.target.value;
                  setBook(editBook);
                }}
              />
              <Row className="absolute -bottom-4 px-3 left-3 space-x-2">
                <FilledIconButton
                  onClick={() => {
                    let editBook = { ...book };
                    editBook.body[k]["paragraphs"] = editBook.body[k]["paragraphs"].filter(
                      (e, index) => pIndex !== index
                    );
                    setBook(editBook);
                  }}
                >
                  <DeleteBadge />
                </FilledIconButton>
                <FilledIconButton
                  onClick={() => {
                    let editBook = { ...book };
                    editBook.body[k]["paragraphs"] = [
                      ...editBook.body[k]["paragraphs"].slice(0, pIndex + 1),
                      "",
                      ...editBook.body[k]["paragraphs"].slice(pIndex + 1),
                    ];
                    setBook(editBook);
                  }}
                >
                  <PlusBadge />
                </FilledIconButton>
                {pIndex < book.body[k]["paragraphs"].length - 1 && (
                  <FilledIconButton
                    onClick={() => {
                      let editBook = { ...book };
                      let p = editBook.body[k]["paragraphs"][pIndex];
                      editBook.body[k]["paragraphs"] = [
                        ...editBook.body[k]["paragraphs"].map((e, index) =>
                          pIndex === index ? editBook.body[k]["paragraphs"][index + 1] : pIndex === index - 1 ? p : e
                        ),
                      ];
                      setBook(editBook);
                    }}
                  >
                    <ChevronDownBadge />
                  </FilledIconButton>
                )}
                {pIndex > 0 && (
                  <FilledIconButton
                    onClick={() => {
                      let editBook = { ...book };
                      let p = editBook.body[k]["paragraphs"][pIndex - 1];
                      editBook.body[k]["paragraphs"] = [
                        ...editBook.body[k]["paragraphs"].map((e, index) =>
                          pIndex - 1 === index ? editBook.body[k]["paragraphs"][index + 1] : pIndex === index ? p : e
                        ),
                      ];
                      setBook(editBook);
                    }}
                  >
                    <ChevronUpBadge />
                  </FilledIconButton>
                )}
              </Row>
            </div>
          ))}
          <div className="">
            <OutlinedButton
              onClick={() => {
                let editBook = { ...book };
                editBook.body[k]["paragraphs"].push("");
                setBook(editBook);
              }}
            >
              <p>+ Add Paragraph</p>
            </OutlinedButton>
          </div>
          <div className="py-14   w-full">
            <hr className="w-2/4" />
          </div>
        </Column>
      ))}
      <div className="max-w-4xl ">
        <OutlinedButton
          onClick={() => {
            let editBook = { ...book };
            editBook.body.push({ subtitle: "", paragraphs: [""] });
            setBook(editBook);
          }}
        >
          <p>+ Add Section</p>
        </OutlinedButton>
      </div>
    </Column>
  );
}

import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import ThinkCurrentCard from "./components/thinkCurrentCard";
import { getCollection } from "../../helpers/FirestoreHelper";
import { GridCols3 } from "../../base/Layout/grids";
import { Column } from "../../base/Layout/columns";
import { Helmet } from "react-helmet";

let currentsDownloaded = null;
let rowSpans = [2, 1, 1, 1, 2, 1, 1, 3];
let colSpans = [1, 2, 1, 1, 2, 1, 1, 3];

export default function Discover({}) {
  const navigate = useNavigate();
  const [currents, setCurrents] = useState(currentsDownloaded);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    if (!currents) {
      getCollection("/currents").then((res) => {
        res = res.sort((a, b) => Math.random() - Math.random());
        currentsDownloaded = res;
        setCurrents(res);
      });
    }
  }, [currents]);

  return (
    <>
      <Helmet>
        <title>Courants de pensée - Philosophique</title>
        <meta name="description" content="Découvrez les differents courants de pensée sur philosophique !" />
      </Helmet>
      <div className="py-5">
        <h1>Courants de pensée</h1>
      </div>
      <GridCols3 className="hidden sm:grid gap-2 min-h-screen">
        {currents &&
          currents.map((current, k) => {
            return (
              <ThinkCurrentCard
                key={"current_" + k}
                onClick={() => navigate("/current/" + current.id)}
                rowSpan={rowSpans[k]}
                colSpan={colSpans[k]}
                image={current.data().img}
              >
                {current.data().name}
              </ThinkCurrentCard>
            );
          })}
      </GridCols3>
      <Column className="w-full space-y-4 sm:hidden min-h-screen">
        {currents &&
          currents.map((current, k) => {
            return (
              <ThinkCurrentCard
                key={"current_" + k}
                onClick={() => navigate("/current/" + current.id)}
                rowSpan={rowSpans[k]}
                colSpan={colSpans[k]}
                image={current.data().img}
              >
                {current.data().name}
              </ThinkCurrentCard>
            );
          })}
      </Column>
    </>
  );
}

import React from "react";
import { Link } from "react-router-dom";

export default function UserNameLink({ className = "", user, showName = true, fromLeftToRight = false }) {
  let userData = user?.data !== undefined ? user.data() : user;

  return user == undefined ? (
    <div className="mt-auto flex w-full items-center gap-3">
      <div className="bg-gray-700 w-32 h-8 animate-pulse hidden xl:block rounded-full" />
      <div className="bg-gray-700 w-12 h-12 animate-pulse rounded-full ml-auto" />
    </div>
  ) : (
    <Link
      className={
        className + " flex items-center w-full hover:underline cursor-pointer " + (fromLeftToRight ? "flex-row-reverse" : "")
      }
      to={"/user/" + user.id}
    >
      {showName && <p className="inline mx-2 truncate">{userData.displayName}</p>}
      <img
        src={userData.profileImg}
        alt="user_profile_img"
        className="h-full aspect-square rounded-full object-cover"
      />
    </Link>
  );
}

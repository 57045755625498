import { useEffect, useState } from "react";
import { getDocument } from "../../../helpers/FirestoreHelper";
import { GridCols3 } from "../../../base/Layout/grids";
import PhilosopherCard from "./philosopherCard";

export default function PhilosophersList({ list }) {
  const [philosophers, setPhilosophers] = useState(null);

  useEffect(() => {
    if (!philosophers && list) getPhilosophers();
  }, [list]);

  async function getPhilosophers() {
    let arr = [];
    for (let index = 0; index < list.length; index++) {
      const ref = list[index];
      let res = await getDocument(ref.path);
      arr.push(res);
    }
    setPhilosophers(arr);
  }

  return (
    <>
      {!list && <p className="text-center w-full py-10 italic">Il n'y a pas encore de philosophes</p>}
      <GridCols3 className="sm:gap-5 w-full max-w-5xl mx-auto px-4 sm:px-16 py-10">
        {!philosophers && list && list.map((e, k) => <PhilosopherCard key={"philosopher_" + k} philosopher={null} />)}
        {philosophers &&
          philosophers.map((e, k) => <PhilosopherCard key={"philosopher_" + k} philosopher={e} />)}
      </GridCols3>
    </>
  );
}

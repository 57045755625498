import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Column } from "../../base/Layout/columns";
import { RowEnd } from "../../base/Layout/rows";
import { awaitForSeconds } from "../../helpers/functions";
import { Link } from "react-router-dom";
import ReadMenu from "./components/readMenu";
import { ChevronLeftBadge, ChevronRightBadge } from "../../components/badges/badges";
import { getCharWaitTime } from "../../logic/BooksLogic";

export default function BookChapter({ book, chapters }) {
  const { id, page } = useParams();
  const [chapter, setChapter] = useState(null);
  const [presentation, setPresentation] = useState([
    ...chapters.map((c) => ({
      presenting: false,
      currentParagraph: 0,
      finished: false,
      page: c.id,
    })),
  ]);

  useEffect(() => {
    window.scrollTo(0, 0, { behavior: "smooth" });
    localStorage.setItem("bookPage" + id, page.toString());
    setChapter(chapters[parseInt(page) - 1].data());

    return () => {
      setPresentation((prevPresentation) => {
        return prevPresentation.map((p, k) => (k === page - 1 ? { ...p, presenting: false } : p));
      });
    };
  }, [page]);

  useEffect(() => {
    // We start reading the chapter
    if (chapter && presentation[page - 1].finished == false && presentation[page - 1].presenting == false) {
      readChapter();
    }
  }, [chapter, presentation]);

  function changePresentationValue(data) {
    setPresentation((prevPresentation) => {
      return prevPresentation.map((p, k) => (k === page - 1 ? { ...p, ...data } : p));
    });
  }

  async function readChapter() {
    // If the chapter is already being presented, we return
    if (presentation[page - 1].presenting) return;
    else changePresentationValue({ presenting: true });

    // If next_paragraph or prev_paragraph exists, we remove them
    document.getElementById("next_paragraph")?.remove();
    document.getElementById("prev_paragraph")?.remove();

    // We get the subtitle element
    const subtitle = document.getElementById("subtitle_" + page);

    if (subtitle.children[1].innerText.length > 0) {
      window.scrollTo({
        top: subtitle.offsetTop - 100,
        behavior: "smooth",
      });
      await awaitForSeconds(1.2);
      while (subtitle.children[1].innerText.length > 0) {
        // Si on est en pause, on attend 0.5 secondes pour verifier si on peut reprendre
        if (document.getElementById("pause")) {
          await awaitForSeconds(0.5);
          continue;
        }

        if (document.getElementById("next_paragraph")) {
          subtitle.children[0].innerText += subtitle.children[1].innerText;
          subtitle.children[1].innerText = "";
          document.getElementById("next_paragraph").remove();
          break;
        }

        let char = subtitle.children[1].textContent[0];
        subtitle.children[0].textContent += char;
        subtitle.children[1].textContent = subtitle.children[1].textContent.substring(char.length);

        await awaitForSeconds(getCharWaitTime(char));
      }
      // we make a pause before starting the paragraphs
      await awaitForSeconds(1.5);
    }

    // Get all elements with "paragraph_" id
    const paragraphs = document.querySelectorAll('[id^="paragraph_' + page + '_"]');

    for (let index = presentation[page - 1].currentParagraph; index < paragraphs.length; index++) {
      const paragraph = paragraphs[index];
      changePresentationValue({ currentParagraph: index });

      if (document.getElementById("skip")) {
        paragraph.children[0].innerText += paragraph.children[1].innerText;
        paragraph.children[1].innerText = "";
        continue;
      }

      // We scroll to the paragraph only if it has text left to read
      if (paragraph.children[1].innerText.length > 0)
        window.scrollTo({
          top: paragraph.offsetTop - 100,
          behavior: "smooth",
        });

      // We wait 0.3 seconds before starting each paragraph
      await awaitForSeconds(0.3);

      while (paragraph.children[1].innerText.length > 0) {
        if (document.getElementById("pause")) {
          await awaitForSeconds(0.1);
          continue;
        }
        // If the user wants to skip the paragraph or skip all the chapter
        if (document.getElementById("next_paragraph") || document.getElementById("skip")) {
          paragraph.children[0].innerText += paragraph.children[1].innerText;
          paragraph.children[1].innerText = "";
          if (document.getElementById("next_paragraph")) document.getElementById("next_paragraph").remove();
          break;
        }
        // If the user wants to go back to the previous paragraph
        if (document.getElementById("prev_paragraph")) {
          let porcentageRead = paragraph.children[0].innerText.length / paragraph.children[1].innerText.length;
          paragraph.children[1].innerText = paragraph.children[0].innerText + paragraph.children[1].innerText;
          paragraph.children[0].innerText = "";
          // If the paragraph is less than 20% read, we return to the previous paragraph
          if (index > 0 && porcentageRead < 0.2) {
            paragraphs[index - 1].children[1].innerText = paragraphs[index - 1].children[0].innerText;
            paragraphs[index - 1].children[0].innerText = "";
            index--;
          }
          document.getElementById("prev_paragraph").remove();
          index--;
          break;
        }

        let char = paragraph.children[1].textContent[0];
        paragraph.children[0].textContent += char;
        paragraph.children[1].textContent = paragraph.children[1].textContent.substring(char.length);

        await awaitForSeconds(getCharWaitTime(char));
      }
    }

    if (document.getElementById("skip")) document.getElementById("skip").remove();
    changePresentationValue({ finished: true, presenting: false });
  }

  async function restart() {
    const subtitle = document.getElementById("subtitle_" + page);
    subtitle.children[1].innerText = subtitle.children[1].innerText;
    subtitle.children[0].innerText = "";

    const paragraphs = document.querySelectorAll('[id^="paragraph_' + page + '_"]');
    for (let index = 0; index < paragraphs.length; index++) {
      const element = paragraphs[index];
      element.children[1].innerText = element.children[0].innerText;
      element.children[0].innerText = "";
    }

    changePresentationValue({ presenting: false, currentParagraph: 0, finished: false });
  }

  function getNextPage() {
    if (page === book.chaptersCount) return window.location.pathname;
    return (
      window.location.pathname.substring(0, window.location.pathname.length - 1) +
      (parseInt(window.location.pathname[window.location.pathname.length - 1]) + 1)
    );
  }

  function getPrevPage() {
    if (page === 1) return window.location.pathname;
    return (
      window.location.pathname.substring(0, window.location.pathname.length - 1) +
      (parseInt(window.location.pathname[window.location.pathname.length - 1]) - 1)
    );
  }

  return (
    chapter && (
      <>
        <Column key={page + "_column"} className="p-5 max-w-5xl mx-auto space-y-5">
          <small className="pl-14 pt-2 lg:pl-0">Chapitre {page}</small>
          <h1 className="pt-5" id={"subtitle_" + page}>
            <span>{presentation[page - 1].finished ? chapter.subtitle : ""}</span>
            <span className="text-gray-700">{presentation[page - 1].finished ? "" : chapter.subtitle}</span>
          </h1>
          {chapter.paragraphs.map((p, k) => (
            <p key={"p_" + page + "_" + k} id={"paragraph_" + page + "_" + k} className="text-2xl lg:text-3xl">
              <span>{k < presentation[page - 1].currentParagraph || presentation[page - 1].finished ? p : ""}</span>
              <span className="text-gray-700">
                {k >= presentation[page - 1].currentParagraph && !presentation[page - 1].finished ? p : ""}
              </span>
            </p>
          ))}
        </Column>
        <RowEnd className="space-x-2 pt-10 max-w-7xl mx-auto px-20">
          <Link
            to={getPrevPage()}
            className={
              "btn border text-primary-color border-primary-color rounded-full pl-4 " +
              (page === 1 ? "opacity-50 cursor-not-allowed" : "  ")
            }
          >
            <ChevronLeftBadge />
            <p>Precedent</p>
          </Link>
          <Link
            to={getNextPage()}
            className={
              "btn border text-primary-color border-primary-color rounded-full pr-4 " +
              (page === book.chaptersCount ? "opacity-50 cursor-not-allowed" : "  ")
            }
          >
            <p>Suivant</p>
            <ChevronRightBadge />
          </Link>
        </RowEnd>
        <div className="h-64" />
        <ReadMenu
          presenting={presentation[page - 1].presenting}
          page={page}
          pages={book.chaptersCount}
          onReplay={restart}
          canSkip
        />
      </>
    )
  );
}

import { useEffect, useState } from "react";
import { Row } from "../../base/Layout/rows";
import BookCard from "./components/bookCard";
import { auth } from "../../helpers/AuthHelper";
import BookCardBig from "./components/bookCardBig";
import { Column } from "../../base/Layout/columns";
import ReadingList from "./components/readingList";
import { GridCols2 } from "../../base/Layout/grids";
import { getBook, getBooksWhere } from "../../logic/BooksLogic";
import { getDocument, getUserDocumentData } from "../../helpers/FirestoreHelper";
import { Helmet } from "react-helmet";
import TabsPanel, { TabPanel } from "../../base/Navigation/TabsPanel";

export default function Read({}) {
  const [userDocData, setUserDocData] = useState(null);
  const [recomendations, setRecomendations] = useState(null);
  const [weekBook, setWeekBook] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (weekBook === null)
      getDocument("/books/bookOfTheWeek").then((res) => getBook(res.data().ref.id).then(setWeekBook));
  }, [weekBook]);

  useEffect(() => {
    if (!recomendations) {
      getBooksWhere("isPublic", "==", true).then((res) => {
        let userDoc = getUserDocumentData();
        if (userDoc) {
          res = res.filter((book) => userDoc.booksFinished?.find((e) => e.id === book.id) === undefined);
          res = res.filter((book) => userDoc.booksStarted?.find((e) => e.id === book.id) === undefined);
          res = res.filter((book) => userDoc.readLaterList?.find((e) => e.id === book.id) === undefined);
          setRecomendations(res);
        } else setRecomendations(res);
      });
    }
  }, [recomendations]);

  useEffect(() => {
    if (userDocData === null && auth.currentUser) setUserDocData(getUserDocumentData());
    else if (userDocData === null)
      auth.onAuthStateChanged((user) =>
        getDocument("/users/" + user.uid).then((res) => {
          let user = res.data();
          user.path = res.ref.path;
          user.id = res.id;
          setUserDocData(user);
        })
      );
  }, [userDocData]);

  return (
    <>
      <Helmet>
        <title>Livres - Philosophique</title>
        <meta name="description" content="Découvrez les differents livres sur philosophique !" />
        <link rel="canonical" href="https://philosophique.app/read/" />
      </Helmet>
      <Column className="space-y-10 w-full mt-5 min-h-screen">
        <div className="flex flex-col items-start w-full space-y-2">
          <h1>Livre de la semaine</h1>
          <BookCardBig book={weekBook} />
        </div>
        <TabsPanel tabs={[{ title: "Recommandations" }, { title: "Livres commencés" }, { title: "Lire plus tard" }]}>
          <TabPanel className="pt-5">
            <ReadingList title="Recommandations pour vous" listName={"recomendations"} list={recomendations} />
          </TabPanel>
          <TabPanel className="pt-5">
            <ReadingList list={userDocData?.booksStarted} listName="booksStarted" title="Livres commencés" />
          </TabPanel>
          <TabPanel className="pt-5">
            <ReadingList list={userDocData?.readLaterList} listName={"readLaterList"} title="Lire plus tard" />
          </TabPanel>
        </TabsPanel>
      </Column>
    </>
  );
}

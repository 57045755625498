import { Helmet } from "react-helmet";
import BookResume from "./bookResume";
import BookChapter from "./bookChapter";
import React, { useEffect, useState } from "react";
import { getCollection } from "../../helpers/FirestoreHelper";
import { Route, Routes, useNavigate, useParams } from "react-router";
import { getBook, getBookMinutesRead } from "../../logic/BooksLogic";
import { NavigateBackStructure } from "../../components/navigation/structure";

export default function Book({}) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [book, setBook] = useState(null);
  const [chapters, setChapters] = useState(null);

  useEffect(() => {
    // TODO
    // if (localStorage.getItem("bookPage" + id)) {
    //   navigate("/chapter/" + localStorage.getItem("bookPage" + id));
    // }

    getBook(id).then(setBook);
    getCollection("books/" + id + "/chapters").then(setChapters);
  }, []);

  return (
    book &&
    chapters && (
      <>
        <Helmet>
          <title>{book?.title}</title>
          <meta
            name="description"
            content={getBookMinutesRead(chapters) + " minutes de lecture - " + book.description}
          />
          <link rel="canonical" href={"https://philosophique.app/book/" + id} />
        </Helmet>
        <NavigateBackStructure to={window.location.pathname.includes("chapter") ? "/book/" + id : "/read"}>
          <Routes>
            <Route path="/chapter/:page" element={<BookChapter book={book} chapters={chapters} />} />
            <Route path="/" index element={<BookResume book={book} setBook={setBook} chapters={chapters} />} />
          </Routes>
        </NavigateBackStructure>
      </>
    )
  );
}

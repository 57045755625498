import { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Form from "../../base/Forms/Form";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { Row } from "../../base/Layout/rows";
import FormInput from "../../base/Forms/FormInput";
import { Column } from "../../base/Layout/columns";
import Spinner from "../../base/Components/Spinner";
import Footer from "../../components/landing/footer";
import OutlinedCard from "../../base/Cards/OutlinedCard";
import FilledButton from "../../base/Buttons/FilledButton";
import { addToastError } from "../../base/Components/Toasts";
import OutlinedButton from "../../base/Buttons/OutlinedButton";
import { RoundedCheckBadge } from "../../components/badges/badges";
import { awaitForSeconds, getPathParams } from "../../helpers/functions";
import { createUserDocument, getDocument } from "../../helpers/FirestoreHelper";
import { sendSignInLink, signInLink, signInWithGoogle } from "../../helpers/AuthHelper";

export default function Login({}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(null);
  const [errorMesage, setErrorMessage] = useState(null);
  const [linkSent, setLinkSent] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    let params = getPathParams();
    if (params && params.apiKey && localStorage.getItem("emailForSignIn")) {
      setLoading("Vérification de l'e-mail...");
      signInLink(localStorage.getItem("emailForSignIn"))
        .then(handleLogin)
        .catch((error) =>
          setErrorMessage(
            "Erreur lors de la creation du compte. Si le problème persiste, veuillez contacter le support."
          )
        );
    }
  }, []);

  async function handleLogin(user) {
    await getDocument("/users/" + user.uid)
      .then(async (res) => {
        if (res._document === null) {
          setLoading("Creation du compte...");
          await createUserDocument(user);
          setLoading("Compte créé !");
          await awaitForSeconds(1.5);
          navigate("/");
        } else {
          navigate("/");
        }
      })
      .catch((err) => {
        setErrorMessage("Une erreur de connexion s'est produite, veuillez réessayer.");
      });

    setLoading(null);
  }

  async function handleSubmit(e) {
    const email = e.target.elements.email.value;

    // Validate email
    if (!email) {
      return addToastError("Veuillez entrer votre e-mail.");
    }

    setLoading("Envoi du lien de connexion...");
    sendSignInLink(email, "login").then(() => {
      setLinkSent(true);
      setLoading(null);
    });
  }

  return (
    <>
      <Helmet>
        <title>Philosophique - Connexion</title>
        <meta name="description" content="Connectez-vous à votre compte Philosophique !" />
      </Helmet>
      <div className="h-screen">
        <OutlinedCard className="max-w-4xl w-full !p-10 items-center flex flex-col justify-center mx-auto mt-20">
          {loading && (
            <Column className="my-20 space-y-4">
              <p>{loading}</p>
              <Spinner />
            </Column>
          )}
          {!loading && errorMesage && !linkSent && (
            <>
              <h1>Erreur de connexion</h1>
              <p className="text-xl text-center">{errorMesage}</p>
              <Column className="space-y-4 mt-20 items-center">
                <OutlinedButton onClick={() => navigate("/")}>
                  <p>Retour à la page d'accueil</p>
                </OutlinedButton>
                <FilledButton onClick={() => setErrorMessage(null)}>
                  <p>Réessayer</p>
                </FilledButton>
              </Column>
            </>
          )}
          {linkSent && !loading && (
            <Column className="items-center space-y-3 my-20">
              <RoundedCheckBadge solid={true} />
              <p className="text-lg text-center">Nous vous avons envoyé un lien vers votre e-mail.</p>
            </Column>
          )}
          {!loading && !errorMesage && !linkSent && (
            <>
              <h1>Connectez-vous à votre compte</h1>
              <p className="mt-5 text-lg">Entrez votre e-mail pour recevoir un lien de connexion.</p>
              <Form className="my-10 w-full md:w-2/4 space-y-4" id={"login_form"} onSubmit={handleSubmit}>
                <FormInput title="Email" placeholder="example@mail.com" id="email" type="email" />
                <FilledButton type="submit" className="w-full flex justify-center">
                  <p>Envoyer le lien de connexion</p>
                </FilledButton>
              </Form>
              <p className="mb-5 text-center">
                Vous n'avez pas de compte ?{" "}
                <Link to={"/earlyRegister"} className="text-blue-500 hover:underline cursor-pointer">
                  Inscription anticipée
                </Link>
              </p>
              <hr className="w-full text-light-brown" />
              <Column className="mt-10 items-center">
                <FilledButton
                  onClick={() => {
                    setLoading("En attente d'une réponse");
                    signInWithGoogle().then((res) => handleLogin(res));
                  }}
                  hasIcon={true}
                >
                  <img src="/images/googleIcon.png" width="24px" height="24px" />
                  <p>S'identifier avec Google</p>
                </FilledButton>
                <Row className="space-x-4 mt-5">
                  <Link className="hover:underline" to="/termsAndConditions">
                    Termes et conditions
                  </Link>
                  <p>|</p>
                  <Link className="hover:underline" to="/">
                    Retour a l'accueil
                  </Link>
                </Row>
              </Column>
            </>
          )}
        </OutlinedCard>
      </div>
      <Footer />
    </>
  );
}

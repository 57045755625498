import { Row } from "../../../base/Layout/rows";
import UserNameLink from "../../../components/navigation/userNameLink";
import { auth } from "../../../helpers/AuthHelper";

export default function CommentCard({ comment }) {
  return (
    <div id={"comment_" + comment.id} className="flex flex-col py-3 mb-5 sm:mb-10 relative w-full">
      <div className="mx-4 sm:mx-5 p-4 sm:p-5 text-justify border border-primary-color rounded-lg space-y-3">
        {comment.comments.map((c, k) => (
          <p key={"comment_" + k}>{c}</p>
        ))}
      </div>
      <Row
        className={
          "sticky -bottom-5 z-10 w-full -mt-5  " +
          (auth.currentUser && auth.currentUser.uid === comment.author.id ? "justify-end" : "")
        }
      >
        <UserNameLink
          className="border w-8 h-8 sm:h-10 sm:w-10 bg-primary-color border-primary-color rounded-full"
          user={comment.author}
          showName={false}
        />
      </Row>
    </div>
  );
}

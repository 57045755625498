import {
  getAuth,
  GoogleAuthProvider,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { showModalEarlRegister } from "../components/modals/modalEarlyRegister";
import { app } from "../providers/FirebaseProvider";
import { getDocument, getUserDocumentData, setUserDocumentData } from "./FirestoreHelper";
import { sendEventToDiscord } from "./DiscordHelper";
import { logEventToAnalytics } from "./AnalyticsHelper";

export const auth = getAuth(app);
auth.languageCode = "fr";
const provider = new GoogleAuthProvider();

auth.onAuthStateChanged((user) => {
  if (user)
    getDocument("/users/" + user.uid).then((res) => {
      if (res.data()) {
        let user = res.data();
        user.path = res.ref.path;
        user.id = res.id;
        setUserDocumentData(user);
      }
    });
});

export function signInWithGoogle() {
  return new Promise((resolve, reject) => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;

        const isFirstLogin = result.user.metadata.creationTime === result.user.metadata.lastSignInTime;
        if (isFirstLogin) {
          sendEventToDiscord(
            "Un nouvel utilisateur a rejoint la plateforme (connexion google) : " + result.user.email + " !"
          );
          logEventToAnalytics("sign_up", { method: "google", email: result.user.email });
        }

        // The signed-in user info.
        resolve(result.user);
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData?.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);

        console.error("Error Code : " + errorCode);
        console.error(errorMessage);
        console.error("Email : " + email);

        reject(error);
      });
  });
}

export function sendSignInLink(email) {
  return new Promise((resolve, reject) => {
    const actionCodeSettings = {
      url: process.env["REACT_APP_IS_PROD"] == 0 ? "http://localhost:3000/" : "https://philosophique.app/",
      handleCodeInApp: true,
    };
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        window.localStorage.setItem("emailForSignIn", email);
        resolve();
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        reject(error);
      });
  });
}

export function signInLink(email) {
  return new Promise(async (resolve, reject) => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      await signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          localStorage.removeItem("emailForSignIn");
          const isFirstLogin = result.user.metadata.creationTime === result.user.metadata.lastSignInTime;
          if (isFirstLogin) {
            sendEventToDiscord(
              "Un nouvel utilisateur a rejoint la plateforme (connexion anticipé) : " + result.user.email + " !"
            );
            logEventToAnalytics("sign_up", { method: "email_link", email: result.user.email });
          }
          resolve(result.user);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    } else {
      reject();
    }
  });
}

export function LogOut() {
  return new Promise((resolve, reject) => {
    signOut(auth).then(resolve).catch(reject);
  });
}

export function logedIn() {
  if (!auth.currentUser) {
    showModalEarlRegister();
  }
  return auth.currentUser !== null && auth.currentUser !== undefined;
}

import moment from "moment";
import React, { useEffect, useState } from "react";
import { auth } from "../../../helpers/AuthHelper";
import { UpdateDocument } from "../../../helpers/FirestoreHelper";
import { saveToClipboard } from "../../../helpers/functions";
import { getQuote, removeQuoteFromMyPhilosophies } from "../../../logic/QuotesLogic";
import { ChevronDownBadge, ChevronUpBadge, DeleteBadge, ShareBadge } from "../../../components/badges/badges";
import { Column } from "../../../base/Layout/columns";
import { Row, RowBetween } from "../../../base/Layout/rows";
import UserNameLink from "../../../components/navigation/userNameLink";
import PopOverMenu, { PopOverMenuItem } from "../../../base/Popovers/Popover";
import FilledCard from "../../../base/Cards/FilledCard";

export default function QuoteCardProfile({ quote, index, userDoc, refresh }) {
  const [quoteData, setQuoteData] = useState(null);

  useEffect(() => {
    if (quoteData == null) getQuote(quote.ref.path).then(setQuoteData);
  }, [quote]);

  async function moveQuote(up = true) {
    let element = userDoc.data().philosophies[index];
    let philosophies = [
      ...(index > 0 ? userDoc.data().philosophies.slice(0, index - (up ? 1 : 0)) : []),
      up ? element : userDoc.data().philosophies[index + 1],
      up ? userDoc.data().philosophies[index - 1] : element,
      ...(index < userDoc.data().philosophies.length
        ? userDoc.data().philosophies.slice(index + (up ? 1 : 2), userDoc.data().philosophies.length)
        : []),
    ];

    await UpdateDocument("/users/" + auth.currentUser.uid, { philosophies: philosophies });

    // css animation
    refresh();
  }

  return (
    <div className="relative pt-4 sm:pl-4">
      <div className="absolute w-1 h-16 bg-primary-color left-4 sm:left-10 -top-5 -z-10" />
      <FilledCard className="relative mr-auto border-b-2 border-primary-color max-w-3xl w-full ">
        <Column className="space-y-2 sm:space-y-0">
          {!quoteData && <LoadingState />}
          {quoteData && (
            <>
              <RowBetween className="space-x-2">
                <Row className=" sm:space-x-4 w-full relative">
                  <UserNameLink className="h-10 flex-row-reverse justify-end w-fit" user={quoteData.author} />
                  <p className="font-thin text-right flex-grow leading-tight text-xs sm:text-sm">
                    Ajouté le {moment(quote.dateAdded.seconds * 1000).format("DD/MM/YY")}
                  </p>
                  <PopOverMenu
                    popoverButton={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                        />
                      </svg>
                    }
                    className="bg-background-dark shadow-glow rounded-2xl"
                    buttonClassName="p-2"
                  >
                    {({ close }) => (
                      <>
                        <PopOverMenuItem
                          onClick={() => {
                            saveToClipboard(window.location.origin + "/quote/" + quoteData.id);
                            close();
                          }}
                          className="pl-4"
                          disabled={true}
                          isFirst={true}
                        >
                          <ShareBadge />
                          <p>Partager</p>
                        </PopOverMenuItem>
                        {userDoc.id === auth.currentUser.uid && (
                          <>
                            <PopOverMenuItem
                              onClick={() => {
                                moveQuote(true);
                                close();
                              }}
                              className="pl-4"
                              disabled={index === 0}
                            >
                              <ChevronUpBadge />
                              <p>Ascendre</p>
                            </PopOverMenuItem>
                            <PopOverMenuItem
                              onClick={() => {
                                moveQuote(false);
                                close();
                              }}
                              className="pl-4"
                              disabled={index === userDoc.data().philosophies.length - 1}
                            >
                              <ChevronDownBadge />
                              <p>Descendre</p>
                            </PopOverMenuItem>
                            <PopOverMenuItem
                              onClick={async () => {
                                await removeQuoteFromMyPhilosophies(quoteData);
                                refresh();
                                close();
                              }}
                              className="text-red-700 hover:text-red-500 pl-4"
                              isLast={true}
                            >
                              <DeleteBadge />
                              <p>Supprimer</p>
                            </PopOverMenuItem>
                          </>
                        )}
                      </>
                    )}
                  </PopOverMenu>
                </Row>
              </RowBetween>
              <p className="quote-xl px-4 sm:px-14">{quoteData.quote}</p>
            </>
          )}
        </Column>
      </FilledCard>
    </div>
  );
}

function LoadingState() {
  return (
    <div className="space-y-2">
      <RowBetween className="space-x-2 w-full">
        <Row className="space-x-4 w-full relative">
          <div className="bg-gray-700 w-10 h-10 animate-pulse rounded-full" />
          <div className="bg-gray-700 w-32 h-8 animate-pulse rounded-full" />
        </Row>
        <div className="bg-gray-700 w-12 animate-pulse h-6 rounded-3xl" />
      </RowBetween>
      <div className="bg-gray-700 mx-14 animate-pulse h-8 rounded-3xl mt-4" />
      <div className="bg-gray-700 ml-14 mr-32 animate-pulse h-8 rounded-3xl mt-4" />
      <div className="bg-gray-700 ml-14 mr-20 animate-pulse h-8 rounded-3xl mt-4" />
    </div>
  );
}

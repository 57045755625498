import moment from "moment";
import { useEffect, useState } from "react";
import { createDocument, getDocumentReference, searchDocumentByValue } from "../../../helpers/FirestoreHelper";
import { addToastError } from "../../../base/Components/Toasts";
import Form from "../../../base/Forms/Form";
import FormInput from "../../../base/Forms/FormInput";
import FormTextarea from "../../../base/Forms/FormTextarea";
import { Column } from "../../../base/Layout/columns";
import GenericModal from "../../../base/Dialogs/GenericModal";
import { addCommentToDiscussion } from "../../../logic/DiscussionLogic";
import { getAllPhilosophers } from "../../../logic/PhilosophersLogic";
import SearchSelect from "../../../base/Forms/SearchSelect";

export default function AddComment({ open, setOpen }) {
  const [newComment, setNewComment] = useState({});
  const [philosophers, setPhilosophers] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!philosophers) {
      getAllPhilosophers().then(setPhilosophers);
    }
  }, [philosophers]);

  async function onAddComment() {
    setLoading(true);
    await addCommentToDiscussion(newComment.comment, open, newComment.user).catch((err) => addToastError(err));
    setLoading(false);
    setNewComment({});
    setOpen(null);
  }

  return (
    <GenericModal
      title="Ajouter un nouveau commentaire"
      open={open !== null}
      setOpen={setOpen}
      showFooter={false}
      loading={loading}
    >
      <Form className="w-3/5 p-10 flex flex-col space-y-5" onSubmit={onAddComment}>
        <Column>
          <p className="font-semibold">User :</p>
          {philosophers && (
            <SearchSelect
              items={philosophers.map((p) => {
                return { id: p.id, label: p.displayName };
              })}
              isMulti={false}
              setSelected={(e) => setNewComment({ ...newComment, user: e.id })}
            />
          )}
        </Column>
        <FormTextarea
          title="Commentaire"
          required
          minLength={10}
          value={newComment.comment}
          setValue={(e) => setNewComment({ ...newComment, comment: e.target.value })}
        />
        <FormInput value="Ajouter" type="submit" />
      </Form>
    </GenericModal>
  );
}

import {
  Timestamp,
  arrayRemove,
  arrayUnion,
  collection,
  getCountFromServer,
  increment,
  serverTimestamp,
} from "firebase/firestore";
import { auth, logedIn } from "../helpers/AuthHelper";
import {
  getCollectionReference,
  getDocument,
  getDocumentReference,
  getDocumentsWhere,
  getUserDocumentData,
  setUserDocumentData,
  UpdateDocument,
} from "../helpers/FirestoreHelper";
import { awaitForSeconds } from "../helpers/functions";
import { giveLightPoint, lightPointsAvailable } from "./UserLogic";
import { addToastWarning } from "../base/Components/Toasts";

export function getBook(id) {
  return new Promise((resolve, reject) => {
    getDocument("/books/" + id).then(async (res) => {
      let data = res.data();
      data.id = res.id;
      data.path = res.ref.path;
      await getDocument(data.author.path, data.author).then((res2) => {
        data.author = res2.data();
        data.author.id = res2.id;
        data.author.path = res2.ref.path;
      });
      let chapterCol = await getCollectionReference("/books/" + id + "/chapters/");
      data.chaptersCount = (await getCountFromServer(chapterCol)).data().count;

      resolve(data);
    });
  });
}

export function getBookChapter(id, chapter) {
  return new Promise((resolve, reject) => {
    getDocument("/books/" + id + "/chapters/" + chapter).then(async (res) => {
      let data = res.data();
      data.id = res.id;
      data.path = res.ref.path;
      resolve(data);
    });
  });
}

export function getBooksWhere(field, operator, value, limit = 10) {
  return new Promise((resolve, reject) => {
    getDocumentsWhere("/books", field, operator, value, limit).then(async (res) => {
      let docs = [];
      for (let index = 0; index < res.length; index++) {
        let doc = res[index];
        let data = doc.data();
        data.id = doc.id;
        data.path = doc.path;
        let res2 = await getDocument(data.author.path);
        data.author = res2.data();
        data.author.id = res2.id;
        data.author.path = res2.path;

        docs.push(data);
      }
      resolve(docs);
    });
  });
}

export function lightBook(b) {
  return new Promise(async (resolve, reject) => {
    if (!logedIn()) return reject();

    let book = { ...b };
    let uid = auth.currentUser.uid;
    let userRef = await getDocumentReference("/users/" + uid);
    let bookRef = await getDocumentReference("/books/" + book.id);
    let userDocData = await getUserDocumentData();

    if (!isBookLighted(book)) {
      if (!lightPointsAvailable()) return addToastWarning("Plus de lumières disponibles");
      UpdateDocument("/books/" + book.id, { lighted: arrayUnion(userRef) });
      UpdateDocument("/users/" + uid, { lightedBooks: arrayUnion(bookRef), lightPoints: increment(-1) });
      UpdateDocument(book.author.path, { lightPoints: increment(1) });
      book.lighted.push(userRef);
      if (userDocData.lightedBooks) userDocData.lightedBooks.push(bookRef);
      await giveLightPoint("light_badge_" + book.id);
    } else {
      UpdateDocument("/books/" + book.id, { lighted: arrayRemove(userRef) });
      UpdateDocument("/users/" + uid, { lightedBooks: arrayRemove(bookRef) });
      UpdateDocument(book.author.path, { lightPoints: increment(-1) });
      book.lighted = book.lighted.filter((e) => e.path !== "users/" + uid);
      userDocData.lightedBooks = userDocData.lightedBooks.filter((e) => e.path !== "books/" + uid);
    }

    setUserDocumentData(userDocData);
    resolve(book);
  });
}

export function onReadLaterBook(b) {
  return new Promise((resolve, reject) => {
    if (!logedIn()) reject();

    let book = { ...b };
    if (!auth.currentUser) return;
    let userData = getUserDocumentData();

    if (!isBookSavedForLater(book)) {
      let docRef = getDocumentReference("/books/" + book.id);
      UpdateDocument("/users/" + auth.currentUser.uid, {
        readLaterList: arrayUnion(docRef),
      });
      userData.readLaterList.push(docRef);
    } else {
      userData.readLaterList = userData.readLaterList.filter((e) => e.path !== "books/" + book.id);
      UpdateDocument("/users/" + auth.currentUser.uid, {
        readLaterList: userData.readLaterList,
      });
    }

    setUserDocumentData(userData);
    resolve(book);
  });
}

export function addBookToList(book, listName, data = {}) {
  return new Promise(async (resolve, reject) => {
    if (!book || !listName) return reject();
    let userData = await getUserDocumentData();
    let docRef = await getDocumentReference("/books/" + book.id);

    data.pages = 0;
    data.paragraph = 0;
    data.startedOn = serverTimestamp();
    data.book = docRef;

    let path = "/users/" + auth.currentUser.uid + "/" + listName + "/" + book.id;
    await UpdateDocument(path, data);

    let doc = await getDocument(path);

    resolve(doc);
  });
}

export function setBookPage(book, step) {
  return new Promise((resolve, reject) => {
    let userData = getUserDocumentData();
    if (!userData.bookPageCount) userData.bookPageCount = {};
    if (userData.bookPageCount[book.id] && userData.bookPageCount[book.id] === step) return reject();
    userData.bookPageCount[book.id] = step;
    UpdateDocument("/users/" + auth.currentUser.uid, { bookPageCount: userData.bookPageCount });
    setUserDocumentData(userData);
    resolve();
  });
}

export function getBookPage(bookId) {
  let userData = getUserDocumentData();
  return userData !== null && userData.bookPageCount && userData.bookPageCount[bookId]
    ? userData.bookPageCount[bookId]
    : 0;
}

export function removeBookFromList(book, listName) {
  return new Promise((resolve, reject) => {
    let modifUser = getUserDocumentData();
    modifUser[listName] = modifUser[listName].filter((e) => e.path !== "books/" + book.id);
    UpdateDocument("/users/" + auth.currentUser.uid, { [listName]: modifUser[listName] });
    setUserDocumentData(modifUser);
    resolve(modifUser[listName]);
  });
}

export function isBookLighted(book) {
  return (
    auth.currentUser !== null && book.lighted.find((e) => e.path === "users/" + auth.currentUser.uid) !== undefined
  );
}

export function isBookSavedForLater(book) {
  let userDocData = getUserDocumentData();
  return (
    auth.currentUser !== null &&
    userDocData &&
    userDocData.readLaterList &&
    userDocData.readLaterList.find((e) => e.path === "books/" + book.id) !== undefined
  );
}

export function isBookStarted(book) {
  let userDocData = getUserDocumentData();
  return userDocData.booksStarted && userDocData.booksStarted.find((e) => e.path === "books/" + book.id) !== undefined;
}

export function isBookFinished(book) {
  let userDocData = getUserDocumentData();
  return (
    userDocData.booksFinished && userDocData.booksFinished.find((e) => e.path === "books/" + book.id) !== undefined
  );
}

export async function writeParagraphs(paragraphs, subtitle = null) {
  return new Promise(async (resolve, reject) => {
    if (document.getElementById("reset")) document.getElementById("reset").remove();
    if (document.getElementById("pause")) document.getElementById("pause").remove();
    if (document.getElementById("next_paragraph")) document.getElementById("next_paragraph").remove();
    if (document.getElementById("prev_paragraph")) document.getElementById("prev_paragraph").remove();

    if (subtitle) {
      let text = subtitle.toString();
      if (document.getElementById("skip") == null) {
        let posY =
          document.getElementById("subtitle").getBoundingClientRect().top - document.body.getBoundingClientRect().top;
        window.scrollTo({ top: posY - 100, behavior: "smooth" });
      }

      while (
        document.getElementById("subtitle").innerText.length < subtitle.length &&
        !document.getElementById("reset")
      ) {
        if (!document.getElementById("pause")) {
          let char = text.slice(0, 1);
          if (char === " ") {
            char = text.slice(0, 2);
            text = text.slice(2);
            await awaitForSeconds(0.1);
          } else {
            text = text.slice(1);
          }
          document.getElementById("subtitle").innerText += char;
          await awaitForSeconds(char === "." || char === ":" ? 0.8 : char === "," ? 0.3 : char === "?" ? 0.35 : 0.03);
        } else {
          await awaitForSeconds(0.1);
        }
      }

      if (document.getElementById("reset")) {
        return;
      }
      await awaitForSeconds(1);
    }

    for (let index = 0; index < paragraphs.length; index++) {
      let text = paragraphs[index].toString();

      if (document.getElementById("skip") == null) {
        let posY =
          document.getElementById("paragraph_" + index).getBoundingClientRect().top -
          document.body.getBoundingClientRect().top;
        window.scrollTo({ top: posY - 100, behavior: "smooth" });
      }

      while (
        document.getElementById("paragraph_" + index).innerText.length < paragraphs[index].length &&
        !document.getElementById("reset")
      ) {
        if (document.getElementById("next_paragraph")) {
          document.getElementById("paragraph_" + index).innerText += text;
          document.getElementById("next_paragraph").remove();

          break;
        }
        if (document.getElementById("prev_paragraph")) {
          document.getElementById("paragraph_" + index).innerText = "";
          if (index - 1 >= 0) document.getElementById("paragraph_" + (index - 1)).innerText = "";
          index = index - 3 < -1 ? -1 : index - 3;
          document.getElementById("prev_paragraph").remove();

          break;
        }

        if (!document.getElementById("pause")) {
          let char = text.slice(0, 1);
          if (char === " ") {
            char = text.slice(0, 2);
            text = text.slice(2);
            await awaitForSeconds(0.1);
          } else {
            text = text.slice(1);
          }
          document.getElementById("paragraph_" + index).innerText += char;
          await awaitForSeconds(char === "." || char === ":" ? 0.8 : char === "," ? 0.3 : char === "?" ? 0.35 : 0.03);
        } else {
          await awaitForSeconds(0.1);
        }
      }
      if (document.getElementById("reset")) {
        return;
      }
    }

    await awaitForSeconds(0.5);
    resolve();
  });
}

export function getBookMinutesRead(chapters) {
  const wordsPerMinute = 200; // Average case.

  let text = "";
  for (let index = 0; index < chapters.length; index++) {
    const data = chapters[index].data();
    text += data.subtitle + " ";
    text += data.paragraphs.reduce((a, b) => a + " " + b);
  }

  let textLength = text.split(" ").length;

  return Math.ceil(textLength / wordsPerMinute);
}

export function getCharWaitTime(char) {
  let waitTime = 0.035;
  if (char === "." || char === ":") waitTime = 0.8;
  else if (char === "," || char === "?") waitTime = 0.3;
  else if (char === " ") waitTime = 0.12;

  return waitTime;
}

import { arrayUnion, Timestamp } from "firebase/firestore";
import React, { useState } from "react";
import { DeleteBadge } from "../../components/badges/badges";
import { Column } from "../../base/Layout/columns";
import { Row, RowBetween } from "../../base/Layout/rows";
import ProtectedRoute from "../../components/navigation/protectedRoute";
import { NavigateBackStructure } from "../../components/navigation/structure";
import { createDocument } from "../../helpers/FirestoreHelper";
import FilledButton from "../../base/Buttons/FilledButton";
import FilledIconButton from "../../base/Buttons/FilledIconButton";
import OutlinedButton from "../../base/Buttons/OutlinedButton";
import Spinner from "../../base/Components/Spinner";
import FormInput from "../../base/Forms/FormInput";

function getNewCurrent() {
  return {
    name: "",
    img: "",
    description: [""],
    books: [],
    quotes: [],
  };
}

export default function AddCurrent({}) {
  const [current, setCurrent] = useState(getNewCurrent());
  const [loading, setLoading] = useState(false);

  async function onAddCurrent() {
    setLoading(true);
    let postCurrent = { ...current };
    let res = await createDocument("/currents", postCurrent, false);
    setLoading(false);
    setCurrent(getNewCurrent());
  }
  return loading ? (
    <Spinner />
  ) : (
    <NavigateBackStructure>
      <ProtectedRoute role="admin">
        <Column className="max-w-4xl w-full h-screen overflow-y-scroll snap-y snap-mandatory space-y-10 mx-auto items-start">
          <div className="flex py-10 justify-between items-end w-full">
            <Column className="w-2/4">
              <h1>Current Name</h1>
              <FormInput className="w-full" onChange={(e) => setCurrent({ ...current, name: e.target.value })} />
            </Column>
            <FilledButton onClick={onAddCurrent}>
              <p>Add Current</p>
            </FilledButton>
          </div>
          <div className="flex-col space-y-10 items-start w-full">
            <Row className="w-3/4 space-x-4">
              <p>Image URL</p>
              <FormInput className="w-full" onChange={(e) => setCurrent({ ...current, img: e.target.value })} />
            </Row>
            <Row className="w-3/4 space-x-4">
              <p>Image Author</p>
              <FormInput onChange={(e) => setCurrent({ ...current, imgAuthor: e.target.value })} />
            </Row>
          </div>
          <Column className="w-full space-y-6">
            <h2>Description</h2>
            {current.description.map((p, k) => (
              <div key={"description" + k} className="max-w-4xl mx-auto flex-col relative items-start ">
                <textarea
                  className="px-6 py-2 w-full border bg-transparent border-text-color rounded-lg focus:ring-0"
                  value={p}
                  onChange={(e) => {
                    let editCurrent = { ...current };
                    editCurrent.description[k] = e.target.value;
                    setCurrent(editCurrent);
                  }}
                />
                <FilledIconButton
                  onClick={() => {
                    let editCurrent = { ...current };
                    editCurrent.description = editCurrent.description.filter((e, index) => k !== index);
                    setCurrent(editCurrent);
                  }}
                  className="absolute -bottom-5 px-3 right-3"
                >
                  <DeleteBadge />
                </FilledIconButton>
                <p className={p.length > 480 ? "text-red-500" : ""}>{p.length}/480 recommende</p>
              </div>
            ))}
            <div className="max-w-4xl pt-10 mx-auto">
              <OutlinedButton
                onClick={() => {
                  let editCurrent = { ...current };
                  editCurrent.description.push("");
                  setCurrent(editCurrent);
                }}
              >
                <p>+ Add Description Paragraph</p>
              </OutlinedButton>
            </div>
          </Column>
        </Column>
      </ProtectedRoute>
    </NavigateBackStructure>
  );
}

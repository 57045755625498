import { Row } from "../../base/Layout/rows";
import FilledIconButton from "../../base/Buttons/FilledIconButton";
import Spinner from "../../base/Components/Spinner";

export default function InputComment({
  className = "",
  disable = false,
  loading = false,
  onAddComment,
  id = "textInput",
}) {
  return (
    <Row
      className={
        "space-x-4 w-full max-w-3xl p-1 border rounded-3xl border-primary-color placeholder:text-primary-color " + className
      }
    >
      <textarea
        id={id}
        onChange={(e) => {
          e.target.style.height = e.target.scrollHeight + "px";
        }}
        placeholder="Écrire un pensée..."
        rows={1}
        className="pl-8 resize-none h-fit focus:outline-none py-2 max-h-36 w-full bg-transparent placeholder:text-opacity-60"
      />
      {!loading && (
        <FilledIconButton className="!rounded-full" disable={disable} onClick={() => onAddComment(document.getElementById(id).value)}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
            <path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z" />
          </svg>
        </FilledIconButton>
      )}
      {loading && <Spinner />}
    </Row>
  );
}

import { Helmet } from "react-helmet";
import { NavigateBackStructure } from "../../components/navigation/structure";

export default function PageNotAllowed({}) {
  return (
    <>
      <Helmet>
        <title>Page non autorisée</title>
        <meta name="description" content="Page non autorisée" />
      </Helmet>
      <p>Page non autorisée</p>
    </>
  );
}

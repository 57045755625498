import React, { useState } from "react";
import { addQuoteToMyPhilosophies, getQuoteTimeAgoPublished, isQuoteInMyPhilosophies } from "../../logic/QuotesLogic";
import { ColRow, Column } from "../../base/Layout/columns";
import { Row, RowEnd } from "../../base/Layout/rows";
import UserNameLink from "../navigation/userNameLink";
import FilledButton from "../../base/Buttons/FilledButton";
import Spinner from "../../base/Components/Spinner";
import OutlinedButton from "../../base/Buttons/OutlinedButton";

export default function QuoteBig({ quote }) {
  const [loading, setLoading] = useState(false);

  async function onAddQuote(e) {
    setLoading(true);
    await addQuoteToMyPhilosophies(quote).catch((err) => console.error(err));
    setLoading(false);
  }

  return (
    <div className="max-w-4xl pt-4 mx-auto w-full">
      <div className="p-4 sm:p-8 flex flex-col border rounded-2xl sm:rounded-3xl border-primary-color space-y-5 w-full">
        {!quote && <LoadingState />}
        {quote && (
          <>
            <p className="text-2xl sm:text-3xl lg:text-4xl overflow-hidden mb-5 sm:w-5/6">"{quote.quote}"</p>
            <ColRow className="items-center">
              <UserNameLink className="h-10 flex-row-reverse justify-end w-full" user={quote.author} small={false} />
              <RowEnd className="space-x-3">
                <p className="text-xs ml-auto whitespace-nowrap">Il y a {getQuoteTimeAgoPublished(quote)}</p>
                {loading && <Spinner className="w-5 h-5" />}
                {!loading && isQuoteInMyPhilosophies(quote) && (
                  <OutlinedButton disable={loading} onClick={onAddQuote} hasIcon>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15" />
                    </svg>
                    <p>mes philosophies</p>
                  </OutlinedButton>
                )}
                {!loading && !isQuoteInMyPhilosophies(quote) && (
                  <FilledButton disable={loading} onClick={onAddQuote} hasIcon>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>
                    <p>mes philosophies</p>
                  </FilledButton>
                )}
              </RowEnd>
            </ColRow>
          </>
        )}
      </div>
    </div>
  );
}

function LoadingState() {
  return (
    <>
      <Column className="overflow-hidden flex-grow group w-5/6">
        <div className="bg-gray-700 ml-2 mr-10 animate-pulse h-10 rounded-3xl mt-4" />
        <div className="bg-gray-700 ml-2 mr-20 animate-pulse h-10 rounded-3xl mt-4" />
        <div className="bg-gray-700 ml-2 mr-3 animate-pulse h-10 rounded-3xl mt-4" />
      </Column>
      <Row className="items-center pt-10">
        <Row className="space-x-4 w-full relative">
          <div className="bg-gray-700 w-32 h-8 animate-pulse rounded-full" />
          <div className="bg-gray-700 w-8 h-8 animate-pulse rounded-full ml-auto" />
        </Row>
        <RowEnd className="space-x-3">
          <div className="bg-gray-700 w-12 animate-pulse h-4 rounded-3xl" />
          <div className="bg-gray-700 w-36 h-10 animate-pulse rounded-full" />
        </RowEnd>
      </Row>
    </>
  );
}

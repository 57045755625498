import moment from "moment";
import Header from "./components/header";
import { RowEnd } from "../../base/Layout/rows";
import React, { useEffect, useState } from "react";
import { isPhilosopher } from "../../logic/UserLogic";
import { getTimeLeft } from "../../helpers/functions";
import DiscussionCard from "./components/discussionCard";
import { ColRow, Column } from "../../base/Layout/columns";
import CreateDiscussion from "./components/createDiscussion";
import { ControlledSelectable } from "../../base/Components/Selectable";
import FloatingActionButton from "../../base/Buttons/FloatingActionButton";
import { getFollowingDiscussions, getWeeklyDiscussions } from "../../logic/DiscussionLogic";
import { Helmet } from "react-helmet";

let lastDiscussions = null;
let followingDiscussion = null;

export default function Discussions() {
  const [newDiscussion, setNewDiscussion] = useState(false);
  const [filter, setFilter] = useState("Actuellement");
  const [discussions, setDiscussions] = useState(lastDiscussions);
  const [timeLeft, setTimeLeft] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    if (filter === "Actuellement") {
      if (!lastDiscussions)
        getWeeklyDiscussions().then((res) => {
          setDiscussions(res);
          lastDiscussions = res;
        });
      else setDiscussions(lastDiscussions);
    } else if (filter === "Favoris") {
      if (!followingDiscussion) {
        setDiscussions(null);
        getFollowingDiscussions()
          .then((res) => {
            setDiscussions(res);
            followingDiscussion = res;
          })
          .catch(() => setFilter("Actuellement"));
      } else setDiscussions(followingDiscussion);
    }
  }, [filter]);

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(getTimeLeft(moment().endOf("week")));
    }, 1000);
  }, [timeLeft]);

  return (
    <>
      <Helmet>
        <title>Philosophique - Contenu philosophique de qualité</title>
        <meta name="description" content="La plateforme pour porter la philosophie dans votre quotidien !" />
        <meta rel="canonical" content="https://philosophique.app" />
      </Helmet>
      <Header />
      <ColRow className="pt-10 space-y-2 lg:space-y-0 justify-between w-full">
        <h1 className="">Participez aux discussions</h1>
        <RowEnd className="overflow-x-scroll w-fit space-x-1">
          {["Actuellement", "Favoris"].map((e) => (
            <ControlledSelectable setValue={() => setFilter(e)} value={filter === e} key={"selectable_" + e}>
              {e}
            </ControlledSelectable>
          ))}
        </RowEnd>
      </ColRow>
      <Column className="py-5 w-full space-y-5">
        {!discussions && [0, 1, 2].map((d) => <DiscussionCard key={"discussion_" + d} discussion={null} />)}
        {discussions &&
          discussions.map((d) => (
            <DiscussionCard
              key={"discussion_" + d.id}
              discussion={d}
              setDiscussion={(e) => setDiscussions(discussions.map((d) => (d.id === e.id ? e : d)))}
            />
          ))}
        {discussions && discussions.length === 0 && (
          <p className="text-center italic">Il n'y a pas de discussions pour le moment</p>
        )}
        {isPhilosopher() && (
          <FloatingActionButton tooltip={"Créer discussion"} onClick={() => setNewDiscussion(true)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-8 w-8"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
          </FloatingActionButton>
        )}
      </Column>
      {isPhilosopher() && <CreateDiscussion open={newDiscussion} setOpen={setNewDiscussion} />}
    </>
  );
}

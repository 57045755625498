import React, { useState } from "react";
import {
  addQuoteToMyPhilosophies,
  getQuoteTimeAgoPublished,
  isQuoteInMyPhilosophies,
  removeQuoteFromMyPhilosophies,
} from "../../logic/QuotesLogic";
import Spinner from "../../base/Components/Spinner";
import { Column } from "../../base/Layout/columns";
import { Row } from "../../base/Layout/rows";
import UserNameLink from "../navigation/userNameLink";
import OutlinedButton from "../../base/Buttons/OutlinedButton";
import FilledCard from "../../base/Cards/FilledCard";
import FilledButton from "../../base/Buttons/FilledButton";

export default function QuoteCard({ quote, leftPos = null }) {
  const [loading, setLoading] = useState(false);

  async function onAddQuote(e) {
    setLoading(true);
    if (isQuoteInMyPhilosophies(quote)) {
      await removeQuoteFromMyPhilosophies(quote).catch((err) => console.error(err));
    } else {
      await addQuoteToMyPhilosophies(quote).catch((err) => console.error(err));
    }
    setLoading(false);
  }

  return (
    <FilledCard style={{ left: leftPos }} className="h-96 border-l-2 border-primary-dark p-4 sticky min-w-fit">
      <Column className="h-full flex-1 space-y-4">
        {!quote && <LoadingState />}
        {quote && (
          <>
            <Row>
              <UserNameLink className="h-10 flex-row-reverse justify-end w-full" user={quote.author} />
              <p className="text-xs ml-auto whitespace-nowrap">Il y a {getQuoteTimeAgoPublished(quote)}</p>
            </Row>
            <Column className="overflow-hidden flex-grow group w-72">
              <p className="text-2xl overflow-hidden">"{quote.quote}"</p>
            </Column>
            {loading && <Spinner className="w-5 h-5" />}
            {!loading && isQuoteInMyPhilosophies(quote) && (
              <OutlinedButton className="w-full justify-center" disable={loading} onClick={onAddQuote} hasIcon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15" />
                </svg>
                <p>mes philosophies</p>
              </OutlinedButton>
            )}
            {!loading && !isQuoteInMyPhilosophies(quote) && (
              <FilledButton className="w-full justify-center" disable={loading} onClick={onAddQuote} hasIcon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>
                <p>mes philosophies</p>
              </FilledButton>
            )}
          </>
        )}
      </Column>
    </FilledCard>
  );
}

function LoadingState() {
  return (
    <>
      <Row>
        <Row className="space-x-4 w-full relative">
          <div className="bg-gray-700 w-32 h-8 animate-pulse rounded-full" />
          <div className="bg-gray-700 w-8 h-8 animate-pulse rounded-full ml-auto" />
        </Row>
        <div className="bg-gray-700 w-12 animate-pulse h-4 rounded-3xl" />
      </Row>
      <Column className="overflow-hidden flex-grow group w-72">
        <div className="bg-gray-700 mx-2 animate-pulse h-6 rounded-3xl mt-4" />
        <div className="bg-gray-700 mx-2 animate-pulse h-6 rounded-3xl mt-4" />
        <div className="bg-gray-700 ml-2 mr-32 animate-pulse h-6 rounded-3xl mt-4" />
      </Column>
      <div className="bg-gray-700 mx-4 animate-pulse h-10 rounded-lg mt-5" />
    </>
  );
}

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { ColRow, Column } from "../../base/Layout/columns";
import { Row } from "../../base/Layout/rows";
import GenericModal from "../../base/Dialogs/GenericModal";
import GradientButton from "../../base/Buttons/GradientButton";

function resetInfo() {
  return { open: false };
}

export default function ModalEarlyRegister({}) {
  const navigate = useNavigate();
  const [info, setInfo] = useState(resetInfo());

  useEffect(() => {
    setInfoModal = setInfo;
  }, []);

  return (
    <GenericModal
      open={info.open}
      setOpen={(e) => setInfo({ ...info, open: false })}
      showHeader={false}
      showFooter={false}
    >
      <ColRow breakpoint="md" className="w-full justify-center items-center md:space-x-10 my-5 md:my-20 px-10">
        <div className="relative md:w-2/5 h-40 md:h-96">
          <img
            src="/images/buble02.png"
            className={"relative aspect-square rounded-full z-10 mx-auto border-4 border-text-light bg-text-light"}
          />
          <img
            src="/images/buble01.png"
            className={
              "absolute aspect-square rounded-full top-0 -left-6 w-36  border-4 border-text-light bg-text-light"
            }
          />
          <img
            src="/images/buble03.png"
            className={
              "absolute aspect-square rounded-full w-32 top-52 right-2 border-2 border-text-light bg-text-light"
            }
          />
          <img
            src="/images/buble04.png"
            className={
              "absolute aspect-square rounded-full w-24 top-0 -right-4 border-2 border-text-light bg-text-light"
            }
          />
        </div>
        <Column className="md:space-y-5 z-10 pt-16 sm:pb-5 items-center bg-gradient-to-t from-background-color via-background-color to-transparent text-center md:w-3/5">
          <h4 className="pb-10 text-3xl sm:text-5xl bg-gradient-to-tl from-violet-600 via-indigo-500 to-indigo-800 bg-clip-text text-transparent">
            Testez notre service avec un compte <span className="italic">Philosophique</span> gratuit
          </h4>
          <GradientButton
            onClick={() => {
              navigate("/earlyRegister");
              setInfo({ ...info, open: false });
            }}
          >
            Inscription anticipée
          </GradientButton>
        </Column>
      </ColRow>
    </GenericModal>
  );
}

var setInfoModal;

export function showModalEarlRegister() {
  setInfoModal({ open: true });
}

export function QuoteBadge({ solid = true, className = "w-6 h-6" }) {
  return solid ? (
    <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.875 19C7.15317 19 9 17.1532 9 14.875C9 12.5968 7.15317 10.75 4.875 10.75C2.59683 10.75 0.75 12.5968 0.75 14.875C0.75 17.1532 2.59683 19 4.875 19Z"
        fill="#D8C9B8"
      />
      <path
        d="M4.875 19.75C2.187 19.75 0 17.563 0 14.875C0 12.187 2.187 10 4.875 10C7.563 10 9.75 12.187 9.75 14.875C9.75 17.563 7.563 19.75 4.875 19.75ZM4.875 11.5C3.01425 11.5 1.5 13.0143 1.5 14.875C1.5 16.7357 3.01425 18.25 4.875 18.25C6.73575 18.25 8.25 16.7357 8.25 14.875C8.25 13.0143 6.73575 11.5 4.875 11.5Z"
        fill="#D8C9B8"
      />
      <path
        d="M17.625 19C19.9032 19 21.75 17.1532 21.75 14.875C21.75 12.5968 19.9032 10.75 17.625 10.75C15.3468 10.75 13.5 12.5968 13.5 14.875C13.5 17.1532 15.3468 19 17.625 19Z"
        fill="#D8C9B8"
      />
      <path
        d="M17.625 19.75C14.937 19.75 12.75 17.563 12.75 14.875C12.75 12.187 14.937 10 17.625 10C20.313 10 22.5 12.187 22.5 14.875C22.5 17.563 20.313 19.75 17.625 19.75ZM17.625 11.5C15.7643 11.5 14.25 13.0143 14.25 14.875C14.25 16.7357 15.7643 18.25 17.625 18.25C19.4857 18.25 21 16.7357 21 14.875C21 13.0143 19.4857 11.5 17.625 11.5Z"
        fill="#D8C9B8"
      />
      <path
        d="M0.75 15.25C0.33525 15.25 0 14.9147 0 14.5C0 8.71 4.71 4 10.5 4C10.9147 4 11.25 4.33525 11.25 4.75C11.25 5.16475 10.9147 5.5 10.5 5.5C5.53725 5.5 1.5 9.53725 1.5 14.5C1.5 14.9147 1.16475 15.25 0.75 15.25Z"
        fill="#D8C9B8"
      />
      <path
        d="M13.5 15.25C13.0853 15.25 12.75 14.9147 12.75 14.5C12.75 8.71 17.46 4 23.25 4C23.6647 4 24 4.33525 24 4.75C24 5.16475 23.6647 5.5 23.25 5.5C18.2872 5.5 14.25 9.53725 14.25 14.5C14.25 14.9147 13.9147 15.25 13.5 15.25Z"
        fill="#D8C9B8"
      />
    </svg>
  ) : (
    <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.875 19.75C2.187 19.75 0 17.563 0 14.875C0 12.187 2.187 10 4.875 10C7.563 10 9.75 12.187 9.75 14.875C9.75 17.563 7.563 19.75 4.875 19.75ZM4.875 11.5C3.01425 11.5 1.5 13.0143 1.5 14.875C1.5 16.7357 3.01425 18.25 4.875 18.25C6.73575 18.25 8.25 16.7357 8.25 14.875C8.25 13.0143 6.73575 11.5 4.875 11.5Z"
        fill="#D8C9B8"
      />
      <path
        d="M17.625 19.75C14.937 19.75 12.75 17.563 12.75 14.875C12.75 12.187 14.937 10 17.625 10C20.313 10 22.5 12.187 22.5 14.875C22.5 17.563 20.313 19.75 17.625 19.75ZM17.625 11.5C15.7643 11.5 14.25 13.0143 14.25 14.875C14.25 16.7357 15.7643 18.25 17.625 18.25C19.4857 18.25 21 16.7357 21 14.875C21 13.0143 19.4857 11.5 17.625 11.5Z"
        fill="#D8C9B8"
      />
      <path
        d="M0.75 15.25C0.33525 15.25 0 14.9147 0 14.5C0 8.71 4.71 4 10.5 4C10.9147 4 11.25 4.33525 11.25 4.75C11.25 5.16475 10.9147 5.5 10.5 5.5C5.53725 5.5 1.5 9.53725 1.5 14.5C1.5 14.9147 1.16475 15.25 0.75 15.25Z"
        fill="#D8C9B8"
      />
      <path
        d="M13.5 15.25C13.0853 15.25 12.75 14.9147 12.75 14.5C12.75 8.71 17.46 4 23.25 4C23.6647 4 24 4.33525 24 4.75C24 5.16475 23.6647 5.5 23.25 5.5C18.2872 5.5 14.25 9.53725 14.25 14.5C14.25 14.9147 13.9147 15.25 13.5 15.25Z"
        fill="#D8C9B8"
      />
    </svg>
  );
}

export function BookBadge({ solid = true, className = "w-6 h-6" }) {
  return solid ? (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={className}>
      <path d="M11.25 4.533A9.707 9.707 0 006 3a9.735 9.735 0 00-3.25.555.75.75 0 00-.5.707v14.25a.75.75 0 001 .707A8.237 8.237 0 016 18.75c1.995 0 3.823.707 5.25 1.886V4.533zM12.75 20.636A8.214 8.214 0 0118 18.75c.966 0 1.89.166 2.75.47a.75.75 0 001-.708V4.262a.75.75 0 00-.5-.707A9.735 9.735 0 0018 3a9.707 9.707 0 00-5.25 1.533v16.103z" />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={className}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25"
      />
    </svg>
  );
}

export function DiscussBadge({ solid = true, className = "w-6 h-6" }) {
  return solid ? (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={className}>
      <path d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 00-1.032-.211 50.89 50.89 0 00-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 002.433 3.984L7.28 21.53A.75.75 0 016 21v-4.03a48.527 48.527 0 01-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979z" />
      <path d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 001.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0015.75 7.5z" />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={className}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
      />
    </svg>
  );
}

export function LigthBadge({ id = "", solid = true, className = "w-6 h-6", highlightOnHover = false }) {
  return solid ? (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      fill={"currentColor"}
      viewBox="0 0 24 24"
      className={className + " transition-all duration-1000 ease-in-out"}
    >
      <path
        d="M12.4999 0.75C10.6844 0.749592 8.91954 1.34803 7.47874 2.45256C6.03793 3.55709 5.00169 5.10602 4.53062 6.8593C4.05955 8.61258 4.17995 10.4723 4.87317 12.1502C5.56639 13.8281 6.7937 15.2305 8.3649 16.14C9.0509 16.538 9.4799 17.148 9.4989 17.763C9.50415 17.9277 9.56351 18.0861 9.6678 18.2138C9.77209 18.3414 9.91552 18.4311 10.0759 18.469C10.4279 18.552 10.7859 18.617 11.1499 18.664C11.4729 18.705 11.7499 18.446 11.7499 18.12V13.459C11.4339 13.4243 11.1208 13.3671 10.8129 13.288C10.7175 13.2634 10.6279 13.2203 10.5491 13.1611C10.4704 13.1019 10.4041 13.0278 10.354 12.943C10.3039 12.8582 10.271 12.7643 10.2571 12.6668C10.2433 12.5692 10.2488 12.4699 10.2734 12.3745C10.298 12.2791 10.3411 12.1895 10.4003 12.1107C10.4595 12.032 10.5336 11.9657 10.6184 11.9156C10.7032 11.8655 10.7971 11.8326 10.8946 11.8187C10.9922 11.8049 11.0915 11.8104 11.1869 11.835C12.0482 12.057 12.9516 12.057 13.8129 11.835C13.9093 11.8072 14.0102 11.7991 14.1098 11.811C14.2094 11.8229 14.3055 11.8547 14.3926 11.9045C14.4797 11.9542 14.5559 12.0209 14.6168 12.1007C14.6776 12.1804 14.7218 12.2715 14.7469 12.3686C14.7719 12.4657 14.7772 12.5669 14.7624 12.6661C14.7476 12.7653 14.7131 12.8605 14.6609 12.9461C14.6087 13.0317 14.5399 13.106 14.4584 13.1646C14.377 13.2231 14.2847 13.2648 14.1869 13.287C13.879 13.3665 13.5659 13.4239 13.2499 13.459V18.119C13.2499 18.446 13.5269 18.705 13.8499 18.664C14.2139 18.617 14.5719 18.552 14.9239 18.469C15.0843 18.4311 15.2277 18.3414 15.332 18.2138C15.4363 18.0861 15.4957 17.9277 15.5009 17.763C15.5209 17.148 15.9489 16.538 16.6349 16.14C18.2061 15.2305 19.4334 13.8281 20.1266 12.1502C20.8199 10.4723 20.9403 8.61258 20.4692 6.8593C19.9981 5.10602 18.9619 3.55709 17.5211 2.45256C16.0803 1.34803 14.3154 0.749592 12.4999 0.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.51303 19.9C9.53139 19.8032 9.56865 19.711 9.62267 19.6286C9.67668 19.5462 9.7464 19.4752 9.82785 19.4198C9.90929 19.3644 10.0009 19.3255 10.0973 19.3055C10.1938 19.2854 10.2932 19.2846 10.39 19.303C11.7843 19.5675 13.2158 19.5675 14.61 19.303C14.708 19.2814 14.8093 19.2797 14.9079 19.298C15.0066 19.3163 15.1005 19.3542 15.1843 19.4094C15.268 19.4646 15.3398 19.5361 15.3954 19.6196C15.451 19.7031 15.4894 19.7969 15.5081 19.8954C15.5268 19.994 15.5256 20.0953 15.5045 20.1933C15.4834 20.2914 15.4428 20.3842 15.3852 20.4663C15.3275 20.5484 15.254 20.6181 15.169 20.6713C15.0839 20.7245 14.9891 20.7601 14.89 20.776C13.3108 21.0757 11.6893 21.0757 10.11 20.776C9.91475 20.7389 9.74218 20.6258 9.63024 20.4615C9.5183 20.2973 9.47614 20.0953 9.51303 19.9ZM10.254 22.344C10.2643 22.246 10.2937 22.151 10.3406 22.0644C10.3876 21.9779 10.4511 21.9014 10.5277 21.8393C10.6042 21.7773 10.6922 21.7309 10.7866 21.7029C10.881 21.6749 10.9801 21.6657 11.078 21.676C12.0235 21.7748 12.9766 21.7748 13.922 21.676C14.1199 21.6553 14.3178 21.7141 14.4724 21.8393C14.6269 21.9646 14.7253 22.1461 14.746 22.344C14.7667 22.5419 14.708 22.7398 14.5827 22.8943C14.4574 23.0489 14.2759 23.1473 14.078 23.168C13.0289 23.2778 11.9712 23.2778 10.922 23.168C10.8241 23.1578 10.7291 23.1283 10.6425 23.0814C10.5559 23.0344 10.4794 22.9709 10.4174 22.8944C10.3553 22.8178 10.309 22.7299 10.2809 22.6354C10.2529 22.541 10.2438 22.442 10.254 22.344Z"
        fill="currentColor"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={"none"}
      id={id}
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={className + " " + (highlightOnHover ? "hover:fill-gray-700" : "")}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18"
      />
    </svg>
  );
}

export function InboxBadge({ solid = true, className = "w-6 h-6" }) {
  return solid ? (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={className}>
      <path
        fillRule="evenodd"
        d="M6.912 3a3 3 0 00-2.868 2.118l-2.411 7.838a3 3 0 00-.133.882V18a3 3 0 003 3h15a3 3 0 003-3v-4.162c0-.299-.045-.596-.133-.882l-2.412-7.838A3 3 0 0017.088 3H6.912zm13.823 9.75l-2.213-7.191A1.5 1.5 0 0017.088 4.5H6.912a1.5 1.5 0 00-1.434 1.059L3.265 12.75H6.11a3 3 0 012.684 1.658l.256.513a1.5 1.5 0 001.342.829h3.218a1.5 1.5 0 001.342-.83l.256-.512a3 3 0 012.684-1.658h2.844z"
        clipRule="evenodd"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={className}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H6.911a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661z"
      />
    </svg>
  );
}

export function ReadLaterBadge({ solid = true, className = "w-6 h-6" }) {
  return solid ? (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={className}>
      <path
        fillRule="evenodd"
        d="M6 3a3 3 0 00-3 3v12a3 3 0 003 3h12a3 3 0 003-3V6a3 3 0 00-3-3H6zm1.5 1.5a.75.75 0 00-.75.75V16.5a.75.75 0 001.085.67L12 15.089l4.165 2.083a.75.75 0 001.085-.671V5.25a.75.75 0 00-.75-.75h-9z"
        clipRule="evenodd"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={className}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.5 3.75V16.5L12 14.25 7.5 16.5V3.75m9 0H18A2.25 2.25 0 0120.25 6v12A2.25 2.25 0 0118 20.25H6A2.25 2.25 0 013.75 18V6A2.25 2.25 0 016 3.75h1.5m9 0h-9"
      />
    </svg>
  );
}

export function DeleteBadge({ solid = true, className = "w-6 h-6" }) {
  return solid ? (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={className}>
      <path
        fillRule="evenodd"
        d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
        clipRule="evenodd"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={className}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
      />
    </svg>
  );
}

export function ChevronUpBadge({ className = "w-6 h-6" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={className}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
    </svg>
  );
}

export function ChevronDownBadge({ className = "w-6 h-6" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={className}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
    </svg>
  );
}

export function ChevronRightBadge({ className = "w-6 h-6" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={className}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
    </svg>
  );
}

export function ChevronLeftBadge({ className = "w-6 h-6" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={className}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
    </svg>
  );
}

export function PlusBadge({ className = "w-6 h-6" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={className}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
    </svg>
  );
}

export function ShareBadge({ solid = true, className = "w-6 h-6" }) {
  return solid ? (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={className}>
      <path
        fillRule="evenodd"
        d="M15.75 4.5a3 3 0 11.825 2.066l-8.421 4.679a3.002 3.002 0 010 1.51l8.421 4.679a3 3 0 11-.729 1.31l-8.421-4.678a3 3 0 110-4.132l8.421-4.679a3 3 0 01-.096-.755z"
        clipRule="evenodd"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={className}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z"
      />
    </svg>
  );
}

export function HideBadge({ solid = true, className = "w-6 h-6" }) {
  return solid ? (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={className}>
      <path d="M3.53 2.47a.75.75 0 00-1.06 1.06l18 18a.75.75 0 101.06-1.06l-18-18zM22.676 12.553a11.249 11.249 0 01-2.631 4.31l-3.099-3.099a5.25 5.25 0 00-6.71-6.71L7.759 4.577a11.217 11.217 0 014.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113z" />
      <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0115.75 12zM12.53 15.713l-4.243-4.244a3.75 3.75 0 004.243 4.243z" />
      <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 00-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 016.75 12z" />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={className}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
      />
    </svg>
  );
}

export function CloseBadge({ className = "w-6 h-6" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={className}>
      <path
        fillRule="evenodd"
        d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export function RoundedCheckBadge({ className = "w-6 h-6", solid = true }) {
  return solid ? (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={className}>
      <path
        fillRule="evenodd"
        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
        clipRule="evenodd"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={className}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
}

export function VerifiedBadge({ solid = true, className = "w-6 h-6" }) {
  return solid ? (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={className}>
      <path
        fillRule="evenodd"
        d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
        clipRule="evenodd"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={className}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
      />
    </svg>
  );
}

export function SettingsBadge({ solid = true, className = "w-6 h-6" }) {
  return solid ? (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={className}>
      <path
        fillRule="evenodd"
        d="M11.078 2.25c-.917 0-1.699.663-1.85 1.567L9.05 4.889c-.02.12-.115.26-.297.348a7.493 7.493 0 00-.986.57c-.166.115-.334.126-.45.083L6.3 5.508a1.875 1.875 0 00-2.282.819l-.922 1.597a1.875 1.875 0 00.432 2.385l.84.692c.095.078.17.229.154.43a7.598 7.598 0 000 1.139c.015.2-.059.352-.153.43l-.841.692a1.875 1.875 0 00-.432 2.385l.922 1.597a1.875 1.875 0 002.282.818l1.019-.382c.115-.043.283-.031.45.082.312.214.641.405.985.57.182.088.277.228.297.35l.178 1.071c.151.904.933 1.567 1.85 1.567h1.844c.916 0 1.699-.663 1.85-1.567l.178-1.072c.02-.12.114-.26.297-.349.344-.165.673-.356.985-.57.167-.114.335-.125.45-.082l1.02.382a1.875 1.875 0 002.28-.819l.923-1.597a1.875 1.875 0 00-.432-2.385l-.84-.692c-.095-.078-.17-.229-.154-.43a7.614 7.614 0 000-1.139c-.016-.2.059-.352.153-.43l.84-.692c.708-.582.891-1.59.433-2.385l-.922-1.597a1.875 1.875 0 00-2.282-.818l-1.02.382c-.114.043-.282.031-.449-.083a7.49 7.49 0 00-.985-.57c-.183-.087-.277-.227-.297-.348l-.179-1.072a1.875 1.875 0 00-1.85-1.567h-1.843zM12 15.75a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z"
        clipRule="evenodd"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={className}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
      />
      <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
    </svg>
  );
}

import { useNavigate } from "react-router";
import DiscussionComments from "./discussionComments";
import { Row, RowCol } from "../../../base/Layout/rows";
import Selectable from "../../../base/Components/Selectable";
import { Column, ColRow } from "../../../base/Layout/columns";
import { LigthBadge } from "../../../components/badges/badges";
import { isFollowingDiscussion, onFollowDiscussion } from "../../../logic/DiscussionLogic";

export default function DiscussionCard({ discussion, setDiscussion }) {
  const navigate = useNavigate();

  return discussion === null ? (
    <DiscussionCardLoadingState />
  ) : (
    <article className="card bg-background-dark w-full !p-0 flex flex-col lg:flex-row lg:h-96 rounded-3xl">
      <Row
        className="justify-between lg:flex-col lg:items-start p-5 h-52 lg:h-full lg:w-2/4 bg-cover bg-center rounded-3xl group cursor-pointer"
        onClick={() => navigate("/discussion/" + discussion.id)}
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0,1),rgba(0, 0, 0, 0)),url(` + discussion.img + `)`,
        }}
      >
        <h4 className="text-3xl sm:text-5xl lg:text-6xl flex-grow font-semibold group-hover:underline text-ellipsis z-10">
          {discussion.title}
        </h4>
        <ColRow className="justify-between items-end z-10">
          <Selectable
            className="backdrop-blur"
            onChange={(e) => onFollowDiscussion(discussion).then((res) => setDiscussion(res))}
            hasIcon={!isFollowingDiscussion(discussion)}
            defaultSelected={isFollowingDiscussion(discussion)}
          >
            {!isFollowingDiscussion(discussion) && (
              <LigthBadge id={"light_badge_" + discussion.id} solid={false} className="w-5 h-5" />
            )}
            <p className="font-semibold">favoris</p>
          </Selectable>
        </ColRow>
      </Row>
      <Column className="items-end relative lg:w-2/4 px-2 pb-2 h-80 lg:h-full justify-between">
        <DiscussionComments discussionId={discussion.id} />
      </Column>
    </article>
  );
}

function DiscussionCardLoadingState() {
  return (
    <div className="pt-5 w-full">
      <ColRow className="card rounded-3xl bg-background-dark p-0 h-96 w-full">
        <RowCol className="rounded-3xl relative justify-between animate-pulse p-5 h-52 lg:h-full lg:w-2/4 bg-gradient-to-tl from-violet-700 via-indigo-600 to-indigo-900">
          <Column className="mr-4">
            <div className="bg-gray-800 opacity-75 w-full h-16 rounded-3xl" />
            <div className="bg-gray-800 opacity-75 w-3/5 h-16 rounded-3xl mt-3" />
            <div className="bg-gray-800 opacity-75 w-5/6 h-16 rounded-3xl mt-3" />
          </Column>
          <div className="bg-gray-800 opacity-75 w-32 h-10 rounded-full" />
        </RowCol>
        <Column className="items-end lg:w-2/4 px-2 pb-2 h-80 lg:h-full justify-between">
          <Column className="snap-y snap-mandatory h-full space-y-2 w-full lg:pb-10 overflow-auto">
            <div className="bg-gray-700 opacity-75 w-full animate-pulse h-16 lg:h-48 rounded-3xl mt-4" />
          </Column>
          <div className="bg-gray-700 opacity-75 w-full animate-pulse h-16 mb-1 rounded-full px-4" />
        </Column>
      </ColRow>
    </div>
  );
}

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  isBookLighted,
  isBookSavedForLater,
  lightBook,
  onReadLaterBook,
  getBookMinutesRead,
} from "../../../logic/BooksLogic";
import { logedIn } from "../../../helpers/AuthHelper";
import { saveToClipboard } from "../../../helpers/functions";
import { HideBadge, LigthBadge, ReadLaterBadge, ShareBadge } from "../../../components/badges/badges";
import { Row, RowBetween } from "../../../base/Layout/rows";
import UserNameLink from "../../../components/navigation/userNameLink";
import { Column } from "../../../base/Layout/columns";
import PopOverMenu, { PopOverMenuItem } from "../../../base/Popovers/Popover";

export default function BookCard({ b, onHideBook = null, style = {} }) {
  const navigate = useNavigate();
  const [book, setBook] = useState(b);

  return (
    <div
      style={{ ...style }}
      onClick={() => {
        if (logedIn()) navigate("/book/" + book.id);
      }}
      className="bg-dark-background-color min-w-fit sticky rounded-3xl group cursor-pointer overflow-clip shadow-lg flex flex-col sm:flex-row select-none"
    >
      {!book && <BookCardLoading />}
      {book && (
        <>
          <img src={book.img} className="h-48 md:h-full md:w-52 bg-gray-700 object-cover aspect-square" />
          <Column className="gap-1 p-4 h-full lg:pt-2">
            <RowBetween className="flex-1 items-start">
              <a className="font-bold text-2xl pt-5 text-ellipsis overflow-y-hidden leading-none group-hover:underline">
                {book.title}
              </a>
              <PopOverMenu
                className="bg-background-dark shadow-glow rounded-2xl z-20 right-0"
                popoverButton={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                    />
                  </svg>
                }
                buttonClassName="p-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
              >
                {({ close }) => (
                  <>
                    <PopOverMenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        if (logedIn()) {
                          saveToClipboard(
                            window.location.origin + "/book/" + book.id,
                            true,
                            "Lien du livre copié dans le presse-papier !"
                          );
                          close();
                        }
                      }}
                      isFirst={true}
                    >
                      <ShareBadge />
                      <p>Partager</p>
                    </PopOverMenuItem>
                    {onHideBook !== null && (
                      <PopOverMenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          onHideBook();
                          close();
                        }}
                      >
                        <HideBadge solid={false} />
                        <p>Masquer</p>
                      </PopOverMenuItem>
                    )}
                    <PopOverMenuItem
                      isLast={true}
                      className={isBookSavedForLater(book) ? "bg-text-color text-background-dark" : ""}
                      onClick={(e) => {
                        e.stopPropagation();
                        onReadLaterBook(book).then(setBook);
                        close();
                      }}
                    >
                      <ReadLaterBadge solid={isBookSavedForLater(book)} />
                      <p>{isBookSavedForLater(book) ? "Sauvegardé" : "Lire plus tard"}</p>
                    </PopOverMenuItem>
                  </>
                )}
              </PopOverMenu>
            </RowBetween>
            <Row className="mt-4">
              {book.author && <UserNameLink className="h-10 flex-row-reverse justify-end w-fit" user={book.author} />}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  lightBook(book).then(setBook);
                }}
                className="p-1 focus:outline-none ml-auto"
              >
                <LigthBadge id={"light_badge_" + book.id} highlightOnHover={true} solid={isBookLighted(book)} />
              </button>
            </Row>
          </Column>
        </>
      )}
    </div>
  );
}

function BookCardLoading() {
  return (
    <>
      <div className="h-full sm:w-52 rounded-3xl bg-gray-700 animate-pulse" />
      <div className="flex p-4 flex-col flex-1 gap-5 sm:p-2 w-full">
        <div className="flex flex-1 flex-col ">
          <div className="bg-gray-700 w-full animate-pulse h-10 rounded-3xl" />
          <div className="bg-gray-700 w-full animate-pulse h-20 rounded-3xl mt-2" />
        </div>
        <div className="mt-auto flex gap-3">
          <div className="bg-gray-700 w-32 h-10 animate-pulse rounded-full" />
          <div className="bg-gray-700 w-10 h-10 animate-pulse rounded-full ml-auto" />
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function ReadMenu({ presenting, onReplay, page, pages, canSkip = true, ...props }) {
  const [pause, setPause] = useState(false);

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown, false);
    return () => {
      document.removeEventListener("keydown", onKeyDown, false);
    };
  }, [presenting]);

  function onKeyDown(e) {
    if (presenting && e.code === "Space") {
      e.preventDefault();
      onPause();
    } else if (presenting && e.code === "ArrowDown") {
      e.preventDefault();
      displayNextParagraph();
    } else if (presenting && e.code === "ArrowUp") {
      e.preventDefault();
      displayPrevParagraph();
    }
  }

  function onPause() {
    if (document.getElementById("pause")) {
      document.getElementById("pause").remove();
    } else {
      let p = document.createElement("a");
      p.className = "hidden";
      p.id = "pause";
      document.body.appendChild(p);
    }
    setPause(document.getElementById("pause") != null);
  }

  function displayNextParagraph() {
    let p = document.createElement("a");
    p.className = "hidden";
    p.id = "next_paragraph";
    document.body.appendChild(p);
  }
  function displayPrevParagraph() {
    let p = document.createElement("a");
    p.className = "hidden";
    p.id = "prev_paragraph";
    document.body.appendChild(p);
  }

  function getNextPage() {
    if (page == pages) return window.location.pathname;
    return (
      window.location.pathname.substring(0, window.location.pathname.length - 1) +
      (parseInt(window.location.pathname[window.location.pathname.length - 1]) + 1)
    );
  }

  function getPrevPage() {
    if (page == 1) return window.location.pathname;
    return (
      window.location.pathname.substring(0, window.location.pathname.length - 1) +
      (parseInt(window.location.pathname[window.location.pathname.length - 1]) - 1)
    );
  }

  return (
    <div className="flex rounded-full items-center shadow-glow-sm bg-dark-background-color border border-primary-color space-x-2 px-4 py-1 bottom-4 right-2 md:right-8 lg:right-20 fixed z-30">
      {presenting && (
        <div
          onClick={onPause}
          className="rounded-full cursor-pointer flex items-center hover:bg-primary-color hover:bg-opacity-20 w-12 h-12 justify-center"
        >
          {pause && (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
              <path
                fillRule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm14.024-.983a1.125 1.125 0 010 1.966l-5.603 3.113A1.125 1.125 0 019 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113z"
                clipRule="evenodd"
              />
            </svg>
          )}
          {!pause && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.25 9v6m-4.5 0V9M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          )}
        </div>
      )}
      {page && pages && (
        <>
          <Link
            to={getPrevPage()}
            className={
              "rounded-full cursor-pointer hidden sm:flex items-center hover:bg-primary-color hover:bg-opacity-20 w-12 h-12 justify-center" +
              (page == 1 ? " opacity-50 pointer-events-none" : "")
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
            </svg>
          </Link>
          {page && pages && (
            <p className="whitespace-nowrap h-min">
              {page}/{pages}
            </p>
          )}
          <Link
            to={getNextPage()}
            className={
              "rounded-full cursor-pointer hidden sm:flex items-center hover:bg-primary-color hover:bg-opacity-20 w-12 h-12 justify-center" +
              (page == pages ? " opacity-50 pointer-events-none" : "")
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 15l6-6m0 0l-6-6m6 6H9a6 6 0 000 12h3" />
            </svg>
          </Link>
        </>
      )}
      {presenting && (
        <div className="flex sm:flex-col items-center space-x-2 sm:space-x-0 lg:space-y-0 sm:space-y-2">
          <svg
            onClick={() => displayPrevParagraph()}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-12 p-2 sm:p-0 h-12 sm:w-5 sm:h-5 hover:bg-primary-color hover:bg-opacity-20 rounded-full"
          >
            <path
              fillRule="evenodd"
              d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z"
              clipRule="evenodd"
            />
          </svg>
          <svg
            onClick={() => displayNextParagraph()}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-12 p-2 sm:p-0 h-12 sm:w-5 sm:h-5 hover:bg-primary-color hover:bg-opacity-20 rounded-full"
          >
            <path
              fillRule="evenodd"
              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      )}
      {canSkip && (
        <div
          onClick={() => {
            if (presenting) {
              let p = document.createElement("a");
              p.className = "hidden";
              p.id = "skip";
              document.body.appendChild(p);
            } else if (onReplay) onReplay();
          }}
          className="rounded-full cursor-pointer hidden sm:flex items-center hover:bg-primary-color hover:bg-opacity-20 w-12 h-12 justify-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            {presenting && (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0l-3.75-3.75M17.25 21L21 17.25"
              />
            )}
            {!presenting && (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
              />
            )}
          </svg>
        </div>
      )}
      {props.children}
    </div>
  );
}

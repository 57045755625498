import { arrayUnion, Timestamp } from "firebase/firestore";
import React, { useState } from "react";
import { Column } from "../../base/Layout/columns";
import { Row } from "../../base/Layout/rows";
import ProtectedRoute from "../../components/navigation/protectedRoute";
import { NavigateBackStructure } from "../../components/navigation/structure";
import { createDocument, getDocumentReference, UpdateDocument } from "../../helpers/FirestoreHelper";
import FilledButton from "../../base/Buttons/FilledButton";
import Spinner from "../../base/Components/Spinner";
import FormInput from "../../base/Forms/FormInput";

function getNewQuote() {
  return {
    date: Timestamp.fromDate(new Date()),
    authorRef: "",
    quote: "",
    lighted: [],
  };
}

export default function AddQuote({}) {
  const [quote, setQuote] = useState(getNewQuote());
  const [loading, setLoading] = useState(false);

  async function onAddQuote() {
    if (quote.quote.length > 155) return alert("Max characters 155");
    setLoading(true);
    let postQuote = { ...quote };
    postQuote.authorRef = getDocumentReference("/users/" + postQuote.authorRef);
    delete postQuote.current;
    postQuote.bookRef = getDocumentReference("/books/" + quote.book);
    delete postQuote.book;
    let res = await createDocument("/quotes", postQuote, false);
    UpdateDocument(postQuote.authorRef.path, { quotes: arrayUnion(getDocumentReference("/quotes/" + res.id)) });
    if (quote.current && quote.current !== "") {
      let modifCurrent = await UpdateDocument("/currents/" + quote.current, {
        quotes: arrayUnion(getDocumentReference("/quotes/" + res.id)),
      });
    }
    if (quote.book && quote.book !== "") {
      let modifBook = await UpdateDocument("/books/" + quote.book, {
        quotes: arrayUnion(getDocumentReference("/quotes/" + res.id)),
      });
    }
    setLoading(false);
    setQuote({ ...quote, quote: "", date: Timestamp.fromDate(new Date()) });
  }
  return loading ? (
    <Spinner />
  ) : (
    <NavigateBackStructure>
      <ProtectedRoute role="admin">
        <Column className="max-w-4xl w-full h-screen overflow-y-scroll snap-y snap-mandatory mx-auto items-start">
          <div className="flex py-10 justify-between items-end w-full">
            <Row className="w-3/4 space-x-4">
              <p>Author Ref (doc id)</p>
              <FormInput value={quote.authorRef} onChange={(e) => setQuote({ ...quote, authorRef: e.target.value })} />
            </Row>
            <FilledButton onClick={onAddQuote}>
              <p>Add Quote</p>
            </FilledButton>
          </div>
          <div className="flex-col space-y-10 items-start w-full">
            <Row className="w-3/4 space-x-4">
              <p>Current of thinking Ref (doc id)</p>
              <FormInput value={quote.current} onChange={(e) => setQuote({ ...quote, current: e.target.value })} />
            </Row>
            <Row className="w-3/4 space-x-4">
              <p>Book Ref (doc id)</p>
              <FormInput value={quote.book} onChange={(e) => setQuote({ ...quote, book: e.target.value })} />
            </Row>
            <Column className="w-full">
              <h2>Quote</h2>
              <textarea
                className="px-6 py-2 w-full border bg-transparent border-text-color rounded-lg focus:ring-0"
                onChange={(e) => setQuote({ ...quote, quote: e.target.value })}
              />
              <p className={quote.quote.length > 155 ? "text-red-500" : ""}>{quote.quote.length}/155</p>
            </Column>
          </div>
        </Column>
      </ProtectedRoute>
    </NavigateBackStructure>
  );
}

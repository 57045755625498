import React from "react";

export default function ThinkCurrentCard({ onClick, rowSpan = 1, colSpan = 1, image, ...props }) {
  return (
    <section
      onClick={onClick}
      style={{ minHeight: "280px", gridColumn: "span " + colSpan, gridRow: "span " + rowSpan }}
      className={"card relative group cursor-pointer hover:shadow-glow transition-all duration-300 flex"}
    >
      <div className="absolute top-0 bottom-0 right-0 left-0 rounded-3xl bg-gray-700 animate-pulse"></div>
      <div
        className="absolute rounded-3xl top-0 bottom-0 left-0 right-0 z-0"
        style={{
          backgroundSize: "cover",
          backgroundPositionY: "center",
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0,0.2),rgba(0, 0, 0, .8)),url(` + image + `)`,
        }}
      />
      <h4 className={"truncate w-full text-3xl lg:text-5xl group-hover:underline relative z-10 self-end"}>{props.children}</h4>
    </section>
  );
}

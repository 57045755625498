import { arrayRemove, arrayUnion, Timestamp } from "firebase/firestore";
import moment from "moment";
import { auth, logedIn } from "../helpers/AuthHelper";
import {
  getDocument,
  getDocumentReference,
  getDocumentsWhere,
  getUserDocumentData,
  setUserDocumentData,
  UpdateDocument,
} from "../helpers/FirestoreHelper";
import { addToastWarning } from "../base/Components/Toasts";

export async function getQuote(path) {
  return new Promise(async (resolve, reject) => {
    await getDocument(path).then(async (res) => {
      let data = await getQuoteAuthor(res);
      resolve(data);
    });
  });
}

async function getQuoteAuthor(quoteDoc) {
  return new Promise(async (resolve, rejecet) => {
    let quote = quoteDoc.data();
    if (quote == undefined) return resolve(undefined);
    quote.id = quoteDoc.id;
    quote.path = quoteDoc.ref.path;

    await getDocument(quote.authorRef.path).then((author) => {
      quote.author = author.data();
      quote.author.id = author.id;
      quote.author.path = author.ref.path;
      resolve(quote);
    });
  });
}

export async function getQuotesFromAuthor(authorId) {
  return new Promise(async (resolve, reject) => {
    await getDocumentsWhere("/quotes", "authorRef", "==", getDocumentReference("/users/" + authorId)).then(
      async (docs) => {
        let res = [];
        for (let index = 0; index < docs.length; index++) {
          var data = await getQuoteAuthor(docs[index]);
          res.push(data);
        }
        resolve(res);
      }
    );
  });
}

export function isQuoteInMyPhilosophies(quote) {
  let userData = getUserDocumentData();
  if (!userData || !userData.philosophies) return false;
  return userData.philosophies.find((e) => e.ref.id === quote.id) !== undefined;
}

export async function addQuoteToMyPhilosophies(quote) {
  return new Promise(async (resolve, reject) => {
    if (!logedIn()) return reject();
    let userData = getUserDocumentData();
    if (userData.philosophies && userData.philosophies.length >= 5) {
      addToastWarning("La limite de 5 philosophies a été atteinte");
      return reject();
    }

    let newElement = { ref: getDocumentReference(quote.path), dateAdded: Timestamp.fromDate(new Date()) };
    userData.philosophies.push(newElement);
    setUserDocumentData(userData);

    UpdateDocument(quote.author.path, {
      quotedBy: arrayUnion(getDocumentReference("/users/" + auth.currentUser.uid)),
    });
    UpdateDocument("/users/" + auth.currentUser.uid, {
      philosophies: arrayUnion(newElement),
    })
      .then(resolve)
      .catch(reject);
  });
}

export async function removeQuoteFromMyPhilosophies(quote) {
  return new Promise((resolve, reject) => {
    UpdateDocument(quote.author.path, {
      quotedBy: arrayRemove(getDocumentReference("/users/" + auth.currentUser.uid)),
    });
    let userData = getUserDocumentData();
    let element = userData.philosophies.find((e) => e.ref.id === quote.id);

    userData.philosophies = userData.philosophies.filter((e) => e.ref.id !== quote.id);
    setUserDocumentData(userData);

    UpdateDocument("/users/" + auth.currentUser.uid, { philosophies: arrayRemove(element) })
      .then(resolve)
      .catch(reject);
  });
}

export function getQuoteTimeAgoPublished(quote) {
  let date = moment(quote.date.seconds * 1000);
  let diffDays = moment().diff(date, "days");

  if (diffDays < 1) return moment().diff(date, "hours") + " heures";
  else if (diffDays === 1) return diffDays + " jour";
  else if (diffDays < 15) return diffDays + " jours";
  else if (diffDays < 31) return moment().diff(date, "weeks") + " semaines";
  else {
    let diffMonths = moment().diff(date, "months");
    return diffMonths + " mois";
  }
}

import {
  Timestamp,
  arrayRemove,
  arrayUnion,
  limit,
  orderBy,
  query,
  startAfter,
  startAt,
  where,
} from "firebase/firestore";
import { auth, logedIn } from "../helpers/AuthHelper";
import {
  createDocument,
  deleteDocument,
  getCollection,
  getCollectionReference,
  getDocument,
  getDocumentReference,
  getDocumentsWhere,
  getQuery,
  getUserDocumentData,
  setUserDocumentData,
  UpdateDocument,
} from "../helpers/FirestoreHelper";
import { giveLightPoint, isSubscribed, lightPointsAvailable } from "./UserLogic";
import moment from "moment";
import { checkRole } from "../components/navigation/protectedRoute";

export function getDiscussion(id, convertReponse = true) {
  return new Promise(async (resolve, reject) => {
    let res = await getDocument("/discussions/" + id);
    if (convertReponse) {
      let doc = res.data();
      doc.id = res.id;
      doc.path = res.path;
      resolve(doc);
    } else resolve(res);
  });
}

export function getPublicDiscussions(convertReponse = true, startAfter = undefined) {
  return new Promise(async (resolve, reject) => {
    let res = await getDocumentsWhere("/discussions/", "public", "==", true, 3, startAfter);
    if (convertReponse) {
      let docs = [];
      for (let index = 0; index < res.length; index++) {
        let doc = res[index];
        let data = doc.data();
        data.id = doc.id;
        data.path = doc.path;

        docs.push(data);
      }
      resolve(docs);
    } else resolve(res);
  });
}

export function getWeeklyDiscussions(convertReponse = true, startAfter = undefined) {
  return new Promise(async (resolve, reject) => {
    let weekly = await getDocument("/discussions/weeklyDiscussions");
    let docs = [];
    let list = weekly.data().list;

    for (let index = 0; index < list.length; index++) {
      let doc = await getDocument(list[index].path);
      if (convertReponse) {
        let data = doc.data();
        data.id = doc.id;
        data.path = doc.path;
        docs.push(data);
      } else docs.push(doc);
    }
    resolve(docs);
  });
}

export function getFollowingDiscussions(convertReponse = true) {
  return new Promise(async (resolve, reject) => {
    if (!logedIn()) reject();
    let list = getUserDocumentData().followingDiscussions;
    if (!list || list.length === 0) return resolve([]);
    let docs = [];

    for (let index = 0; index < list.length; index++) {
      let res = await getDocument(list[index].path);
      if (convertReponse) {
        let data = res.data();
        data.id = res.id;
        data.path = res.ref.path;
        docs.push(data);
      } else {
        docs.push(res);
      }
    }

    resolve(docs);
  });
}

export function getDiscussionComments(discussionId, startAtDoc = undefined, docsLimit = 3, getAll = false) {
  return new Promise(async (resolve, reject) => {
    let q;
    let col = await getCollectionReference("discussions/" + discussionId + "/comments");

    if (getAll) {
      q = query(col, where("public", "==", true), orderBy("date"));
    } else if (startAtDoc !== undefined) {
      let snap = await getDocument("/discussions/" + discussionId + "/comments/" + startAtDoc.id);
      q = query(col, where("public", "==", true), orderBy("date"), startAfter(snap), limit(docsLimit));
    } else {
      q = query(col, where("public", "==", true), orderBy("date"), limit(docsLimit));
    }

    let docs = await getQuery(q);

    for (let index = 0; index < docs.length; index++) {
      let data = docs[index].data();
      data.id = docs[index].id;
      data.path = docs[index].ref.path;
      docs[index] = data;
      let author = await getDocument("", docs[index].userRef);
      docs[index].author = author.data();
      docs[index].author.id = author.id;
    }

    resolve(docs);
  });
}

export function getDiscussionCommentsInReview(discussionId) {
  return new Promise(async (resolve, reject) => {
    let q;
    let col = await getCollectionReference("discussions/" + discussionId + "/comments");

    q = query(col, where("inReview", "==", true), orderBy("date"));

    let docs = await getQuery(q);

    for (let index = 0; index < docs.length; index++) {
      let data = docs[index].data();
      data.id = docs[index].id;
      data.path = docs[index].ref.path;
      docs[index] = data;
      let author = await getDocument("", docs[index].userRef);
      docs[index].author = author.data();
      docs[index].author.id = author.id;
    }

    resolve(docs);
  });
}

export function onFollowDiscussion(discussion) {
  return new Promise(async (resolve, reject) => {
    if (!logedIn()) return reject();
    if (!lightPointsAvailable()) return reject();

    let userData = getUserDocumentData();

    if (!isFollowingDiscussion(discussion)) {
      if (!userData.followingDiscussions) userData.followingDiscussions = [];
      let docRef = getDocumentReference("/discussions/" + discussion.id);
      UpdateDocument("/users/" + auth.currentUser.uid, {
        followingDiscussions: arrayUnion(docRef),
      });
      userData.followingDiscussions.push(docRef);
      await giveLightPoint("light_badge_" + discussion.id);
    } else {
      UpdateDocument("/users/" + auth.currentUser.uid, {
        followingDiscussions: arrayRemove(
          userData.followingDiscussions.find((e) => e.path === "discussions/" + discussion.id)
        ),
      });
      userData.followingDiscussions = userData.followingDiscussions.filter(
        (e) => e.path !== "discussions/" + discussion.id
      );
    }

    setUserDocumentData(userData);
    resolve(discussion);
  });
}

export async function addCommentToDiscussion(comment, discussionId, userId = null) {
  return new Promise(async (resolve, reject) => {
    if (!logedIn()) return reject("Vous devez être connecté pour commenter.");
    if (!isSubscribed(true)) return reject("Vous devez être abonné pour commenter.");
    if (comment.length < 10) {
      return reject("Le commentaire doit avoir un minimum de 10 caractères.");
    }

    await createDocument("discussions/" + discussionId + "/comments/", {
      comments: comment.split(/(\r\n|\n|\r)/gm).filter((str) => str.trim().length > 0),
      inReview: true,
      public: false,
      date: Timestamp.fromMillis(moment()),
      userRef: getDocumentReference("users/" + (userId ? userId : auth.currentUser.uid)),
    });

    resolve();
  });
}

export async function deleteCommentFromDiscussion(comment) {
  return new Promise(async (resolve, reject) => {
    if (!logedIn()) return reject();
    if(!checkRole("admin") && !checkRole("moderator")) return reject("Vous n'avez pas les droits pour supprimer ce commentaire.");

    let res = await deleteDocument(comment.path);
    resolve(res);
  });
}

export function isFollowingDiscussion(discussion) {
  let userDocData = getUserDocumentData();
  return (
    auth.currentUser !== null &&
    userDocData &&
    userDocData.followingDiscussions &&
    userDocData.followingDiscussions.find((e) => e.path === "discussions/" + discussion.id) !== undefined
  );
}

import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Column } from "../../base/Layout/columns";
import { NavigateBackStructure } from "../../components/navigation/structure";

export default function TermsAndConditions({}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Philosophique - Termes et conditions d'utilisation</title>
        <meta name="description" content="Connaître les conditions générales d'utilisation de la plateforme." />
      </Helmet>
      <NavigateBackStructure>
        <Column className="max-w-5xl mx-auto space-y-10 p-4 mt-24 sm:p-24 sm:text-justify">
          <h4 className="text-5xl">Termes et conditions d'utilisation</h4>
          <Column className="space-y-4">
            <h4>1 - Introduction</h4>
            <p className="text-xl">
              Bienvenue sur notre plateforme de contenu philosophique. Nous sommes heureux que vous ayez choisi notre
              plateforme pour explorer des idées philosophiques et interagir avec d'autres utilisateurs. Les termes et
              conditions suivants régissent votre utilisation de notre plateforme, veuillez les lire attentivement avant
              de commencer à utiliser notre service.
            </p>
          </Column>
          <Column className="space-y-4">
            <h4>2 - Acceptation des conditions d'utilisation</h4>
            <p className="text-xl">
              En utilisant notre plateforme, vous acceptez les présents termes et conditions ainsi que notre politique
              de confidentialité. Si vous n'acceptez pas ces termes, vous ne devez pas utiliser notre plateforme.
            </p>
          </Column>
          <Column className="space-y-4">
            <h4>3 - Utilisation de notre plateforme</h4>
            <p className="text-xl">
              Notre plateforme est destinée à être utilisée par des personnes âgées de 18 ans et plus. Nous nous
              réservons le droit de refuser l'accès à notre plateforme à tout moment et sans préavis.
            </p>
            <p className="text-xl">Lors de l'utilisation de notre plateforme, vous vous engagez à :</p>
            <ul className="pl-5">
              <li>- Respecter les lois applicables ;</li>
              <li>- Ne pas violer les droits d'autrui, y compris les droits de propriété intellectuelle ;</li>
              <li>- Ne pas diffuser de contenu illégal, offensant ou qui pourrait causer du tort à autrui ;</li>
              <li>- Ne pas utiliser notre plateforme à des fins commerciales sans notre autorisation préalable.</li>
            </ul>
            <p className="text-xl">
              Nous nous réservons le droit de suspendre ou de résilier votre compte en cas de violation de ces termes et
              conditions.
            </p>
          </Column>
          <Column className="space-y-4">
            <h4>4 - Propriété intellectuelle</h4>
            <p className="text-xl">
              Notre plateforme, y compris le contenu et les marques de commerce, est la propriété exclusive de notre
              entreprise ou de nos partenaires. Vous ne pouvez pas reproduire, distribuer ou utiliser notre contenu sans
              notre autorisation préalable.
            </p>
          </Column>
          <Column className="space-y-4">
            <h4>5 - Contenu généré par l'utilisateur</h4>
            <p className="text-xl">
              Notre plateforme permet aux utilisateurs de soumettre du contenu, y compris des commentaires, des
              critiques de livres, des podcasts, etc. En soumettant du contenu, vous acceptez que ce contenu soit
              disponible pour les autres utilisateurs et qu'il soit utilisé à des fins promotionnelles.
            </p>
            <p className="text-xl">
              Nous nous réservons le droit de modérer le contenu soumis par les utilisateurs et de supprimer tout
              contenu qui est illégal, offensant ou qui viole les droits d'autrui.
            </p>
          </Column>
          <Column className="space-y-4">
            <h4>6 - Exclusion de responsabilité</h4>
            <p className="text-xl">
              Notre plateforme est fournie "telle quelle" et nous ne garantissons pas que notre plateforme sera exempte
              d'erreurs ou que l'accès à notre plateforme sera ininterrompu. Nous ne serons pas responsables des
              dommages directs ou indirects découlant de l'utilisation de notre plateforme.
            </p>
          </Column>
          <Column className="space-y-4">
            <h4>7 - Modification des termes et conditions</h4>
            <p className="text-xl">
              Nous nous réservons le droit de modifier ces termes et conditions à tout moment et sans préavis. Nous vous
              encourageons à consulter régulièrement ces termes et conditions pour être informé de toute modification.
            </p>
          </Column>
          <Column className="space-y-4">
            <h4>8 - Loi applicable</h4>
            <p className="text-xl">
              Ces termes et conditions sont régis par les lois françaises et tout litige découlant de ces termes et
              conditions sera soumis à la juridiction française.
            </p>
            <p className="text-xl">
              En utilisant notre plateforme, vous acceptez de vous conformer à ces termes et conditions et à toute autre
              loi applicable.
            </p>
          </Column>
        </Column>
      </NavigateBackStructure>
    </>
  );
}

import React from "react";
import { Popover, Transition } from "@headlessui/react";

export default function PopOverMenu({ className = "", buttonClassName = "btn", popoverButton, ...props }) {
  return (
    <Popover>
      <Popover.Button className={buttonClassName}>{popoverButton}</Popover.Button>
      <Popover.Panel as="ul" className={"absolute " + className}>
        {props.children}
      </Popover.Panel>
    </Popover>
  );
}

export function PopOverMenuItem({
  onClick,
  className = "",
  isLast = false,
  isFirst = false,
  disabled = false,
  ...props
}) {
  let style = disabled
    ? "text-gray-400 cursor-default"
    : "hover:bg-gray-800 hover:text-gray-100 transition duration-500 cursor-pointer";

  return (
    <button
      onClick={() => (disabled ? () => {} : onClick())}
      type="button"
      className={
        className +
        " " +
        style +
        " flex space-x-4 items-center text-left pl-4 pr-6 border-gray-800 whitespace-nowrap focus:outline-none focus:ring-0 py-2 last:rounded-b-2xl last:pb-3 first:rounded-t-2xl border-b last:border-b-0 first:pt-3 w-full "
      }
    >
      {props.children}
    </button>
  );
}

import Footer from "../landing/footer";
import Read from "../../pages/read/read";
import Navbar from "../navigation/navbar";
import Podcasts from "../../pages/podcasts";
import { LigthBadge } from "../badges/badges";
import { onSnapshot } from "firebase/firestore";
import { auth } from "../../helpers/AuthHelper";
import Profile from "../../pages/profile/profile";
import { Column } from "../../base/Layout/columns";
import React, { useEffect, useState } from "react";
import FilledCard from "../../base/Cards/FilledCard";
import Discover from "../../pages/discover/discover";
import history from "../../providers/HistoryProvider";
import { Route, Routes, useNavigate } from "react-router";
import FilledButton from "../../base/Buttons/FilledButton";
import { Row, RowCol, RowEnd } from "../../base/Layout/rows";
import Discussions from "../../pages/discussions/discussions";
import OutlinedButton from "../../base/Buttons/OutlinedButton";
import PageNotFounded from "../../pages/default/pageNotFounded";
import ProfileCard from "../../pages/profile/components/profileCard";
import { getDocumentReference } from "../../helpers/FirestoreHelper";
import OutlinedIconButton from "../../base/Buttons/OutlinedIconButton";
import ProfileQuotes from "../../pages/profile/components/profileQuotes";

export default function Structure() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [userDoc, setuserDoc] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged((result) => setUser(result));
  }, []);

  useEffect(() => {
    if (user !== null) onSnapshot(getDocumentReference("/users/" + user.uid), setuserDoc);
  }, [user]);

  return (
    <>
      <div style={{ maxWidth: "1440px" }} className="flex flex-col sm:flex-row justify-between mx-auto">
        {/* GRADIENT POUR LA VERSION MOBILE */}
        <div className="h-28 z-30 sm:hidden w-full sm:w-fit bg-gradient-to-b from-background-color to-transparent fixed left-0 right-0 top-0" />
        {/* LEFT COLUMN */}
        <RowCol
          breakPoint="sm"
          className="fixed p-2 sm:p-0 justify-between sm:sticky sm:top-3 sm:left-2 sm:h-full w-full sm:w-fit sm:space-y-2 lg:w-40 xl:w-52 z-30"
        >
          <Navbar />
          <div className="flex xl:hidden">
            <ProfileCard userDoc={userDoc} />
          </div>
        </RowCol>
        {/* CENTER COLUMN */}
        <Column
          id="main_column"
          className="px-2 sm:px-5 w-full mt-20 sm:mt-0 justify-between sm:overflow-x-hidden items-start"
        >
          <main className="w-full h-full">
            <Routes>
              <Route path="/" element={<Discussions />} />
              <Route path="/discover" element={<Discover />} />
              <Route path="/read" element={<Read />} />
              <Route path="/listen" element={<Podcasts />} />
              <Route path="/user/:id" element={<Profile />} />
              <Route path="*" element={<PageNotFounded />} />
            </Routes>
          </main>
          <Footer />
        </Column>
        {/* RIGHT COLUMN */}
        <Column className="hidden xl:block z-30 right-2 top-3 sticky h-full space-y-2">
          <ProfileCard userDoc={userDoc} />
          {userDoc && (
            <>
              <ProfileQuotes userDoc={userDoc} />
              <FilledCard className="hidden sm:flex items-center shadow-glow-sm space-x-2 py-3 px-3 xl:px-5 xl:w-min xl:ml-auto">
                <LigthBadge id="profile_light_badge" />
                <p
                  id="profile_light_text"
                  className="font-bold text-sm lg:text-md transition-all duration-300 ease-in-out"
                >
                  {userDoc?.data().lightPoints}
                </p>
              </FilledCard>
            </>
          )}
        </Column>
      </div>
      {!user && (
        <Row className="hidden sm:flex w-full z-30 justify-between fixed bottom-0 px-6 py-4 bg-gradient-to-tr from-violet-800 via-blue-600 to-blue-900">
          <Column className="w-full">
            <h4 className="text-2xl">Êtes-vous prêt à challenger vos idées ?</h4>
            <p className="font-normal text-sm">Inscrivez-vous et portez la philosophie dans votre quotidien.</p>
          </Column>
          <RowEnd className="space-x-2 w-fit">
            <OutlinedButton className="rounded-full hidden md:flex" onClick={() => navigate("/login")}>
              <p>Connexion</p>
            </OutlinedButton>
            <FilledButton className="rounded-full" onClick={() => navigate("/earlyRegister")}>
              <p>S'inscrire</p>
            </FilledButton>
          </RowEnd>
        </Row>
      )}
    </>
  );
}

export function NavigateBackStructure({ to, ...props }) {
  const navigate = useNavigate();
  return (
    <div className="w-full h-fit bg-background-dark">
      <OutlinedIconButton
        onClick={to == undefined ? history.back : () => navigate(to)}
        className="h-14 w-14 flex justify-center !rounded-3xl border-primary-color items-center left-3 top-2 backdrop-blur-sm fixed z-20"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
        </svg>
      </OutlinedIconButton>
      {props.children}
    </div>
  );
}

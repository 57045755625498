import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { getDiscussion } from "../../logic/DiscussionLogic";
import DiscussionComments from "./components/discussionComments";
import { NavigateBackStructure } from "../../components/navigation/structure";
import { Column } from "../../base/Layout/columns";
import { Helmet } from "react-helmet";

export default function Discussion({}) {
  const { id } = useParams();
  const [discussion, setDiscussion] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (discussion == null) {
      getDiscussion(id).then(setDiscussion);
    }
  }, [discussion]);

  return (
    <>
      {discussion && (
        <Helmet>
          <title>{discussion.title} - Discussion</title>
          <meta name="description" content={discussion.description} />
          <link rel="canonical" href={"https://philosophique.app/discussion/" + id} />
        </Helmet>
      )}
      <NavigateBackStructure>
        <div
          className="relative w-full px-4 sm:px-16 flex items-end bg-gradient-to-tl from-violet-700 via-indigo-600 to-indigo-900 h-1/4"
          id={"paragraph_-1"}
        >
          {discussion && discussion.img && (
            <div
              className="absolute top-0 bottom-0 left-0 right-0 z-0"
              style={{
                backgroundSize: "cover",
                backgroundPositionY: "center",
                backgroundImage:
                  `linear-gradient(to top, rgba(0, 0, 0,.8),rgba(0, 0, 0, 0)),url(` + discussion.img + `)`,
              }}
            />
          )}
          {!discussion && (
            <div className="bg-gray-800 z-10 opacity-75 sm:w-3/4 my-2 max-w-4xl mx-auto animate-pulse h-24 rounded-3xl" />
          )}
          {discussion && (
            <h4 className={"z-10 text-5xl lg:text-7xl w-full max-w-4xl sm:mx-auto"}>{discussion.title}</h4>
          )}
        </div>
        <Column className="relative w-full flex-1 h-3/4 pb-2 lg:w-2/4 mx-auto">
          {discussion && <DiscussionComments discussionId={discussion.id} />}
        </Column>
      </NavigateBackStructure>
    </>
  );
}

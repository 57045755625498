import moment from "moment";
import { useEffect, useState } from "react";
import { Row } from "../../base/Layout/rows";
import { Column } from "../../base/Layout/columns";
import Spinner from "../../base/Components/Spinner";
import OutlinedCard from "../../base/Cards/OutlinedCard";
import FilledButton from "../../base/Buttons/FilledButton";
import { addToastError, addToastSuccess } from "../../base/Components/Toasts";
import { UpdateDocument, getDocumentsWhere } from "../../helpers/FirestoreHelper";

export default function Inbox({}) {
  const [loading, setLoading] = useState(false);
  const [contactMessages, setContactMessages] = useState(null);

  useEffect(() => {
    if (!contactMessages) {
      setLoading(true);
      getDocumentsWhere("contact", "traited", "!=", true).then((res) => {
        setContactMessages(res);
        setLoading(false);
      });
    }
  }, [contactMessages]);

  async function treatMessage(message) {
    setLoading(true);
    await UpdateDocument("/contact/" + message.id, { traited: true }).catch((error) => {
      addToastError("Erreur lors du traitement du message");
      setLoading(false);
    });
    addToastSuccess("Message traité avec succès");
    setContactMessages((contactMessages) => contactMessages.filter((msg) => msg.id !== message.id));
    setLoading(false);
  }

  return (
    <Column className="space-y-5 pb-40">
      <Row className="space-x-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-16 h-16"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H6.911a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661z"
          />
        </svg>
        <h1>Boîte de réception</h1>
      </Row>
      {contactMessages && contactMessages.length === 0 && (
        <p className="text-center italic">Vous n'avez aucun message pour le moment</p>
      )}
      {loading && <Spinner />}
      {contactMessages &&
        contactMessages.map((message) => (
          <OutlinedCard key={message.id}>
            <Row className="space-x-2">
              <h4>{message.data().name}</h4>
              <p className="flex-grow">{message.data().email}</p>
              <FilledButton hasIcon onClick={() => treatMessage(message)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                </svg>
                <p>Marquer comme traité</p>
              </FilledButton>
            </Row>
            <Column className="p-5 space-y-1">
              <p>{message.data().message}</p>
              <p className="text-sm text-end">{moment(message.data().date.seconds * 1000).format("DD/MM/yyyy")}</p>
            </Column>
          </OutlinedCard>
        ))}
    </Column>
  );
}

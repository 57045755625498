import { useNavigate } from "react-router";
import { logedIn } from "../../../helpers/AuthHelper";
import { Row, RowBetween } from "../../../base/Layout/rows";
import { ColRow, Column } from "../../../base/Layout/columns";
import { ReadLaterBadge } from "../../../components/badges/badges";
import UserNameLink from "../../../components/navigation/userNameLink";
import { isBookSavedForLater, onReadLaterBook } from "../../../logic/BooksLogic.js";

export default function BookCardBig({ book }) {
  const navigate = useNavigate();
  return (
    <ColRow className="rounded-3xl max-w-5xl shadow-xl bg-dark-background-color w-full h-fit lg:h-72 cursor-pointer">
      {!book && <BookCardBigLoading />}
      {book && (
        <>
          <img src={book.img} className="w-full lg:w-2/5 h-48 lg:h-full rounded-3xl object-cover aspect-auto" />
          <div className="flex flex-col w-full lg:w-3/5 h-72 lg:h-full p-6 space-y-5 justify-between">
            <Column
              onClick={() => {
                if (logedIn()) navigate("/book/" + book.id);
              }}
              className="sm:mr-20 max-h-full overflow-hidden space-y-3 relative group"
            >
              <p className="text-3xl font-bold group-hover:underline">{book.title}</p>
              <p>{book.description}</p>
              <div className="absolute pointer-events-auto z-10 -bottom-5 right-0 w-full h-20 bg-gradient-to-t from-dark-background-color to-transparent" />
            </Column>
            <RowBetween className="flex-row-reverse lg:flex-row">
              <Row className="space-x-2 flex-row-reverse lg:flex-row">
                <button
                  onClick={() => onReadLaterBook(book)}
                  className="flex items-center justify-center py-2 px-4 hover:underline space-x-2 hover:text-light-brown text-primary-color"
                >
                  <ReadLaterBadge solid={isBookSavedForLater(book)} />
                  <p className="hidden sm:block">{isBookSavedForLater(book) ? "Sauvegardé" : "Lire plus tard"}</p>
                </button>
              </Row>
              <UserNameLink className="h-12 w-fit" fromLeftToRight={window.innerWidth < 1024} user={book.author} />
            </RowBetween>
          </div>
        </>
      )}
    </ColRow>
  );
}

function BookCardBigLoading() {
  return (
    <>
      <div className="w-full lg:w-2/5 h-48 lg:h-full rounded-3xl bg-gray-700 animate-pulse" />
      <div className="flex flex-col w-full lg:w-3/5 lg:h-full p-5 space-y-5 justify-between">
        <Column>
          <div className="bg-gray-700 w-full animate-pulse h-16 rounded-3xl" />
          <div className="bg-gray-700 w-full animate-pulse h-4 rounded-3xl mt-2" />
          <div className="bg-gray-700 w-4/6 animate-pulse h-4 rounded-3xl mt-2" />
          <div className="bg-gray-700 w-3/5 block animate-pulse h-4 rounded-3xl mt-2" />
          <div className="bg-gray-700 w-3/5 block animate-pulse h-4 rounded-3xl mt-2" />
          <div className="bg-gray-700 w-3/4 animate-pulse h-4 rounded-3xl mt-2" />
        </Column>
        <RowBetween className="flex-row-reverse lg:flex-row">
          <Row className="flex-row-reverse lg:flex-row items-center">
            <div className="w-20 hidden sm:block h-10 py-1 rounded-lg bg-gray-700 animate-pulse lg:mr-2"></div>
            <div className="bg-gray-700 w-32 animate-pulse h-6 rounded-3xl" />
          </Row>
          <div className="mt-auto flex gap-1 items-center lg:flex-row-reverse flex-row">
            <div className="bg-gray-700 w-12 h-12 animate-pulse rounded-full ml-auto" />
            <div className="bg-gray-700 w-32 h-6 hidden sm:block animate-pulse rounded-full" />
          </div>
        </RowBetween>
      </div>
    </>
  );
}

import {
  getBookMinutesRead,
  isBookLighted,
  isBookSavedForLater,
  lightBook,
  onReadLaterBook,
} from "../../logic/BooksLogic";
import { Link } from "react-router-dom";
import FilledButton from "../../base/Buttons/FilledButton";
import { RowBetween, RowEnd } from "../../base/Layout/rows";
import OutlinedButton from "../../base/Buttons/OutlinedButton";
import { ColRow, ColumnBetween } from "../../base/Layout/columns";
import TabsPanel, { TabPanel } from "../../base/Navigation/TabsPanel";
import { LigthBadge, ReadLaterBadge } from "../../components/badges/badges";

export default function BookResume({ book, setBook, chapters }) {
  return (
    book &&
    chapters && (
      <ColRow breakPoint="md" className="h-screen w-full overflow-hidden">
        <div
          className="w-full md:w-2/5 flex-shrink-0 bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `linear-gradient(to left, rgba(0, 0, 0,0.5),rgba(5, 8, 20, .9)),url(` + book.img + `)`,
          }}
        >
          <ColumnBetween className="px-5 p-4 sm:p-6 md:pb-10 space-y-10">
            <RowBetween className="ml-16">
              <p className="font-semibold">{getBookMinutesRead(chapters)} minutes de lecture</p>
            </RowBetween>
            <h4 className="text-3xl md:text-5xl lg:text-7xl font-bold">{book.title}</h4>
          </ColumnBetween>
        </div>
        <ColumnBetween className="p-2 md:p-5 w-full space-y-4">
          <TabsPanel tabs={[{ title: "Résumé" }, { title: "Chapitres" }, { title: "Citations" }]}>
            <TabPanel className="p-2 md:p-5">
              <p className="md:text-xl lg:text-2xl">{book.description}</p>
            </TabPanel>
            <TabPanel className="p-2 md:p-5 text-lg">
              <ul className="space-y-4 list-decimal p-5">
                {chapters.map((chapter) => (
                  <li key={chapter.id}>
                    <Link to={"chapter/" + chapter.id} className="hover:underline">
                      <p className="font-semibold">{chapter.data().subtitle}</p>
                    </Link>
                  </li>
                ))}
              </ul>
            </TabPanel>
          </TabsPanel>
          <RowEnd className="space-x-2">
            <p className="text-right">{book.lighted?.length || 0} personnes ont illuminé ce livre</p>
            <button onClick={() => lightBook(book).then(setBook)} className={"p-2 focus:outline-none"}>
              <LigthBadge id={"light_badge_" + book.id} highlightOnHover={true} solid={isBookLighted(book)} />
            </button>
          </RowEnd>
          <RowEnd className="flex-col !items-end sm:items-center space-y-2 sm:space-y-0 sm:flex-row sm:space-x-4">
            {isBookSavedForLater(book) == false && (
              <OutlinedButton onClick={() => onReadLaterBook(book).then(setBook)} hasIcon>
                <ReadLaterBadge solid={isBookSavedForLater(book)} />
                <p>Lire plus tard</p>
              </OutlinedButton>
            )}
            {isBookSavedForLater(book) && (
              <FilledButton onClick={() => onReadLaterBook(book).then(setBook)} hasIcon>
                <ReadLaterBadge solid={isBookSavedForLater(book)} />
                <p>Retirer de la liste</p>
              </FilledButton>
            )}
            <Link to={"chapter/1"} className="btn bg-primary-color text-primary-on !pl-4">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path
                  fillRule="evenodd"
                  d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm14.024-.983a1.125 1.125 0 0 1 0 1.966l-5.603 3.113A1.125 1.125 0 0 1 9 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113Z"
                  clipRule="evenodd"
                />
              </svg>
              <p>Démarrer la lecture</p>
            </Link>
          </RowEnd>
        </ColumnBetween>
      </ColRow>
    )
  );
}

import React, { useEffect, useState } from "react";
import { getQuote, getQuotesFromAuthor } from "../../logic/QuotesLogic";
import { Column } from "../../base/Layout/columns";
import QuoteCard from "./quoteCard";
import QuoteBig from "./quoteBig";

export default function QuotesList({ title, list, id, className = "", cards = true }) {
  const [docs, setDocs] = useState();

  useEffect(() => {
    if (!docs && list) getQuotes();
    else if (!docs && !list) setDocs([]);
  }, [list]);

  async function getQuotes() {
    let quotes = [];
    for (let index = 0; (index < list.length && index < 10); index++) {
      await getQuote(list[index].path).then((res) => {
        if (res) {
          quotes.push(res);
        }
      });
    }
    setDocs(quotes);
  }

  return cards ? (
    docs && docs.length === 0 ? (
      <></>
    ) : (
      <Column id={id} className={"w-full pt-10 " + className}>
        <h1>{title}</h1>
        <div className="py-4 overflow-x-scroll relative flex space-x-2">
          {!docs &&
            list &&
            list.map((e, k) => <QuoteCard key={"quote_" + title + "_" + k} quote={null} leftPos={k * 5 + "px"} />)}
          {docs && docs.map((e, k) => <QuoteCard key={"quote_" + title + "_" + k} quote={e} leftPos={k * 5 + "px"} />)}
        </div>
      </Column>
    )
  ) : (
    <Column className="px-4 sm:px-10 mx-auto w-full">
      {!docs && list.map((e, k) => <QuoteBig key={"quote_" + title + "_" + k} quote={null} />)}
      {docs && docs.map((e, k) => <QuoteBig key={"quote_" + title + "_" + k} quote={e} />)}
    </Column>
  );
}

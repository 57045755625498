import moment from "moment";
import { useEffect, useState } from "react";
import { UpdateDocument, getDocument, searchDocumentByValue } from "../../../helpers/FirestoreHelper";
import { addToastInfo } from "../../../base/Components/Toasts";
import { Row, RowBetween, RowEnd } from "../../../base/Layout/rows";
import { Column } from "../../../base/Layout/columns";
import FilledButton from "../../../base/Buttons/FilledButton";
import Spinner from "../../../base/Components/Spinner";
import SearchRounded from "../../../base/Components/Search";
import OutlinedCard from "../../../base/Cards/OutlinedCard";
import OutlinedIconButton from "../../../base/Buttons/OutlinedIconButton";
import {
  deleteCommentFromDiscussion,
  getDiscussion,
  getDiscussionComments,
  getDiscussionCommentsInReview,
  getWeeklyDiscussions,
} from "../../../logic/DiscussionLogic";
import OutlinedButton from "../../../base/Buttons/OutlinedButton";
import { showDangerConfirmationModal } from "../../../base/Dialogs/ConfirmationModal";
import AddComment from "./addComment";
import AddDiscussion from "./addDiscussion";
import UserNameLink from "../../../components/navigation/userNameLink";
import { getHighlightedText } from "../../../helpers/functions";

export default function DiscussionsDashboard({}) {
  const [search, setSearch] = useState("");
  const [discussions, setDiscussions] = useState(null);
  const [addNewDiscussion, setAddNewDiscussion] = useState(false);
  const [newComment, setNewComment] = useState(null);
  const [loading, setLoading] = useState(false);

  // On recupere les discussions de la semaine par defaut
  useEffect(() => {
    getWeeklyDiscussions().then(setDiscussions);
  }, []);

  return (
    <>
      <Column className="max-w-7xl w-full space-y-5 mx-auto p-20">
        <RowBetween>
          <h2>Gestion des discussions</h2>
          <FilledButton onClick={() => setAddNewDiscussion(true)}>
            <p>Créer une discussion</p>
          </FilledButton>
        </RowBetween>
        <RowBetween className="mt-10">
          <SearchRounded id="search_discussions" onChange={setSearch} />
        </RowBetween>
        {loading && <Spinner />}
        {!loading &&
          discussions &&
          discussions
            .filter((e) => e.title.toLowerCase().includes(search.toLowerCase()))
            .map((discussion) => (
              <DiscussionCard
                key={discussion.id}
                discussion={discussion}
                setNewComment={setNewComment}
                search={search}
              />
            ))}
      </Column>
      <AddComment open={newComment} setOpen={() => setNewComment(null)} />
      <AddDiscussion open={addNewDiscussion} setOpen={() => setAddNewDiscussion(false)} />
    </>
  );
}

function DiscussionCard({ discussion, setNewComment, search }) {
  const [comments, setComments] = useState(null);
  const [commentsInReview, setCommentsInReview] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!comments) getDiscussionComments(discussion.id).then(setComments);
  }, [comments]);

  useEffect(() => {
    if (!commentsInReview) getDiscussionCommentsInReview(discussion.id).then(setCommentsInReview);
  }, [commentsInReview]);

  // Delete a comment from the discussion
  async function handleDeleteComment(comment) {
    let deleteComment = await showDangerConfirmationModal(
      "Supprimer le commentaire ?",
      "Êtes-vous sûr de vouloir supprimer ce commentaire ?"
    );
    if (deleteComment) {
      setLoading(true);
      await deleteCommentFromDiscussion(comment);
      setComments(comments.filter((c) => c.id !== comment.id));
      setLoading(false);
    }
  }

  // Accept a comment to be shown in the discussion
  async function acceptComment(comment) {
    setLoading(true);
    await UpdateDocument(comment.path, { inReview: false, public: true });
    setCommentsInReview((commentsInReview) => commentsInReview.filter((c) => c.id !== comment.id));
    setLoading(false);
    setComments((comments) => [...comments, comment]);
    addToastInfo("Commentaire accepté !");
  }

  // Reject a comment to be shown in the discussion
  async function rejectComment(comment) {
    setLoading(true);
    await UpdateDocument(comment.path, { inReview: false, public: false });
    setCommentsInReview((commentsInReview) => commentsInReview.filter((c) => c.id !== comment.id));
    setLoading(false);
    addToastInfo("Commentaire rejeté !");
  }

  return (
    <OutlinedCard>
      <Column className="space-y-4">
        <Row className="space-x-2">
          <p className="text-3xl font-semibold flex-grow">{getHighlightedText(discussion.title, search)}</p>
          {loading && <Spinner />}
          <OutlinedIconButton onClick={() => addToastInfo("Module not developped yet !")} tooltip="Editer">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
              <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z" />
            </svg>
          </OutlinedIconButton>
        </Row>
        {comments &&
          comments.map((comment) => (
            <OutlinedCard key={comment.id}>
              <Row className="space-x-5 p-5">
                <UserNameLink className="h-10 w-10" showName={false} user={comment.author} />
                <Column className="space-y-2 w-fit">
                  {comment.comments.map((c, k) => (
                    <p key={k}>{c}</p>
                  ))}
                </Column>
                <OutlinedIconButton tooltip="Supprimer" onClick={() => handleDeleteComment(comment)}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                    <path
                      fillRule="evenodd"
                      d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
                      clipRule="evenodd"
                    />
                  </svg>
                </OutlinedIconButton>
              </Row>
            </OutlinedCard>
          ))}
        {commentsInReview && commentsInReview.length > 0 && (
          <>
            <hr className="h-10" />
            <h4>Commntaires en revision :</h4>
            {commentsInReview.map((comment) => (
              <OutlinedCard className="border-dashed" key={"review_" + comment.id}>
                <Row className="space-x-2">
                  <Column className="space-y-2 flex-grow">
                    {comment.comments.map((c, k) => (
                      <p key={k}>{c}</p>
                    ))}
                  </Column>
                  <OutlinedIconButton tooltip="Accepter" onClick={() => acceptComment(comment)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                    </svg>
                  </OutlinedIconButton>
                  <OutlinedIconButton tooltip="Rejeter" onClick={() => rejectComment(comment)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </OutlinedIconButton>
                </Row>
              </OutlinedCard>
            ))}
          </>
        )}
        <RowEnd>
          <OutlinedButton onClick={() => setNewComment(discussion.id)}>Ajouter commentaire</OutlinedButton>
        </RowEnd>
      </Column>
    </OutlinedCard>
  );
}
